import { useEffect, useState } from "react";
import moment from "moment";
import Select from "../Objects/Select.js";
import Repo from "../Utility/Repo";
import InstitutionList from "../Objects/InstitutionList.js";
import ReactPaginate from "react-paginate";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";

function PageInstitution(props) {
  const [pageCount, setPageCount] = useState(3);
  const [institution, setInstitution] = useState();
  const [allInstitution, setAllInstitution] = useState([]);
  const [itemsPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [data, setData] = useState();
  const [currentItems, setCurrentItems] = useState(null);
  const [items, setItems] = useState([]);
  const [unmount, setUnmount] = useState();

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (props.apiToken) {
      Repo.getInstitution()
        .then((response) => {
          setItems(response.data.data);
          setCurrentItems(items.slice(0, itemsPerPage));
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.apiToken) {
      reloadPage(JSON.stringify(data));
    }
  };

  const reloadPage = (dataJson) => {
    setUnmount(true);
    if (props.apiToken) {
      Repo.filteredInstitution(props.apiToken, dataJson)

        .then((response) => {
          setItemOffset(0);
          setItems(response.data);
          setCurrentItems(items.slice(0, itemsPerPage));
          setUnmount(false);
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  const handleChange = (event) => {
    var value = event.target.value;
    switch (event.target.name) {
      case "nomeEnte":
        setData((prevState) => ({
          ...prevState,
          nomeEnte: value,
        }));
        break;

      case "citta":
        setData((prevState) => ({
          ...prevState,
          citta: value,
        }));
        break;

      case "minAbitanti":
        setData((prevState) => ({
          ...prevState,
          minAbitanti: value ? value : null,
        }));
        break;

      case "maxAbitanti":
        setData((prevState) => ({
          ...prevState,
          maxAbitanti: value ? value : null,
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeSelect = (event, nameOfComponent) => {
    if (event) {
      let name = nameOfComponent.name;
      let value = event.value;
      switch (name) {
        case "quotaPartecipazione":
          setData((prevState) => ({
            ...prevState,
            quotaPartecipazione: value,
          }));
          break;

        case "isPagato":
          setData((prevState) => ({
            ...prevState,
            isPagato: value,
          }));
          break;

        default:
          break;
      }
    } else {
      let name = nameOfComponent.name;
      switch (name) {
        case "quotaPartecipazione":
          setData((prevState) => ({
            ...prevState,
            quotaPartecipazione: null,
          }));
          break;

        case "isPagato":
          setData((prevState) => ({
            ...prevState,
            isPagato: null,
          }));
          break;

        default:
          break;
      }
    }
  };

  return (
    <>
      {/* Filtri */}

      <form onSubmit={handleSubmit} className="mt-5">
        <div>
          <div className="form-row mb-4">
            <div className="form-group col-6">
              <div className="form-group col-12">
                {" "}
                <input
                  type="text"
                  className="form-control"
                  id="nomeEnte"
                  name="nomeEnte"
                  onChange={handleChange}
                />
                <label htmlFor="nomeEnte" className="active">
                  Nome Ente
                </label>
              </div>
            </div>
            <div className="form-group col-6">
              <div className="form-group col-12">
                {" "}
                <input
                  type="text"
                  className="form-control"
                  id="citta"
                  name="citta"
                  onChange={handleChange}
                />
                <label htmlFor="citta" className="active">
                  Città
                </label>
              </div>
            </div>
          </div>

          <div className="form-row mb-4">
            <div className="form-group col-6">
              <div className="form-group col-12">
                {" "}
                <input
                  type="number"
                  className="form-control"
                  id="minAbitanti"
                  name="minAbitanti"
                  onChange={handleChange}
                />
                <label htmlFor="minAbitanti" className="active">
                  Numero Minimo Abitanti
                </label>
              </div>
            </div>
            <div className="form-group col-6">
              <div className="form-group col-12">
                {" "}
                <input
                  type="number"
                  className="form-control"
                  id="maxAbitanti"
                  name="maxAbitanti"
                  onChange={handleChange}
                />
                <label htmlFor="maxAbitanti" className="active">
                  Numero Massimo Abitanti
                </label>
              </div>
            </div>
          </div>
          <div className="form-row">
            <div className="form-group col-6">
              <div className="bootstrap-select-wrapper">
                <label>Quota Partecipazione</label>

                <Select
                  aria-label="Scegli una opzione"
                  clearText="Annulla"
                  defaultInputValue=""
                  defaultMenuIsOpen={false}
                  defaultValue={null}
                  icon={false}
                  name="quotaPartecipazione"
                  onChange={handleChangeSelect}
                  isClearable
                  options={[
                    {
                      value: "A",
                      label: "A",
                    },
                    {
                      value: "B",
                      label: "B",
                    },
                    {
                      value: "C",
                      label: "C",
                    },
                    {
                      value: "D",
                      label: "D",
                    },
                    {
                      value: "E",
                      label: "E",
                    },
                  ]}
                  placeholder="Scegli una opzione"
                />
              </div>
            </div>

            <div className="form-group col-6">
              <div className="bootstrap-select-wrapper">
                <label>Quota Pagata</label>

                <Select
                  aria-label="Scegli una opzione"
                  clearText="Annulla"
                  defaultInputValue=""
                  defaultMenuIsOpen={false}
                  defaultValue={null}
                  icon={false}
                  name="isPagato"
                  onChange={handleChangeSelect}
                  isClearable
                  options={[
                    {
                      value: true,
                      label: "SI",
                    },
                    {
                      value: false,
                      label: "NO",
                    },
                  ]}
                  placeholder="Scegli una opzione"
                />
              </div>
            </div>
            <div className="form-group col-lg-12 text-center">
              <button type="submit" className="btn btn-primary">
                Cerca{" "}
              </button>
            </div>
          </div>
        </div>
      </form>
      <div className="row mt-5 mb-3 p-2">
        <div className="col-10 text-left">
          <h5>Lista Enti</h5>
        </div>
        <div className="col-lg-2 text-right">
          <Link
            to={"/create_institution"}
            className="btn  btn-outline-primary btn-icon"
          >
            <span>Aggiungi ente</span>
          </Link>
        </div>
      </div>

      {/* Lista enti */}

      <InstitutionList>{currentItems}</InstitutionList>
      {!unmount && (
        <>
          {" "}
          {currentItems && currentItems.length !== 0 && pageCount > 1 && (
            <ReactPaginate
              previousLabel={
                <svg className="icon icon-primary">
                  <use xlinkHref={`${iconSprite}#it-chevron-left`}></use>
                </svg>
              }
              nextLabel={
                <svg className="icon icon-primary">
                  <use xlinkHref={`${iconSprite}#it-chevron-right`}></use>
                </svg>
              }
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={4}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center col-12"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              initialPage={0}
            />
          )}
        </>
      )}

      <div className="text-right">
        <b>Totale: </b>
        {items ? items.length : ""}
      </div>
    </>
  );
}

export default PageInstitution;
