import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import ReactPaginate from "react-paginate";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import SessionCourses from "../Objects/SessionCourses";
import Repo from "../Utility/Repo";
import Select from "../Objects/Select.js";
import Spinner from "../Objects/Spinner";
import { Link } from "react-router-dom";

function PageSessionAllCourses(props) {
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(3);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([]);
  const [data, setData] = useState({});
  const [request, setRequest] = useState({});
  const [unmount, setUnmount] = useState();

  const [loaded, setLoading] = useState(true);

  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const paramsId = query.get("meetingNumber");

  useEffect(() => {
    if (paramsId) {
      if (props.operator === true) {
        Repo.setMeetingStatus(props.apiToken, paramsId, true)
          .then((response) => {})
          .catch((error) => {
            //ALERT ERRORE - IMPOSSIBILE AVVIARE IL MEETING
          });
      }
    }
  }, []);

  const reloadPage = (dataJson) => {
    setUnmount(true);
    setItems([]);
    if (props.operator === false && props.apiToken) {
      Repo.postAllSessionAvailable(props.apiToken, dataJson)
        .then((response) => {
          if (response.status == 200) {
            setItemOffset(0);
            setItems(response.data);
            setCurrentItems(items.slice(0, itemsPerPage));
            setUnmount(false);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    } else if (props.operator) {
      Repo.postAllSession(props.apiToken, dataJson)
        .then((response) => {
          if (response.status == 200) {
            setItemOffset(0);
            setItems(response.data);
            setCurrentItems(items.slice(0, itemsPerPage));
            setUnmount(false);
            setLoading(true);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (props.apiToken) {
      reloadPage(JSON.stringify(data));
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const handleChange = (event) => {
    var value = event.target.value;
    switch (event.target.name) {
      case "nomeCorso":
        setData((prevState) => ({
          ...prevState,
          nomeCorso: value,
        }));
        break;

      case "materia":
        setData((prevState) => ({
          ...prevState,
          materia: value,
        }));
        break;

      case "dateInizio":
        setData((prevState) => ({
          ...prevState,
          dateInizio: value,
        }));
        break;

      case "dataFine":
        setData((prevState) => ({
          ...prevState,
          dataFine: value,
        }));
        break;

      case "localita":
        setData((prevState) => ({
          ...prevState,
          localita: value,
        }));
        break;

      case "relatori":
        setData((prevState) => ({
          ...prevState,
          relatori: value,
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeSelect = (event, nameOfComponent) => {
    if (event) {
      let name = nameOfComponent.name;
      let value = event.value;
      switch (name) {
        case "isPresenza":
          setData((prevState) => ({
            ...prevState,
            isPresenza: value,
          }));
          break;

        case "isConcluso":
          setData((prevState) => ({
            ...prevState,
            isConcluso: value,
          }));
          break;

        default:
          break;
      }
    } else {
      let name = nameOfComponent.name;
      switch (name) {
        case "isPresenza":
          setData((prevState) => ({
            ...prevState,
            isPresenza: null,
          }));
          break;

        case "isConcluso":
          setData((prevState) => ({
            ...prevState,
            isConcluso: null,
          }));
          break;

        default:
          break;
      }
    }
  };

  useEffect(() => {
    if (props.operator === false && props.apiToken) {
      Repo.postAllSessionAvailable(props.apiToken, JSON.stringify(request))
        .then((response) => {
          if (response.status == 200) {
            setItems(response.data);
            setCurrentItems(items.slice(0, itemsPerPage));
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    } else if (props.operator && props.apiToken) {
      Repo.postAllSession(props.apiToken, JSON.stringify(request))
        .then((response) => {
          if (response.status == 200) {
            setItems(response.data);
            setCurrentItems(items.slice(0, itemsPerPage));
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    } else {
      Repo.postAllSessionAvailableNoLogin(
        props.apiToken,
        JSON.stringify(request)
      )
        .then((response) => {
          if (response.status == 200) {
            setItems(response.data);
            setCurrentItems(items.slice(0, itemsPerPage));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  useEffect(() => {
    setLoading(!loaded);
  }, [items]);

  return (
    <>
      <Spinner loaded={loaded}></Spinner>

      {/* Filtri */}
      {props.apiToken && (
        <>
          <form onSubmit={handleSubmit} className="mt-5">
            <div className="form-row mb-3">
              <div className="form-group col-5">
                <div className="form-group col-12">
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    id="nomeCorso"
                    name="nomeCorso"
                    onChange={handleChange}
                  />
                  <label htmlFor="nomeCorso" className="active">
                    Nome Corso
                  </label>
                </div>
              </div>
              <div className="form-group col-4">
                <div className="form-group col-12">
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    id="materia"
                    name="materia"
                    onChange={handleChange}
                  />
                  <label htmlFor="materia" className="active">
                    Materia
                  </label>
                </div>
              </div>
              <div className="form-group col-3">
                <div className="bootstrap-select-wrapper">
                  <label>Presenza</label>

                  <Select
                    aria-label="Scegli una opzione"
                    clearText="Annulla"
                    defaultInputValue=""
                    defaultMenuIsOpen={false}
                    defaultValue={null}
                    icon={false}
                    name="isPresenza"
                    onChange={handleChangeSelect}
                    isClearable={true}
                    options={[
                      {
                        value: true,
                        label: "SI",
                      },
                      {
                        value: false,
                        label: "NO",
                      },
                    ]}
                    placeholder="Scegli una opzione"
                  />
                </div>
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="form-group col-6">
                <div className="form-group col-12">
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    id="localita"
                    name="localita"
                    onChange={handleChange}
                  />
                  <label htmlFor="localita" className="active">
                    Località
                  </label>
                </div>
              </div>
              <div className="form-group col-6">
                <div className="form-group col-12">
                  {" "}
                  <input
                    type="text"
                    className="form-control"
                    id="relatori"
                    name="relatori"
                    onChange={handleChange}
                  />
                  <label htmlFor="relatori" className="active">
                    Relatori
                  </label>
                </div>
              </div>
            </div>
            <div className="form-row mb-3">
              <div className="form-group col-6">
                <div className="form-group col-12">
                  {" "}
                  <input
                    type="date"
                    className="form-control"
                    id="dateInizio"
                    name="dateInizio"
                    defaultValue=""
                    onChange={handleChange}
                  />
                  <label htmlFor="dateInizio" className="active">
                    Data inizio
                  </label>
                </div>
              </div>
              <div className="form-group col-6">
                <div className="form-group col-12">
                  {" "}
                  <input
                    type="date"
                    className="form-control"
                    id="dataFine"
                    name="dataFine"
                    defaultValue=""
                    onChange={handleChange}
                  />
                  <label htmlFor="dataFine" className="active">
                    Data Fine
                  </label>
                </div>
              </div>
              {/* <div className="form-group col-4">
                <div className="bootstrap-select-wrapper">
                  <label>Concluso</label>

                  <Select
                    aria-label="Scegli una opzione"
                    clearText="Annulla"
                    defaultInputValue=""
                    defaultMenuIsOpen={false}
                    defaultValue={null}
                    icon={false}
                    name="isConcluso"
                    onChange={handleChangeSelect}
                    isClearable={true}
                    options={[
                      {
                        value: true,
                        label: "SI",
                      },
                      {
                        value: false,
                        label: "NO",
                      },
                    ]}
                    placeholder="Scegli una opzione"
                  />
                </div>
              </div> */}
            </div>
            <div className="form-group col text-center">
              <button type="submit" className="btn btn-primary">
                Cerca{" "}
              </button>
            </div>
          </form>
          <div className="row mt-5 mb-3 p-2">
            <div className="col-10 text-left">
              <h5>Lista Corsi</h5>
            </div>
            {props.operator && (
              <div className="col-lg-2 text-right">
                <Link
                  to={"/new_session"}
                  className="btn  btn-outline-primary btn-icon"
                >
                  <span>Aggiungi edizione</span>
                </Link>
              </div>
            )}
          </div>
        </>
      )}
      {/* Lista corsi */}

      <div className="row">
        {" "}
        <SessionCourses
          column={4}
          subscribe={true}
          apiToken={props.apiToken}
          operator={props.operator}
          showResult={true}
          subfolder={props.subfolder}
        >
          {currentItems}
        </SessionCourses>
        {!unmount && (
          <>
            {" "}
            {currentItems && currentItems.length !== 0 && pageCount > 1 && (
              <>
                <ReactPaginate
                  previousLabel={
                    <svg className="icon icon-primary">
                      <use xlinkHref={`${iconSprite}#it-chevron-left`}></use>
                    </svg>
                  }
                  nextLabel={
                    <svg className="icon icon-primary">
                      <use xlinkHref={`${iconSprite}#it-chevron-right`}></use>
                    </svg>
                  }
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={handlePageClick}
                  containerClassName={
                    "pagination justify-content-center col-12"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  initialPage={0}
                />
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default PageSessionAllCourses;
