import { useState } from "react";
import Repo from "../Utility/Repo";
import { useLocation } from "react-router";
import SelectPayments from "../Objects/SelectPayments";
import { Link } from "react-router-dom";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import SelectCourse from "../Objects/SelectCourse";
import SelectTemplate from "../Objects/SelectTemplate";
import UploadFile from "../Objects/UploadFile";

function PageNewSession(props) {
  const [subject, setSubject] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [uploadImage, setUploadImage] = useState();
  let { search } = useLocation();
  const query = new URLSearchParams(search);

  const [isOpenConfirmation, toggleModalConfirmation] = useState(false);
  const [sendResult, setSendResult] = useState(false);
  const [startValidate, setStartValidate] = useState(false);
  const [idSessioResult, setIdSessioResult] = useState();

  const [startDate, setStartDate] = useState();
  const [dataEnd, setDataEnd] = useState();
  const [locality, setLocality] = useState();
  const [edition, setEdition] = useState();
  const [relation, setRelation] = useState();
  const [presence, setpresence] = useState(false);
  const [subscription, setSubscription] = useState();
  const [courseID, setCourseID] = useState(query.get("id"));
  const [courseName, setCourseName] = useState(query.get("name"));
  const [uploadImageBase64, setUploadImageBase64] = useState();
  const [registrationClosingDate, setRegistrationClosingDate] = useState();
  const [isPagamento, setIsPagamento] = useState(false);
  const [importPrice, setImportPrice] = useState("0");

  const [paymentsSelect, setPaymentsSelect] = useState([]);
  const [templateID, setTemplateID] = useState();
  const regexImport = new RegExp("^[+]?([0-9]{0,})*[,]?([0-9]{0,2})?$", "g");

  const subscriptionCheck = (e) => {
    if (e !== undefined) {
      if (presence) {
        setSubscription(e);
      } else if (!presence && e > 100) {
        setSubscription(100);
      } else {
        setSubscription(e);
      }
    } else {
      if (subscription > 100) {
        setSubscription(100);
      }
    }
  };

  function getBase64(file) {
    return new Promise(function (resolve) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  }

  const sendSession = async (e) => {
    e.preventDefault();
    setStartValidate(true);
    var validatePassed = true;

    if (uploadImage) {
      var base64File = await getBase64(uploadImage);
      var splitBase64 = base64File.split(",");
    }

    if (!startDate) validatePassed = false;
    if (!dataEnd) validatePassed = false;
    if (!locality) validatePassed = false;
    if (!edition) validatePassed = false;
    if (!templateID) validatePassed = false;
    if (!relation) validatePassed = false;
    if (isPagamento == true && parseFloat(importPrice) == 0)
      validatePassed = false;

    if (!registrationClosingDate) {
      validatePassed = false;
    } else {
      if (new Date(startDate) < new Date(registrationClosingDate)) {
        validatePassed = false;
      }
    }

    if (!courseID) validatePassed = false;

    if (validatePassed) {
      var temp = {
        dateInizio: startDate,
        dataFine: dataEnd,
        localita: locality,
        edizione: edition,
        relatori: relation,
        isPresenza: presence,
        maxUtenti: subscription,
        corsoID: courseID,
        templateID: templateID,
        copertina: splitBase64 && splitBase64[1],
        tipoCopertina: uploadImage && uploadImage.type,
        chiusuraPrenotazione: registrationClosingDate,
        prezzo: importPrice ? parseFloat(importPrice.replace(/,/g, ".")) : 0,
      };

      if (props.apiToken) {
        Repo.postNewSession(props.apiToken, JSON.stringify(temp))
          .then((response) => {
            setIdSessioResult(response.data.sessioneCorsoID);
            toggleModalConfirmation(true);
            setSendResult(true);
          })
          .catch((error) => {
            setSendResult(false);
            console.log(error);
            if (error.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12 ">
          <div className="col-4">
            <h4> Creazione Edizione </h4>
          </div>
        </div>

        {query.get("id") ? (
          <div className="col-lg-12 mt-4">
            <Input
              label="Nome Corso"
              id="name"
              name="name"
              onChange={(e) => setName(e.target.value)}
              disabled
              placeholder={courseName}
              defaultValue={courseName}
            />
          </div>
        ) : (
          <div className="col-lg-12 mt-4">
            <SelectCourse
              apiToken={props.apiToken}
              setValue={setCourseID}
            ></SelectCourse>
          </div>
        )}
      </div>

      <div className="row mt-5">
        <div className="col-lg-6">
          <Input
            label="Data Inizio"
            id="dateStart"
            name="startDate"
            type="date"
            placeholder=" "
            {...(startValidate &&
              !startDate && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startDate && !startDate && { className: "text-danger" })}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </div>

        <div className="col-lg-6">
          <Input
            label="Data Fine"
            id="dateEnd"
            name="dataEnd"
            type="date"
            placeholder=" "
            {...(startValidate &&
              !dataEnd && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate && !dataEnd && { className: "text-danger" })}
            onChange={(e) => setDataEnd(e.target.value)}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-6">
          <Input
            label="Edizione"
            id="edition"
            name="edition"
            placeholder="Inserisci l'edizione"
            {...(startValidate &&
              !edition && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate && !edition && { className: "text-danger" })}
            onChange={(e) => setEdition(e.target.value)}
          />
        </div>

        <div className="col-lg-6">
          <Input
            label="Località"
            id="locality"
            name="locality"
            placeholder="Inserisci la località"
            {...(startValidate &&
              !locality && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate && !locality && { className: "text-danger" })}
            onChange={(e) => setLocality(e.target.value)}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-6">
          <Input
            label="Relatori"
            id="relation"
            name="relation"
            placeholder="Inserisci i relatori separati da una virgola"
            {...(startValidate &&
              !relation && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate && !relation && { className: "text-danger" })}
            onChange={(e) => setRelation(e.target.value)}
          />
        </div>

        <div className="col-lg-6">
          <SelectTemplate
            apiToken={props.apiToken}
            setValue={setTemplateID}
          ></SelectTemplate>
          {startValidate && !templateID ? (
            <small id="errorSelect" className="form-text  text-danger">
              Il campo non può essere vuoto
            </small>
          ) : (
            <></>
          )}
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-6">
          <Input
            label="Data Chiusura Iscrizione"
            id="datachiusura"
            name="datachiusura"
            type="date"
            placeholder=" "
            {...(startValidate &&
              !registrationClosingDate && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate &&
              !registrationClosingDate && { className: "text-danger" })}
            onChange={(e) => setRegistrationClosingDate(e.target.value)}
            {...(startValidate &&
              new Date(startDate) < new Date(registrationClosingDate) && {
                className: "text-danger",
              })}
            {...(startValidate &&
              new Date(startDate) < new Date(registrationClosingDate) && {
                infoText:
                  "La data deve essere inferiore alla data di inizio del corso",
              })}
          />
        </div>
        <div className="col-lg-3">
          <Input
            label="Numero massimo iscritti"
            id="subscription"
            name="subscription"
            type="number"
            placeholder="Numero iscritti"
            value={subscription && subscription}
            min="0"
            {...(presence ? {} : { max: "100" })}
            {...(startValidate &&
              !subscription && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate &&
              !subscription && { className: "text-danger" })}
            onChange={(e) => subscriptionCheck(e.target.value)}
          />
        </div>
        <div className="col-lg-3">
          <FormGroup check>
            <Input
              id="presence"
              type="checkbox"
              name="privacyTwo"
              onChange={() => {
                setpresence(!presence);
                subscriptionCheck();
              }}
            />
            <Label for="presence" check>
              <i>In presenza?</i>
            </Label>
          </FormGroup>
        </div>
      </div>

      <div className="row">
        <div className="col-lg-3">
          <FormGroup check>
            <Input
              id="checkFree"
              type="checkbox"
              name="checkFree"
              onChange={() => {
                setIsPagamento(!isPagamento);
                setImportPrice(0);
              }}
            />
            <Label for="checkFree" check>
              <i>A Pagamento</i>
            </Label>
          </FormGroup>
        </div>
        <div className="col-lg-3">
          <Input
            label="Prezzo"
            name="prezzo"
            type="text"
            value={importPrice}
            disabled={!isPagamento}
            {...(startValidate &&
              isPagamento &&
              importPrice == 0 && {
                infoText: "Il prezzo deve essere maggiore di 0!",
                className: "text-danger",
              })}
            onChange={(e) =>
              setImportPrice(
                e.target.value.match(regexImport) ? e.target.value : importPrice
              )
            }
          />
        </div>
      </div>

      <div
        className="p-2 mt-4"
        style={{
          border: "solid 1px blue",
          borderRadius: "15px 15px 15px 15px",
        }}
      >
        <div className="col-lg-12 text-center">
          <h6>Carica Copertina Edizione</h6>
          {uploadImageBase64 ? (
            <figure className="img-wrapper">
              <img
                src={uploadImageBase64 && uploadImageBase64}
                width="310"
                height="190"
              ></img>
            </figure>
          ) : (
            <>
              <figure className="img-wrapper">
                <img src="https://semplicitalearning.it/img/corso.png"></img>
              </figure>
              <h6>
                In caso di mancato caricamento della copertina verrà
                visualizzata questa immagine
              </h6>
            </>
          )}
        </div>

        <div className="row">
          <div className="col-lg-4"></div>
          <div className="col-lg-4 text-center">
            <UploadFile
              handleFile={setUploadImage}
              getBase64={true}
              file64={setUploadImageBase64}
              testoInterno={"Carica immagine"}
            ></UploadFile>
          </div>
          <div className="col-lg-4"></div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-12 text-right">
          <button className="btn btn-primary" onClick={sendSession}>
            Salva corso
          </button>
        </div>
      </div>

      {isOpenConfirmation && (
        <div>
          <Modal
            isOpen={isOpenConfirmation}
            toggle={() => toggleModalConfirmation(!isOpenConfirmation)}
            centered
            size={"lg"}
          >
            <ModalHeader
              toggle={() => toggleModalConfirmation(!isOpenConfirmation)}
              id="esempio2"
            >
              {sendResult
                ? "L'edizione è stata creata"
                : "Qualcosa è andato storto!"}
            </ModalHeader>
            <ModalBody>
              {sendResult ? (
                <>
                  <h5>Edizione Creata con successo</h5>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        position: "absolute",
                        right: "1%",
                        bottom: "5%",
                      }}
                    >
                      <Link
                        to={"/session_course_detail?id=" + idSessioResult}
                        className="btn btn-primary btn-sm float-right"
                      >
                        Vai alla Sessione
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h4>Qualcosa è andato storto!</h4>
                  <div className="row">
                    {" "}
                    <div className="col-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() =>
                          toggleModalConfirmation(!isOpenConfirmation)
                        }
                      >
                        Indietro
                      </button>
                    </div>{" "}
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        </div>
      )}
    </>
  );
}

export default PageNewSession;
