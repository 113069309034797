import { useEffect, useState } from "react";
import { Input } from "design-react-kit";
import ReactPaginate from "react-paginate";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Repo from "../Utility/Repo";
import UsersList from "../Objects/UsersList";
import Select from "../Objects/Select.js";
import SelectInstitution from "../Objects/SelectInstitution";
import SelectQualification from "../Objects/SelectQualification";

function PageUserManagement(props) {
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(5);
  const [currentItems, setCurrentItems] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([]);
  const [data, setData] = useState({});
  const [request, setRequest] = useState({});
  const [qualification, setQualification] = useState();
  const [institution, setInstitution] = useState();
  const [subscribers, setSubscribers] = useState(false);
  const [unmount, setUnmount] = useState();
  const [usersOnly, setUsersOnly] = useState(false);

  const reload = (dataJson) => {
    setUnmount(true);

    if (props.operator) {
      Repo.getUsers(props.apiToken, dataJson)

        .then((response) => {
          if (response.status == 200) {
            if (response.data.data && response.data.data.length > 0) {
              setItemOffset(0);
              setItems(response.data.data);
              setCurrentItems(items.slice(0, itemsPerPage));
              setUnmount(false);
            } else {
              setItems([]);
              setCurrentItems([]);
              setUnmount(false);
            }
          }
        })
        .catch((error) => {
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    var tempData = data;
    tempData["qualifica"] = qualification;
    tempData["enteID"] = institution;

    if (usersOnly) {
      tempData["isSoloUser"] = usersOnly;
    } else {
      tempData["isSoloUser"] = false;
    }

    if (props.apiToken) {
      reload(tempData);
    }
  };

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const [requestUser, setRequestUser] = useState({
    isTesseratoVerificato: false,
  });

  const reloadApi = () => {
    Repo.getUsers(props.apiToken, JSON.stringify(requestUser))
      .then((response) => {
        if (response.status == 200) {
          setItems(response.data.data);
          setCurrentItems(items.slice(0, itemsPerPage));
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  useEffect(() => {
    if (props.apiToken) {
      reloadApi();
    }
  }, []);

  const handleChange = (event) => {
    var value = event.target.value;
    switch (event.target.name) {
      case "firstName":
        setData((prevState) => ({
          ...prevState,
          firstName: value,
        }));
        break;

      case "lastName":
        setData((prevState) => ({
          ...prevState,
          lastName: value,
        }));
        break;

      case "codiceFiscale":
        setData((prevState) => ({
          ...prevState,
          codiceFiscale: value,
        }));
        break;

      case "email":
        setData((prevState) => ({
          ...prevState,
          email: value,
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeSelect = (event, nameOfComponent) => {
    if (event) {
      let name = nameOfComponent.name;
      let value = event.value;

      switch (name) {
        case "isTesserato":
          setData((prevState) => ({
            ...prevState,
            isTesserato: value,
          }));
          break;

        default:
          break;
      }
    } else {
      let name = nameOfComponent.name;
      switch (name) {
        case "isTesserato":
          setData((prevState) => ({
            ...prevState,
            isTesserato: null,
          }));
          break;

        default:
          break;
      }
    }
  };
  return (
    <>
      {/* Lista filtri */}
      <form onSubmit={handleSubmit} className="mt-5">
        <div className="form-row mb-3">
          <div className="form-group col-6">
            <div className="form-group col-12">
              {" "}
              <input
                type="text"
                className="form-control"
                id="firstName"
                name="firstName"
                onChange={handleChange}
              />
              <label htmlFor="firstName" className="active">
                Nome
              </label>
            </div>
          </div>
          <div className="form-group col-6">
            <div className="form-group col-12">
              {" "}
              <input
                type="text"
                className="form-control"
                id="lastName"
                name="lastName"
                onChange={handleChange}
              />
              <label htmlFor="lastName" className="active">
                Cognome
              </label>
            </div>
          </div>
          {/* <div className="col-lg-3">
            <div className="bootstrap-select-wrapper">
              <label>Tesserato</label>

              <Select
                aria-label="Scegli una opzione"
                clearText="Annulla"
                defaultInputValue=""
                defaultMenuIsOpen={false}
                defaultValue={null}
                icon={false}
                name="isTesserato"
                onChange={handleChangeSelect}
                isClearable={true}
                options={[
                  {
                    value: true,
                    label: "SI",
                  },
                  {
                    value: false,
                    label: "NO",
                  },
                ]}
                placeholder="Scegli una opzione"
              />
            </div>
          </div> */}
        </div>
        <div className="form-row mb-3">
          <div className="form-group col-6">
            <div className="form-group col-12">
              {" "}
              <input
                type="text"
                className="form-control"
                id="codiceFiscale"
                name="codiceFiscale"
                onChange={handleChange}
              />
              <label htmlFor="codiceFiscale" className="active">
                Codice Fiscale
              </label>
            </div>
          </div>
          <div className="form-group col-6">
            <div className="form-group col-12">
              {" "}
              <input
                type="text"
                className="form-control"
                id="email"
                name="email"
                onChange={handleChange}
              />
              <label htmlFor="email" className="active">
                Email
              </label>
            </div>
          </div>
        </div>
        {/* <div className="form-row mb-3">
          <div className="col-lg-4 mt-4">
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectExampleDisabled">Ente</label>
              <SelectInstitution setValue={setInstitution}></SelectInstitution>
            </div>
          </div>

          <div className="col-lg-4 mt-4">
            <div className="bootstrap-select-wrapper">
              <label htmlFor="selectExampleDisabled">Qualifica</label>
              <SelectQualification
                setValue={setQualification}
              ></SelectQualification>
            </div>
          </div>
        </div> */}
        <div className="form-group col text-center">
          <button type="submit" className="btn btn-primary">
            Cerca{" "}
          </button>
        </div>
      </form>
      <div className="row mt-5 mb-3 p-2">
        <div className="col-10 text-left">
          <h5>Lista Utenti</h5>
        </div>

        {/* <div className="col-lg-2 text-right">
          {usersOnly ? (
            <>
              <button
                className="btn btn-danger"
                onClick={() => setUsersOnly(!usersOnly)}
              >
                Mostra Tutti
              </button>
            </>
          ) : (
            <>
              <button
                className="btn btn-danger"
                onClick={() => setUsersOnly(!usersOnly)}
              >
                Solo Utenti
              </button>
            </>
          )}
        </div> */}
      </div>

      {/* Lista utenti */}
      <div className="row">
        <div className="col-12">
          <UsersList
            operator={true}
            reload={reloadApi}
            totElement={items && items.length}
            apiToken={props.apiToken}
            hideCheckIcon={true}
            hideCloseIcon={true}
          >
            {currentItems}
          </UsersList>
          {!unmount && (
            <>
              {currentItems && currentItems.length !== 0 && pageCount > 1 && (
                <ReactPaginate
                  previousLabel={
                    <svg className="icon icon-primary">
                      <use xlinkHref={`${iconSprite}#it-chevron-left`}></use>
                    </svg>
                  }
                  nextLabel={
                    <svg className="icon icon-primary">
                      <use xlinkHref={`${iconSprite}#it-chevron-right`}></use>
                    </svg>
                  }
                  breakLabel={"..."}
                  pageCount={pageCount}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={4}
                  onPageChange={handlePageClick}
                  containerClassName={
                    "pagination justify-content-center col-12"
                  }
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextClassName={"page-item"}
                  nextLinkClassName={"page-link"}
                  breakClassName={"page-item"}
                  breakLinkClassName={"page-link"}
                  activeClassName={"active"}
                  initialPage={0}
                />
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}

export default PageUserManagement;
