import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import moment from "moment";
import Select from "../Objects/Select.js";
import Repo from "../Utility/Repo";
import SubjectsList from "../Objects/SubjectsList.js";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";

function PageSubjects(props) {
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [countElement, setCountElement] = useState([]);
  const [itemsPerPage] = useState(5);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (props.apiToken) {
      Repo.getSubjects(props.apiToken)
        .then((response) => {
          setItems(response.data);
          setCurrentItems(items.slice(0, itemsPerPage));
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, [props.apiToken]);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (apiToken) {
      reloadPage(JSON.stringify(data));
    }
  };

  const handleChange = (event) => {
    var value = event.target.value;
    switch (event.target.name) {
      case "valueDateEmmision":
        setData((prevState) => ({
          ...prevState,
          AnnoImposta: value,
        }));
        break;

      case "valueTaxYear":
        setData((prevState) => ({
          ...prevState,
          AnnoEmissione: value,
        }));
        break;

      default:
        break;
    }
  };

  const handleChangeSelect = (event, nameOfComponent) => {
    let name = nameOfComponent.name;
    let value = event.value;

    switch (name) {
      case "valueFoldertype":
        setData((prevState) => ({
          ...prevState,
          TipoCartella: value,
        }));
        break;

      case "valueDataEntry":
        setData((prevState) => ({
          ...prevState,
          TipoEntrata: value,
        }));
        break;

      default:
        break;
    }
  };

  return (
    <>
      {/* Filtri */}

      <div className="row mt-5 mb-3 p-2">
        <div className="col-10 text-left">
          <h5>Lista Materie</h5>
        </div>
        {props.operator && (
          <div className="col-lg-2 text-right">
            <Link
              to={"/create_subject"}
              className="btn  btn-outline-primary btn-icon"
            >
              <span>Aggiungi Materie</span>
            </Link>
          </div>
        )}
      </div>

      {/* Lista materie */}

      <SubjectsList>{currentItems}</SubjectsList>
      {currentItems && currentItems.length !== 0 && pageCount > 1 && (
        <ReactPaginate
          previousLabel={
            <svg className="icon icon-primary">
              <use xlinkHref={`${iconSprite}#it-chevron-left`}></use>
            </svg>
          }
          nextLabel={
            <svg className="icon icon-primary">
              <use xlinkHref={`${iconSprite}#it-chevron-right`}></use>
            </svg>
          }
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center col-12"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          initialPage={0}
        />
      )}

      <div className="text-right">
        <b>Totale: </b>
        {items ? items.length : ""}
      </div>
    </>
  );
}

export default PageSubjects;
