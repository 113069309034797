import { useEffect, useState } from "react";
import { Input, FormGroup, Label } from "design-react-kit";
import useLocalStorage from "../Utility/useLocalStorage";

function ViewUserProfile(props) {
  const [validation, setValidation] = useState();
  const [utenteID, setUtenteID] = useState();
  const [firstName, setFirstName] = useState();
  const [lastName, setLastName] = useState();
  const [cf, setCf] = useState();
  const [cfValidation, setCfValidation] = useState();
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [institution, setInstitution] = useState();
  const [institutionId, setInstitutionId] = useState();
  const [street, setStreet] = useState();
  const [crediti, setCrediti] = useState();
  const [houseNumber, setHouseNumber] = useState();
  const [city, setCity] = useState();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [qualification, setQualification] = useState();
  const [subscribers, setSubscribers] = useState(false);
  const [verifySubscribers, setVerifiySubscribers] = useState();
  const [cap, setCap] = useState(false);
  const [tSelect, setTSelect] = useState();
  const [phone, setPhone] = useState();
  const [user, setUser] = useState(props.user);
  const [qSelect, setQSelect] = useState();

  const [editForce, setEditForce] = useState();
  const [loginLocalStorage, setLoginLocalStorage] =
    useLocalStorage("UserLoggedIn");

  const sendForm = () => {
    setValidation(true);
    const regexCF =
      /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/gm;
    const regexPW = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).{8,15}$/m;
    let passedValidation = true;

    if (props.user == false) {
      if (!firstName) passedValidation = false;
      if (!lastName) passedValidation = false;
      if (!cf) passedValidation = false;
      else {
        if (!cf.match(regexCF)) {
          setCfValidation(false);
          passedValidation = false;
        } else setCfValidation(true);
      }
      if (!email) passedValidation = false;
    }
    // if (!firstName) passedValidation = false;
    // if (!lastName) passedValidation = false;
    // if (!cf) passedValidation = false;
    // else {
    //   if (!cf.match(regexCF)) {
    //     setCfValidation(false);
    //     passedValidation = false;
    //   } else setCfValidation(true);
    // }
    if (!city) passedValidation = false;
    // if (!email) passedValidation = false;
    if (!phone) passedValidation = false;
    if (!houseNumber) passedValidation = false;
    if (!street) passedValidation = false;
    if (!cap) passedValidation = false;

    if (passedValidation) {
      var request = null;
      if (props.user == false) {
        request = {
          nome: firstName,
          cognome: lastName,
          codiceFiscale: cf,
          luogoResidenza: city,
          // email: email,
          telefono: phone,
          nCivico: houseNumber,
          via: street,
          cap: cap,
          isTesserato: subscribers,
          isVerificaTesserato: verifySubscribers,
        };
      } else {
        request = {
          luogoResidenza: city,
          telefono: phone,
          nCivico: houseNumber,
          via: street,
          cap: cap,
          isTesserato: subscribers,
          isVerificaTesserato: verifySubscribers,
        };
      }

      if (props.sendMail) {
        props.editMethod(request, utenteID);
      } else {
        props.editMethod(request);
      }
    }
  };
  useEffect(() => {
    if (props.qualifySelect) {
      setQSelect(props.qualifySelect);
    }

    if (props.institutionSelect) {
      setTSelect(props.institutionSelect);
    }

    if (props.profile) {
      var data = props.profile;
      setEditForce(props.displayMode);
      if (data) {
        //utente
        setUtenteID(data.utenteID);
        setFirstName(data.nome);
        setLastName(data.cognome);
        setCrediti(data.crediti && data.crediti);
        setCf(data.codiceFiscale);
        setCity(data.city);
        setEmail(data.email);
        setPassword(data.password);
        // setQualification(data.qualifica);
        setPhone(data.telefono);
        setHouseNumber(data.nCivico);
        setStreet(data.via);
        setCap(data.cap);
        setCity(data.luogoResidenza);
        setPassword(data.password);
        setSubscribers(data.isTesserato);
        setVerifiySubscribers(data.isVerificaTesserato);
        // setInstitution(data.ente && data.ente.nomeEnte);
        // setInstitutionId(data.ente && data.ente.enteID);
      }
    }
  }, [props]);

  const changeInstitution = (event) => {
    setInstitution(event.label);
    setInstitutionId(event.value);
  };

  const obbjTesserato = [
    { label: "SI", value: true },
    { label: "NO", value: false },
  ];

  const changeTesserato = (event) => {
    setSubscribers(event.value);
  };

  const changeQualification = (event) => {
    setQualification(event.value);
  };
  return (
    <>
      <>
        <div className="col d-flex justify-content-center ">
          <div className="card-body p-1">
            <div>
              <div className="col-8 p-0">
                <h3>Dati Utente</h3>
              </div>
              <br />
              <div className="row ">
                <div className="col-12 mb-4">
                  <h6>Dati Anagrafici</h6>
                </div>

                <div className="col-lg-3">
                  <Input
                    onChange={(e) => setFirstName(e.target.value)}
                    id="firstName "
                    label="Nome"
                    name="firstName"
                    value={firstName ? firstName : ""}
                    {...(validation &&
                      !firstName && {
                        infoText: "Il campo non può essere vuoto!",
                      })}
                    {...(validation &&
                      !firstName && { className: "text-danger" })}
                    readOnly={!user ? editForce : true}
                    disabled={!user ? editForce : true}
                  />
                </div>

                <div className="col-lg-3">
                  <Input
                    label="Cognome"
                    id="lastName"
                    name="lastName"
                    value={lastName ? lastName : ""}
                    {...(validation &&
                      !lastName && {
                        infoText: "Il campo non può essere vuoto!",
                      })}
                    {...(validation &&
                      !lastName && { className: "text-danger" })}
                    onChange={(e) => setLastName(e.target.value)}
                    readOnly={!user ? editForce : true}
                    disabled={!user ? editForce : true}
                  />
                </div>

                <div className="col-lg-6">
                  <Input
                    label="Codice Fiscale"
                    id="cf"
                    name="cf"
                    value={cf ? cf : ""}
                    {...(validation &&
                      !cf && {
                        infoText: "Il campo non può essere vuoto!",
                      })}
                    {...(validation && !cf && { className: "text-danger" })}
                    // {...(validation &&
                    //   !cfValidation && { className: "text-danger" })}
                    {...(validation &&
                      !user &&
                      !cfValidation && {
                        infoText: "Codice Fiscale non coretto",
                        className: "text-danger",
                      })}
                    readOnly={!user ? editForce : true}
                    disabled={!user ? editForce : true}
                    onChange={(e) => setCf(e.target.value)}
                  />
                </div>
              </div>

              <div className="row mt-2">
                {/* <div className="col-lg-5">
                  <div className="bootstrap-select-wrapper">
                    <label htmlFor="selectExampleDisabled">Ente</label>
                    <Select
                      label="Seleziona..."
                      clearText="Annulla"
                      defaultValue={institution}
                      defaultMenuIsOpen={false}
                      icon={true}
                      options={tSelect}
                      id="institution"
                      onChange={changeInstitution}
                      isSearchable
                      placeholder={institution ? institution : "Seleziona..."}
                      isDisabled={editForce}
                    />

                    {validation && !institution ? (
                      <small
                        id="errorSelect"
                        className="form-text  text-danger"
                      >
                        Seleziona un campo
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>
                </div> */}
                {/* <div className="col-lg-3">
                  <div className="bootstrap-select-wrapper">
                    <label htmlFor="selectExampleDisabled">Qualifica</label>
                    <Select
                      clearText="Annulla"
                      icon={true}
                      options={qSelect}
                      defaultValue={qualification}
                      id="qualifica"
                      onChange={changeQualification}
                      isSearchable
                      placeholder={
                        qualification ? qualification : "Seleziona..."
                      }
                      isDisabled={editForce}
                    />

                    {validation && !qualification ? (
                      <small
                        id="errorSelect"
                        className="form-text  text-danger"
                      >
                        Seleziona un campo
                      </small>
                    ) : (
                      <></>
                    )}
                  </div>
                </div> */}
                {crediti && crediti ? (
                  <>
                    {/* <div className="col-lg-2">
                      <div className="bootstrap-select-wrapper">
                        <label htmlFor="selectExampleDisabled">Tesserato</label>
                        <Select
                          label="Seleziona..."
                          clearText="Annulla"
                          value={{
                            label: subscribers ? "SI" : "NO",
                            value: subscribers ? true : false,
                          }}
                          defaultMenuIsOpen={false}
                          icon={true}
                          options={obbjTesserato}
                          id="payed"
                          onChange={changeTesserato}
                          isSearchable
                          isDisabled={editForce}
                        />
                      </div>
                    </div> */}
                    <div className="col-lg-2">
                      <Input
                        label="Crediti"
                        name="crediti"
                        id="crediti"
                        value={crediti ? crediti : ""}
                        disabled
                      />
                    </div>
                  </>
                ) : (
                  <>
                    {/* <div className="col-lg-4">
                      <div className="bootstrap-select-wrapper">
                        <label htmlFor="selectExampleDisabled">Tesserato</label>
                        <Select
                          label="Seleziona..."
                          clearText="Annulla"
                          value={{
                            label: subscribers ? "SI" : "NO",
                            value: subscribers ? true : false,
                          }}
                          defaultMenuIsOpen={false}
                          icon={true}
                          options={obbjTesserato}
                          id="payed"
                          onChange={changeTesserato}
                          isSearchable
                          isDisabled={editForce}
                        />
                      </div>
                    </div> */}
                  </>
                )}
              </div>

              <div className="row mt-2">
                <div className="col-12 mb-4">
                  <h6>Residenza</h6>
                </div>

                <div className="col-lg-4">
                  <Input
                    onChange={(e) => setCity(e.target.value)}
                    label="Città"
                    name="city"
                    id="city"
                    value={city ? city : ""}
                    {...(validation &&
                      !city && {
                        infoText: "Il campo non può essere vuoto!",
                      })}
                    {...(validation && !city && { className: "text-danger" })}
                    disabled={editForce}
                  />
                </div>

                <div className="col-lg-2">
                  <Input
                    onChange={(e) => setCap(e.target.value)}
                    label="CAP"
                    name="cap"
                    id="cap"
                    value={cap ? cap : ""}
                    {...(validation &&
                      !cap && {
                        infoText: "Il campo non può essere vuoto!",
                      })}
                    {...(validation && !cap && { className: "text-danger" })}
                    disabled={editForce}
                  />
                </div>

                <div className="col-lg-4">
                  <Input
                    onChange={(e) => setStreet(e.target.value)}
                    label="Via"
                    name="street"
                    id="street"
                    value={street ? street : ""}
                    {...(validation &&
                      !street && {
                        infoText: "Il campo non può essere vuoto!",
                      })}
                    {...(validation && !street && { className: "text-danger" })}
                    disabled={editForce}
                  />
                </div>

                <div className="col-lg-2">
                  <Input
                    onChange={(e) => setHouseNumber(e.target.value)}
                    label="N. Civico"
                    name="houseNumber"
                    id="houseNumber"
                    value={houseNumber ? houseNumber : ""}
                    {...(validation &&
                      !houseNumber && {
                        infoText: "Il campo non può essere vuoto!",
                      })}
                    {...(validation &&
                      !houseNumber && { className: "text-danger" })}
                    disabled={editForce}
                  />
                </div>
              </div>
              <div className="row mt-2">
                <div className="col-12 mb-4">
                  <h6>Recapiti</h6>
                </div>

                <div className="col-lg-4">
                  <Input
                    onChange={(e) => setEmail(e.target.value)}
                    label="Email"
                    name="email"
                    id="email"
                    value={email ? email : ""}
                    {...(validation &&
                      !email && {
                        infoText: "Il campo non può essere vuoto!",
                      })}
                    {...(validation && !email && { className: "text-danger" })}
                    readOnly={true}
                    disabled={true}
                  />
                </div>

                <div className="col-lg-4">
                  <Input
                    onChange={(e) => setPhone(e.target.value)}
                    label="Telefono"
                    name="phone"
                    id="phone"
                    value={phone ? phone : ""}
                    {...(validation &&
                      !phone && {
                        infoText: "Il campo non può essere vuoto!",
                      })}
                    {...(validation && !phone && { className: "text-danger" })}
                    disabled={editForce}
                  />
                </div>
              </div>
              <h5>
                <div className="row">
                  <>
                    {editForce ? (
                      <>
                        {" "}
                        <div className="col-12 text-right">
                          <button
                            type="button"
                            className="btn btn-primary "
                            onClick={() => setEditForce(!editForce)}
                          >
                            Modifica
                          </button>
                        </div>
                      </>
                    ) : (
                      <>
                        {" "}
                        <div className="col-10 text-right">
                          <a
                            onClick={() => sendForm()}
                            className="btn btn-primary text-white"
                          >
                            Salva
                          </a>
                        </div>
                        <div className="col-2 text-right">
                          <button
                            type="submit"
                            className="btn btn-danger "
                            onClick={() => setEditForce(!editForce)}
                          >
                            Indietro
                          </button>
                        </div>
                      </>
                    )}
                  </>
                </div>
              </h5>
            </div>
          </div>
        </div>
      </>
    </>
  );
}

export default ViewUserProfile;
