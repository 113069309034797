import axios from "axios";
import configuration from "./config.json";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const base_url = process.env.REACT_APP_HUB_URL;

const hub_zoom = process.env.REACT_APP_HUB_ZOOM;

// var entityId = "8fefaafb-5a9f-44a6-b499-e4b56998eaf4";

const Repo = {
  postLogin: (obb) => {
    var header = {
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      headers: header,

      url: base_url + configuration.URL_POST_LOGIN,
      data: obb,
    };
    return axios(config);
  },

  //temporaneo
  getMyCourses: (token) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_MYCOURSES,
      headers: {
        "content-type": "application/octet-stream",

        Authorization: "Bearer " + token,
      },
      params: {},
    }),

  getInstitution: () =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_INSTITUTION,
      headers: {},
      params: {},
    }),

  getOneInstitution: (token, id) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_ONE_INSTITUTION + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  putInstitution: (token, obb, id) => {
    var config = {
      method: "Put",
      url: base_url + configuration.URL_PUT_INSTITUTION + id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postInstitution: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_INSTITUTION,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  filteredInstitution: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_FILTERED_INSTITUTION,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  deleteInstitution: (token, id) => {
    var config = {
      method: "delete",
      url: base_url + configuration.URL_DELETE_INSTITUTION + id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    return axios(config);
  },

  getMyCourses: (token) =>
    axios({
      method: "get",
      url: base_url + configuration.URL_GET_MYCOURSES,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  getOneSession: (token, id) =>
    axios({
      method: "get",
      url: base_url + configuration.URL_GET_ONE_SESSION + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  getUsers: (token, obb) => {
    var header = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      headers: header,

      url: base_url + configuration.URL_GET_USERS,
      data: obb,
    };
    return axios(config);
  },

  putUser: (token, obb) => {
    var config = {
      method: "put",
      url: base_url + configuration.URL_PUT_USER,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  putUserAdmin: (token, obb, params) => {
    var config = {
      method: "put",
      url: base_url + configuration.URL_PUT_USER_ADMIN + params,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  getConfirmRegistration: (token, id) =>
    axios({
      method: "get",
      url: base_url + configuration.URL_GET_CONFIRM_REGISTRATION + "?Id=" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  getSubjects: (token) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_SUBJECTS,
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {},
    }),

  getOneSubject: (token, id) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_ONE_SUBJECT + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  getAuthenticated: (token) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_AUTHENTICATED,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  putSubject: (token, obb, id) => {
    var config = {
      method: "Put",
      url: base_url + configuration.URL_PUT_SUBJECT + id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postSubject: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_SUBJECT,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  deleteSubject: (token, id) => {
    var config = {
      method: "delete",
      url: base_url + configuration.URL_DELETE_SUBJECT + id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    return axios(config);
  },

  getSelect: (tipology, token) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_SELECT + "?Tipologia=" + tipology,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    }),

  postAllSessionAvailable: (token, obb) => {
    var header = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      headers: header,

      url: base_url + configuration.URL_GET_ALL_CORSI_DISPONIBILI,
      data: obb,
    };
    return axios(config);
  },

  postAllSessionAvailableNoLogin: (token, obb) => {
    var header = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      headers: header,

      url: base_url + configuration.URL_GET_ALL_CORSI_DISPONIBILI_NO_LOGIN,
      data: obb,
    };
    return axios(config);
  },

  postAllSession: (token, obb) => {
    var header = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      headers: header,

      url: base_url + configuration.URL_GET_ALL_SESSION,
      data: obb,
    };
    return axios(config);
  },

  postUserFromCourse: (token, obb) => {
    var header = {
      Authorization: "Bearer " + token,
      "Content-Type": "application/json",
    };

    var config = {
      method: "post",
      headers: header,

      url: base_url + configuration.URL_GET_USER_FOR_COURSE,
      data: obb,
    };
    return axios(config);
  },

  postRegistration: (obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_REGISTRATION,
      headers: {
        "Content-Type": "application/json",
      },
      data: obb,
    };

    return axios(config);
  },

  deleteRegistration: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_DELETE_REGISTRATION,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  registerConfirm: (token, userId) =>
    axios({
      method: "GET",
      url:
        base_url +
        configuration.URL_REGISTER_CONFIRM +
        "?userId=" +
        userId +
        "&token=" +
        token,
    }),

  postSignedUp: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_GET_SEND_SIGNED_UP,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };

    return axios(config);
  },

  deleteSignedUp: (token, idUser, idSession) => {
    var axios = require("axios");

    var config = {
      method: "post",
      url:
        base_url +
        configuration.URL_DELETE_SIGNED_UP +
        "/" +
        idSession +
        "/" +
        idUser,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    return axios(config);
  },

  utentiFilter: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_UTENTI_FILTER,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };

    return axios(config);
  },

  postSignedUpOperator: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_GET_SEND_SIGNED_UP_OPERATOR,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };

    return axios(config);
  },

  postNewCourse: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_NEW_COURSE,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postNewSession: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_NEW_SESSION,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  getSelectCourse: (token) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_SELECT_COURSE,

      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  postUsersList: (token, sessionId) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_USER_LIST + sessionId,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/pdf",
        Accept: "application/json",
      },
      // data: obb,
      responseType: "blob",
    };

    return axios(config);
  },

  getCertificatoCorso: (token, iscrizioneId) =>
    axios({
      method: "GET",
      url:
        base_url +
        configuration.URL_GET_CERTIFICATO +
        "?iscrizioneId=" +
        iscrizioneId,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/pdf",
        Accept: "application/json",
      },
      responseType: "blob",
    }),

  getMaterial: (token, id) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_MATERIAL + "?idSessione=" + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  getDownloadMaterial: (token, id) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_DOWNLOAD_MATERIAL + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  deleteMaterial: (token, id) =>
    axios({
      method: "delete",
      url: base_url + configuration.URL_DELETE_MATERIAL + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  postMaterial: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_MATERIAL,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };

    return axios(config);
  },

  getCourses: (token) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_POST_GET_COURSES,
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {},
    }),

  getOneCourse: (token, id) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_POST_GET_ONE_COURSE + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  deleteCourse: (token, id) => {
    var config = {
      method: "delete",
      url: base_url + configuration.URL_DELETE_COURSE + id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    return axios(config);
  },

  deleteSession: (token, id) => {
    var config = {
      method: "delete",
      url: base_url + configuration.URL_DELETE_SESSION + id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    return axios(config);
  },

  putCourse: (token, obb, idCorso) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_PUT_COURSE + "?idCorso=" + idCorso,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };

    return axios(config);
  },

  putSessionPassed: (token, idIscriziona) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_COURSE_PASSED + "/" + idIscriziona,
      headers: {
        Authorization: "Bearer " + token,
      },
      params: {},
    }),

  editSessionCourse: (token, obb, id) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_EDIT_SESSION + "/" + id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };

    return axios(config);
  },

  getSessioniByCorso: (token, id) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_SESSIONI_CORSO + id,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  postModalitaPagamento: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_MODALITA_PAGAMENTO,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };

    return axios(config);
  },

  postForgotPassword: (email) => {
    var config = {
      method: "post",
      url:
        base_url + configuration.URL_POST_FORGOT_PASSWORD + "?email=" + email,
      headers: {
        "Content-Type": "application/json",
      },
    };
    return axios(config);
  },

  getResetPassword: (token, userId, password) =>
    axios({
      method: "GET",
      url:
        base_url +
        configuration.URL_GET_RESET_PASSWORD +
        "?userId=" +
        userId +
        "&token=" +
        token +
        "&password=" +
        password,

      params: {},
    }),

  getConfirmedSigneUp: (token, userId) =>
    axios({
      method: "GET",
      url:
        base_url +
        configuration.URL_GET_CONFIRM_SIGNEDUP +
        "?userId=" +
        userId +
        "&token=" +
        token,

      params: {},
    }),

  closeSession: (token, id) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_CLOSE_SESSION + id,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    return axios(config);
  },

  postNewMeeting: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_ADD_MEETING,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: obb,
    };

    return axios(config);
  },

  getMeetingBySessione: (token, sessioneId, role) =>
    axios({
      method: "GET",
      url:
        base_url +
        configuration.URL_GET_MEETING_BY_SESSIONE +
        "?sessioneCorsoID=" +
        sessioneId +
        "&role=" +
        role,
      headers: {
        Authorization: "Bearer " + token,
      },

      params: {},
    }),

  deleteMeeting: (token, meetingID) => {
    var config = {
      method: "delete",
      url: base_url + configuration.URL_DELETE_MEETING + meetingID,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };
    return axios(config);
  },

  setMeetingStatus: (token, meetingID, status) => {
    var config = {
      method: "patch",
      url:
        base_url +
        configuration.URL_SET_MEETING_STATUS +
        "?meetingNumber=" +
        meetingID +
        "&status=" +
        status,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    return axios(config);
  },

  getRecordingsBySessione: (token, sessionId) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_RECORDINGS_BY_SESSIONE + sessionId,
      headers: {
        Authorization: "Bearer " + token,
      },
    }),

  getRecording: (token, recordingId) =>
    axios({
      method: "GET",
      url: base_url + configuration.URL_GET_RECORDING + recordingId,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/pdf",
        Accept: "application/json",
      },
      responseType: "blob",
    }),

  createMeeting: () =>
    axios({
      method: "GET",
      url: hub_zoom + configuration.URL_ZOOM_CREATE_MEETING,
    }),

  getSignature: (meetingNumber, role) =>
    axios({
      method: "GET",
      url:
        hub_zoom +
        configuration.URL_ZOOM_GET_SIGNATURE +
        "?meetingNumber=" +
        meetingNumber +
        "&role=" +
        role,
    }),

  postPagamento: (token, obb) => {
    var config = {
      method: "post",
      url: base_url + configuration.URL_POST_PAGAMENTO,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      },
      data: obb,
    };
    return axios(config);
  },

  postYTLink: (token, meetingNumber, link) => {
    var config = {
      method: "patch",
      url:
        base_url +
        configuration.URL_RECORDING_LINK_YT +
        "?meetingNumber=" +
        meetingNumber +
        "&link=" +
        link,
      headers: {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    };

    return axios(config);
  },
};

export default Repo;
