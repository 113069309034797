import { useEffect, useState } from "react";
import Select from "../Objects/Select";

import Repo from "../Utility/Repo";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";

function SelectPayments(props) {
  const [payment, setPayment] = useState("Enti");

  const [paymentsList, setPaymentsList] = useState([]);
  const [paymentSelect, setPaymentSelect] = useState([]);

  const [startValidate, setStartValidate] = useState(false);

  const [insertMode, setInsertMode] = useState([false]);
  const [namePayment, setNamePayment] = useState();
  const [amount, setAmount] = useState();

  const changePayment = (id, label, importo) => {
    setPaymentsList(
      paymentsList.filter(function (f) {
        return f.value !== id;
      })
    );
    var temp = paymentSelect;
    temp.push({ value: id, label: label, importo: importo });

    setPaymentSelect(temp);

    props.setValue(
      paymentSelect.map(function (a) {
        return { descrizione: a.label, importo: a.importo };
      })
    );
  };

  const deletePayment = (id, label, importo) => {
    var tempLIST = paymentSelect.filter(function (f) {
      return f.value !== id;
    });
    setPaymentSelect(
      paymentSelect.filter(function (f) {
        return f.value !== id;
      })
    );
    var temp = paymentsList;
    temp.push({ value: id, label: label, importo: importo });
    setPaymentsList(temp);
    props.setValue(
      tempLIST.map(function (a) {
        return { descrizione: a.label, importo: a.importo };
      })
    );
  };

  const sendNewPayment = () => {
    setStartValidate(true);

    var validatePassed = true;
    if (!amount) validatePassed = false;
    if (!namePayment) validatePassed = false;

    if (validatePassed) {
      var temp = paymentSelect;
      temp.push({ value: namePayment, label: namePayment, importo: amount });

      setPaymentSelect(temp);
      props.setValue(
        paymentSelect.map(function (a) {
          return { descrizione: a.label, importo: a.importo };
        })
      );
      setInsertMode(true);
    }
  };

  useEffect(() => {
    Repo.getPagamentiSessioni(props.apiToken)
      .then((response) => {
        if (response.status == 200) {
          var tempList = [];
          response.data.map((x) => {
            tempList.push({
              value: x.pagamentoSessioneID,
              label: x.descrizione,
              importo: x.importo,
            });
          });

          setPaymentsList(
            tempList.filter(
              (v, i, a) => a.findIndex((t) => t.label === v.label) === i
            )
          );
        }
      })
      .catch((error) => {
        setPaymentsList([]);
        console.log(error);
      });
  }, [props.apiToken]);

  return (
    <div className="row mt-3">
      <div className="col-9 mb-2">
        {insertMode ? (
          <>
            <b>Duplica un pagamento già inserito</b>
          </>
        ) : (
          <b>Nuovo Pagamento</b>
        )}
      </div>
      <div className="col-3 mb-2 text-right">
        <button
          className="btn btn-outline-primary  "
          onClick={() => setInsertMode(!insertMode)}
        >
          {insertMode ? (
            <>
              <>Nuovo Pagamento</>
            </>
          ) : (
            <>Duplica Pagamento</>
          )}
        </button>
      </div>
      {insertMode ? (
        <>
          <div
            className="col-5 border roundedCustom"
            style={{ height: "250px", overflow: "auto", padding: "10px" }}
          >
            <div className="it-list-wrapper">
              <ul className="it-list pt-2">
                {paymentsList &&
                  paymentsList.map((x, index) => (
                    <li key={index} className="py-2">
                      <a
                        onClick={() => {
                          changePayment(x.value, x.label, x.importo);
                        }}
                      >
                        <div className="mr-2">
                          {" "}
                          <b>{x.label}</b>
                        </div>
                        <div>
                          {x.importo}
                          <b>€</b>
                        </div>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
          <div
            className="col-2 text-center align-middle"
            style={{ paddingTop: "9%" }}
          >
            <svg
              className="icon icon-primary icon-lg"
              style={{ cursor: "pointer" }}
            >
              <use href={`${iconSprite}#it-arrow-left-triangle`} />
            </svg>
            <svg
              className="icon icon-primary icon-lg"
              style={{ cursor: "pointer" }}
            >
              <use href={`${iconSprite}#it-arrow-right-triangle`} />
            </svg>
          </div>
          <div
            className="col-5 border border-primaryroundedCustom"
            style={{ height: "250px", overflow: "auto", padding: "10px" }}
          >
            {" "}
            <div className="it-list-wrapper">
              <ul className="it-list pt-2">
                {paymentSelect &&
                  paymentSelect.map((x, index) => (
                    <li key={index} className="py-2">
                      <a
                        onClick={() => {
                          deletePayment(x.value, x.label, x.importo);
                        }}
                      >
                        <div className="mr-2">
                          {" "}
                          <b>{x.label}</b>
                        </div>
                        <div>
                          {x.importo}
                          <b>€</b>
                        </div>
                      </a>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </>
      ) : (
        <>
          {" "}
          <div className="col-12">
            <div className="row">
              <div className="col-10">
                <div className="row">
                  <div className="col-12">
                    <Input
                      id="name"
                      name="name"
                      placeholder="Inserisci descrizione Pagamento"
                      onChange={(e) => setNamePayment(e.target.value)}
                      {...(startValidate &&
                        !namePayment && {
                          infoText: "Il campo non può essere vuoto!",
                        })}
                      {...(startValidate &&
                        !namePayment && { className: "text-danger" })}
                    />
                  </div>
                  <div className="col-6">
                    <Input
                      id="amount"
                      name="amount"
                      placeholder="Inserisci importo"
                      type="number"
                      onChange={(e) => setAmount(e.target.value)}
                      {...(startValidate &&
                        !amount && {
                          infoText: "Il campo non può essere vuoto!",
                        })}
                      {...(startValidate &&
                        !amount && { className: "text-danger" })}
                    />
                  </div>
                </div>
              </div>
              <div className="col-2 text-right py-5">
                <Button onClick={() => sendNewPayment()}>INSERISCI</Button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default SelectPayments;
