import { useEffect, useState } from "react";

import { Input } from "design-react-kit";

import ReactPaginate from "react-paginate";
import moment from "moment";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Select from "../Objects/Select.js";
import Repo from "../Utility/Repo";
import ViewInstitution from "../Objects/ViewInstitution";
import { useLocation } from "react-router";

function PageCreateInstitution(props) {
  const [isOperator, setIsOperator] = useState(props.operator);
  const [institution, setInstitution] = useState({});
  const [apiToken, setApiToken] = useState(props.apiToken);
  const [sendEnte, setSendEnte] = useState(false);
  const [backButton, setBackButton] = useState(true);
  const [quote, setQuote] = useState("Quote");
  const [quoteSelect, setQuoteSelect] = useState({});

  useEffect(() => {
    setIsOperator(props.operator);

    if (apiToken) {
      Repo.getSelect(quote, apiToken)
        .then((response) => {
          if (response.status == 200) {
            setQuoteSelect(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, [props.operator]);

  const createSend = (request) => {
    if (apiToken) {
      Repo.postInstitution(apiToken, JSON.stringify(request))
        .then((response) => {
          window.location.href = props.subfolder + "/institution_management";
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  return (
    <>
      {/* Filtri */}

      {/* Ente */}

      <ViewInstitution
        editMethod={createSend}
        displayMode={false}
        sendEnte={sendEnte}
        backButton={backButton}
        institution={institution}
        quoteSelect={quoteSelect}
      ></ViewInstitution>
    </>
  );
}

export default PageCreateInstitution;
