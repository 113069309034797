import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Headers, SlimHeaderFullResponsive } from "design-react-kit";

// import smartcitylogo from "../../Img/smartcitylogo.PNG";
import mainLogo from "../../Img/tipografia-learning.png";
import configuration from "../Utility/config.json";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import logo from "../../Img/pittogramma-learning.svg";
import { Link } from "react-router-dom";

function Header(props) {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isOperator, setIsOperator] = useState(props.operator);
  const [width, setWidth] = useState(window.innerWidth);

  const [pathname] = useState(location.pathname);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [width]);

  useEffect(() => {
    if (cookies.user_profile) {
      if (cookies.user_profile.WorkingEntityId) {
        setIsOperator(true);
      }
    }
  }, [cookies]);

  const login = () => {
    if (pathname == "/" && !props.isLogged) {
      return <></>;
    } else {
      return (
        <Link to="/" className="btn btn-primary btn-icon btn-full mx-0">
          <span className="rounded-icon">
            <svg className="icon icon-primary">
              <use href={`${iconSprite}#it-user`} />
            </svg>
          </span>
          <span className="d-lg-block">Accedi ai servizi</span>
        </Link>
      );
    }
  };

  const logout = () => {
    return (
      <>
        <div className="it-user-wrapper nav-item dropdown">
          <Link
            to="/#"
            className="btn btn-primary btn-icon btn-full"
            data-toggle="dropdown"
            // aria-haspopup="true"
            aria-expanded="false"
          >
            <span className="rounded-icon">
              <svg className="icon icon-primary ml-0">
                <use href={`${iconSprite}#it-user`} />
              </svg>
            </span>
            <span className="d-none d-lg-block">
              {props.user.nome} {props.user.cognome}
            </span>
          </Link>
          <div
            className="dropdown-menu"
            x-placement="bottom-start"
            style={{
              position: "absolute",
              willChange: "transform",
              top: "0px",
              left: "0px",
              transform: "translate3d(0px, 48px, 0px)",
            }}
          >
            <div className="link-list-wrapper">
              <ul className="link-list ">
                <li>
                  <Link className="list-item" to="/page_user_profile">
                    <svg className="icon icon-primary">
                      <use xlinkHref={`${iconSprite}#it-user`}></use>
                    </svg>

                    <span className="ml-1">
                      <b>Profilo</b>
                    </span>
                  </Link>
                </li>
                <hr className="mx-3" />
                <li>
                  <Link
                    className="list-item"
                    to="/"
                    onClick={() => deleteStorage()}
                  >
                    <svg className="icon icon-primary">
                      <use xlinkHref={`${iconSprite}#it-external-link`}></use>
                    </svg>

                    <span className="ml-1">
                      <b>Esci</b>
                    </span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  };

  const deleteStorage = () => {
    removeCookie("ente");
    localStorage.clear();
    window.location.href = props.subfolder + "/";
  };

  return (
    <>
      <header className="it-header-wrapper it-header-sticky">
        <div className="it-header-slim-wrapper">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="it-header-slim-wrapper-content">
                  <Link
                    className=" d-lg-block navbar-brand"
                    to="https://www.servizilocalispa.it/"
                  ></Link>

                  <div className="it-header-slim-right-zone ">
                    {props.isLogged && (
                      <>
                        {/* {props.linksEsterni && props.linksEsterni.length < 2 ? (
                          <>
                            <div className="nav-item d-none d-lg-block">
                              {props.linksEsterni &&
                                props.linksEsterni.map((linkObb, index) => (
                                  <span className="linkMenu" key={index}>
                                    <Link to={linkObb.Url}>
                                      {linkObb.Title}
                                    </Link>
                                  </span>
                                ))}
                            </div>
                          </>
                        ) : (
                          <div className="nav-item dropdown">
                            <Link
                              className="nav-link dropdown-toggle"
                              to="/#"
                              data-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <span>SemplicitaLearning</span>
                              <svg className="icon d-none d-lg-block align-middle">
                                <use href={`${iconSprite}#it-expand`} />
                              </svg>
                            </Link>
                            <div className="dropdown-menu">
                              <div className="row">
                                <div className="col-12">
                                  <div className="link-list-wrapper">
                                    <ul className="link-list">
                                      <li>
                                        <Link
                                          className="list-item"
                                          to="https://smartcondominio.servizilocalispa.it:3002/"
                                        >
                                          <span>Smart Condominio</span>
                                        </Link>{" "}
                                      </li>
                                      {props.linksEsterni &&
                                        props.linksEsterni.map(
                                          (linkObb, index) => (
                                            <li key={index}>
                                              <Link
                                                className="list-item"
                                                to={linkObb.Url}
                                              >
                                                <span> {linkObb.Title}</span>
                                              </Link>{" "}
                                            </li>
                                          )
                                        )}
                                    </ul>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )} */}
                      </>
                    )}

                    <div className="it-access-top-wrapper mr-2">
                      {props.isLogged ? logout() : login()}
                    </div>

                    {/*props.LastName && (
                      <div style={{ marginLeft: "5px", padding: "2px" }}>
                        <svg className="icon icon-light  ">
                          {" "}
                          <use href={`${iconSprite}#it-horn`} />
                        </svg>
                        <span className="badge badge-warning">2</span>{" "}
                        {/* <span className="badge badge-danger">9</span> */}
                    {/*                                        
                                        <svg className="icon icon-light  ">  <use href={`${iconSprite}#it-folder`} /></svg>
                                        <span className="badge badge-light">9</span>
                                        <span className="sr-only">Messaggi non letti<span className="badge badge-light">0</span></span> 
                      </div>
                    )}*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="it-nav-wrapper">
          <div className="it-header-center-wrapper">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="it-header-center-content-wrapper ">
                    <div className="it-brand-wrapper px-0 mx-0">
                      <div className="row">
                        {width < 992 && (
                          <div className="col-2 pt-3">
                            <a className="">
                              <button
                                className="custom-navbar-toggler"
                                type="button"
                                aria-controls="nav02"
                                aria-expanded="false"
                                aria-label="Toggle navigation"
                                data-target="#nav02"
                              >
                                <svg className="icon">
                                  <use
                                    href={`${iconSprite}#it-burger`}
                                    width="70%"
                                  />
                                </svg>
                              </button>
                            </a>
                          </div>
                        )}
                        <div className="col-10 mt-1">
                          <a className="">
                            <img height="105" src={logo} alt="logo" />
                            <div className="it-brand-text">
                              <h2 className="no_toc ">
                                {" "}
                                <img
                                  className="img-fluid"
                                  src={mainLogo}
                                  alt="mainlogo"
                                  style={{
                                    marginTop: "4px",
                                    width: "365px",
                                  }}
                                />
                              </h2>
                            </div>
                          </a>
                        </div>{" "}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Headers shadow={false} sticky>
            <div className="it-header-navbar-wrapper ">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <nav className="navbar navbar-expand-lg has-megamenu">
                      <div
                        className="navbar-collapsable"
                        id="nav02"
                        style={{ display: "none", width: "80%" }}
                      >
                        <div
                          className="overlay"
                          style={{ display: "none" }}
                        ></div>
                        <div className="close-div sr-only">
                          <button className="btn close-menu" type="button">
                            <span className="it-close"></span>close
                          </button>
                        </div>
                        <div className="row menu-wrapper">
                          <ul className="navbar-nav">
                            {props.links &&
                              props.links.map((linkObb, index) => (
                                <li className="nav-item active" key={index}>
                                  <Link className="nav-link" to={linkObb.url}>
                                    <span style={{ width: "max-content" }}>
                                      {linkObb.testo}{" "}
                                    </span>
                                    <span className="sr-only">current</span>
                                  </Link>
                                </li>
                              ))}
                            {width < 992 && (
                              <>
                                {props.isLogged &&
                                  !props.operator &&
                                  props.user.isVerificaTesserato &&
                                  props.user.isTesserato && (
                                    <li className="nav-item active">
                                      <a className="nav-link">
                                        <span style={{ width: "max-content" }}>
                                          Numero Crediti Totali:{" "}
                                          {props.credits ? props.credits : "0"}
                                        </span>
                                      </a>
                                    </li>
                                  )}
                              </>
                            )}
                          </ul>
                        </div>
                      </div>
                      {width > 992 && (
                        <>
                          {props.isLogged &&
                            !props.operator &&
                            props.user.isVerificaTesserato &&
                            props.user.isTesserato && (
                              <div
                                className="text-white"
                                style={{ width: "max-content" }}
                              >
                                Numero Crediti Totali:{" "}
                                {props.credits != null ? props.credits : "0"}
                              </div>
                            )}
                        </>
                      )}
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </Headers>
        </div>
      </header>
    </>
  );
}

export default Header;
