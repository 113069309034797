import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import { useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import Repo from "../Utility/Repo";

function PageResetPassword(props) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);

  const paramsId = query.get("userid");

  const token = query.get("token");

  const [result, setResult] = useState();

  const [passwordValidation, setPasswordValidation] = useState(true);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [validation, setValidation] = useState(false);
  const [disableButton, setDisableButton] = useState(false);

  const sendForm = (event) => {
    event.preventDefault();
    setValidation(true);

    const regexPW =
      /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[-_.,:@$!%*#&])[-_A-Za-z\d.,:@$!%*#&]{8,}$/m;

    let passedValidation = true;

    if (!password) passedValidation = false;
    else {
      setPasswordValidation(true);

      if (password !== confirmPassword) {
        passedValidation = false;
        setPasswordValidation(false);
      } else {
        if (!password.match(regexPW)) {
          passedValidation = false;
          setPasswordValidation(false);
        }
      }
    }

    if (passedValidation) {
      Repo.getResetPassword(token, paramsId, password)
        .then((response) => {
          setResult(true);
        })
        .catch((error) => {});
    } else {
      setDisableButton(false);
    }
  };

  return (
    <>
      {!result ? (
        <form className="needs-validation" onSubmit={sendForm}>
          <div className="row">
            <div className="col-12 mb-3">
              <h6>Credenziali</h6>
            </div>

            <div className="col-lg-6">
              {passwordValidation}
              <Input
                onChange={(e) => setPassword(e.target.value)}
                type="password"
                name="password"
                id="password"
                label="Password"
                {...(validation &&
                  !passwordValidation && { className: "text-danger" })}
                infoText="Inserisci almeno 8 caratteri e una lettera maiuscola ed un carattere speciale (ad eccezione del simbolo '?')"
              />
            </div>

            <div className="col-lg-6">
              <Input
                onChange={(e) => setConfirmPassword(e.target.value)}
                type="password"
                id="confirmPassword"
                name="confirmPassword"
                label="Password"
                {...(validation &&
                  confirmPassword !== password && {
                    className: "text-danger",
                  })}
                {...(validation && confirmPassword !== password
                  ? { infoText: "La Password non corrisponde" }
                  : {
                      infoText:
                        "Inserisci almeno 8 caratteri e una lettera maiuscola",
                    })}
              />
            </div>
          </div>
          <div className="row text-center">
            <div className="col-lg-12  ">
              <button
                type="submit"
                disabled={disableButton}
                className="btn btn-primary mt-4"
              >
                Cambia Password
              </button>
            </div>
          </div>
        </form>
      ) : (
        <>
          {" "}
          <div className="row col-12">
            <div className="col-9">
              <h3>Cambio Password andato a buon fine.</h3>
            </div>
            <div className="col-3">
              <Link className="btn btn-primary" to="/">
                Login
              </Link>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default PageResetPassword;
