import { useEffect, useState } from "react";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";

import RichTextEditor from "../Objects/RichTextEditor";

function ViewSubject(props) {
  const [validation, setValidation] = useState();
  const [subjectName, setSubjectName] = useState();
  const [description, setDescription] = useState();

  const [editForce, setEditForce] = useState();

  const sendForm = () => {
    setValidation(true);
    let passedValidation = true;

    if (!subjectName) passedValidation = false;
    if (!description) passedValidation = false;

    if (passedValidation) {
      if (props.sendEnte) {
        var request = {
          materiaID: props.children.materiaID,
          nome: subjectName,
          descrizione: description,
        };
      } else {
        var request = {
          nome: subjectName,
          descrizione: description,
        };
      }
      props.editMethod(request);
    }
  };

  const deleteSubject = () => {
    props.delete();
  };

  useEffect(() => {
    var data = props.children;

    setEditForce(props.displayMode);
    if (data) {
      setSubjectName(data.nome);
      setDescription(data.descrizione);
    }
  }, [props.children]);

  return (
    <>
      <div className="col d-flex justify-content-center mt-3">
        <div className="card-body">
          <div className="needs-validation">
            <br />

            <div className="row ">
              <div className="col-lg-12">
                <Input
                  onChange={(e) => setSubjectName(e.target.value)}
                  label="Nome Materia"
                  id="subjectName"
                  name="subjectName"
                  value={subjectName ? subjectName : ""}
                  {...(validation &&
                    !subjectName && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation &&
                    !subjectName && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>
            </div>
            {/* <div className="row ">
              <div className="col-lg-12">
                <textarea
                  onChange={(e) => setDescription(e.target.value)}
                  label="Descrizione"
                  id="description"
                  name="description"
                  rows="10"
                  value={description ? description : ""}
                  {...(validation &&
                    !description && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation &&
                    !description && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>
            </div> */}
            <div className="row mt-3">
              <div className="col-lg-8" style={{ zIndex: "1" }}>
                <RichTextEditor
                  contentDefault={description && description}
                  setContentText={setDescription}
                  readonly={editForce}
                ></RichTextEditor>

                {validation && !description ? (
                  <small
                    id="errorSelect"
                    className="form-text text-danger"
                    style={{ zIndex: "0" }}
                  >
                    Inserisci una descrizione per la materia
                  </small>
                ) : (
                  <></>
                )}
              </div>
            </div>
            <div className="row">
              {editForce ? (
                <>
                  {" "}
                  <div className="col-10 text-right">
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={() => setEditForce(!editForce)}
                    >
                      Modifica
                    </button>
                  </div>
                  <div className="col-2 text-right">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => props.toggleModal(!props.isOpen)}
                    >
                      Elimina
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="col-12 text-right ">
                    <button
                      onClick={() => sendForm()}
                      className="btn btn-primary mx-5"
                    >
                      Salva
                    </button>

                    {!props.backButton && (
                      <button
                        type="submit"
                        className="btn btn-info text-white ml-5"
                        onClick={() => setEditForce(!editForce)}
                      >
                        Indietro
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={props.isOpen}
        toggle={() => props.toggleModal(!props.isOpen)}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">Attenzione</ModalHeader>
        <ModalBody>Vuoi procedere con l'eliminazione della materia?</ModalBody>
        <ModalFooter>
          <Button
            onClick={() => props.toggleModal(!props.isOpen)}
            className="btn btn-info text-white"
          >
            Chiudi
          </Button>
          <Button onClick={() => deleteSubject()} className="btn btn-danger">
            Elimina
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ViewSubject;
