import { useEffect, useState, useRef } from "react";

import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

import Repo from "../Utility/Repo";
import RichTextEditor from "../Objects/RichTextEditor";
import SelectSubjects from "../Objects/SelectSubjects";

import SelectTermsOfPayment from "../Objects/SelectTermsOfPayment";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import UploadFile from "../Objects/UploadFile";
import { Link } from "react-router-dom";

function PageOperator(props) {
  const [subject, setSubject] = useState();

  const [name, setName] = useState();
  const [description, setDescription] = useState();

  const [formativeCredits, setFormativeCredits] = useState();
  const [isOpenConfirmation, toggleModalConfirmation] = useState(false);
  const [sendResult, setSendResult] = useState(false);

  const [startValidate, setStartValidate] = useState(false);
  const [idCoursResult, setIdCoursResult] = useState();
  // const [paymentsSelect, setPaymentsSelect] = useState([]);
  const [uploadReceipt, setUploadReceipt] = useState();

  function getBase64(file) {
    return new Promise(function (resolve) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  }

  const loadFile = async (id) => {
    var tempRequest = {
      corsoID: id,
    };
    var name = uploadReceipt.name.split(".")[0];
    var replaceName = name.replace(/[^\w\s]/gi, "");

    if (uploadReceipt) {
      var base64File = await getBase64(uploadReceipt);
      var splitBase64 = base64File.split(",");
      tempRequest["allegato"] = splitBase64[1];
      tempRequest["tipoFile"] = uploadReceipt.type;
      tempRequest["nome"] = replaceName;
    }

    Repo.postMaterial(props.apiToken, tempRequest)
      .then((response) => {})

      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  // const addPayments = async (id) => {
  //   var arrayTemp = [];

  //   paymentsSelect.map((x) => {
  //     arrayTemp.push({
  //       descrizione: x.descrizione,
  //       corsoID: id,
  //     });
  //   });

  // Repo.postModalitaPagamento(props.apiToken, JSON.stringify(arrayTemp))

  //   .then((response) => {})

  //   .catch((error) => {
  //     console.log(error);
  //   });
  // };

  const sendCourse = (e) => {
    e.preventDefault();
    setStartValidate(true);

    var validatePassed = true;
    if (!name) validatePassed = false;
    if (!subject) validatePassed = false;
    if (!description) validatePassed = false;
    if (!formativeCredits) validatePassed = false;

    if (validatePassed) {
      var tempRequest = {
        nome: name,
        creditiFormativi: formativeCredits,
        descrizione: description,
        materieList: subject,
      };
      toggleModalConfirmation(true);
      if (props.apiToken) {
        Repo.postNewCourse(props.apiToken, JSON.stringify(tempRequest))
          .then((response) => {
            if (response.data.statusCode == 202) {
              setSendResult(true);
              setIdCoursResult(response.data.data);
              if (uploadReceipt) {
                loadFile(response.data.data);
              }
            } else {
              setSendResult(false);
              if (response.data.statusCode == 401) {
                props.deleteCoockieExpired();
              }
            }
          })
          .catch((error) => {
            setSendResult(false);
            console.log(error);
            if (error.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <h3>Creazione Nuovo Corso</h3>
        </div>
        <div className="col-lg-12 mt-3">
          <Input
            label="Nome Corso"
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            required
            {...(startValidate &&
              !name && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate && !name && { className: "text-danger" })}
          />
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-6">
          <Input
            label="Crediti Formativi"
            id="formativeCredits"
            name="formativeCredits"
            type="number"
            min={0}
            max={100}
            {...(startValidate &&
              !formativeCredits && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate &&
              !formativeCredits && { className: "text-danger" })}
            onChange={(e) => setFormativeCredits(e.target.value)}
          />
        </div>

        <div className="col-lg-6" style={{ zIndex: "15" }}>
          <div className="bootstrap-select-wrapper">
            <label htmlFor="selectExampleDisabled">Materie</label>
            <SelectSubjects setValue={setSubject}></SelectSubjects>

            {startValidate && !subject ? (
              <small id="errorSelect" className="form-text text-danger">
                Seleziona almeno un campo
              </small>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className="row mt-3">
        <div className="col-lg-12" style={{ zIndex: "1" }}>
          <RichTextEditor
            setContentText={setDescription}
            contentDefault={"Inserisci una descrizione per il corso..."}
          ></RichTextEditor>

          {startValidate && !description ? (
            <small
              id="errorSelect"
              className="form-text text-danger"
              style={{ zIndex: "0" }}
            >
              Inserisci una descrizione per il corso
            </small>
          ) : (
            <></>
          )}
        </div>
      </div>

      {/* <div className="row mt-5 mb-2">
        <div className="col-lg-12">
          <div id="collapseDiv1" className="collapse-div" role="tablist">
            <div className="collapse-header" id="heading1">
              <button
                data-toggle="collapse"
                data-target="#collapse1"
                aria-expanded="true"
                aria-controls="collapse1"
              >
                <b>Aggiungi Pagamenti</b>
              </button>
            </div>
            <div
              id="collapse1"
              className="collapse"
              role="tabpanel"
              aria-labelledby="heading1"
            >
              <div className="collapse-body">
                <SelectTermsOfPayment
                  apiToken={props.apiToken}
                  setValue={setPaymentsSelect}
                ></SelectTermsOfPayment>
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <div className="row mt-5 pl-3">
        <div className="col-lg-12">
          <h5>Materiale Didattico</h5>
        </div>
        <UploadFile
          handleFile={setUploadReceipt}
          position={"text-right"}
          testoInterno={"Carica File"}
        ></UploadFile>
      </div>

      <div className="row mt-4">
        <div className="col-lg-12 text-right">
          <button className="btn btn-primary" onClick={sendCourse}>
            Salva corso
          </button>
        </div>
      </div>

      {isOpenConfirmation && (
        <div>
          <Modal
            isOpen={isOpenConfirmation}
            toggle={() => toggleModalConfirmation(!isOpenConfirmation)}
            centered
            size={"lg"}
          >
            <ModalHeader
              toggle={() => toggleModalConfirmation(!isOpenConfirmation)}
              id="esempio2"
            >
              {sendResult
                ? "Il corso è stato Creato"
                : "Qualcosa è andato storto!"}
            </ModalHeader>
            <ModalBody>
              {sendResult ? (
                <>
                  <div className="row">
                    <div className="col-12">
                      <h5>
                        Vuoi procedere con la creazione di una <b>Edizione</b>?
                      </h5>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12 d-flex justify-content-end">
                      <Link
                        to={
                          "/new_session?id=" + idCoursResult + "&name=" + name
                        }
                        className="btn btn-primary btn-sm mr-2"
                      >
                        Crea Edizione
                      </Link>
                      <Link
                        to={"/course_detail?id=" + idCoursResult}
                        className="btn btn-danger btn-sm"
                      >
                        Annulla
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h4>Qualcosa è andato storto!</h4>
                  <div className="row">
                    {" "}
                    <div className="col-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() =>
                          toggleModalConfirmation(!isOpenConfirmation)
                        }
                      >
                        Indietro
                      </button>
                    </div>{" "}
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        </div>
      )}
    </>
  );
}

export default PageOperator;
