import { useEffect, useState } from "react";
import Select from "../Objects/Select";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import useLocalStorage from "../Utility/useLocalStorage";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Repo from "../Utility/Repo";
import SelectInstitution from "../Objects/SelectInstitution";
import SelectQualification from "../Objects/SelectQualification";
import { Link } from "react-router-dom";

function SignedUp(props) {
  const [registrationPannel, setRegistrationPannel] = useState(false);
  const [validation, setValidation] = useState(false);
  const [firstName, setFirstName] = useState(false);
  const [lastName, setLastName] = useState(false);
  const [cf, setCf] = useState(false);
  const [cfValidation, setCfValidation] = useState(true);
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [institution, setInstitution] = useState();
  const [street, setStreet] = useState(false);
  const [houseNumber, setHouseNumber] = useState(false);
  const [city, setCity] = useState(false);
  const [email, setEmail] = useState(false);
  const [password, setPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState(false);
  const [cap, setCap] = useState();
  const [phone, setPhone] = useState();
  const [qualification, setQualification] = useState();
  const [tesserato, setTesserato] = useState(false);

  const [privacyOne, setPrivacyOne] = useState(false);
  const [privacyTwo, setPrivacyTwo] = useState(false);
  const [validationPrivacy, setValidationPrivacy] = useState(true);

  const [isOpen, toggleModal] = useState(false);
  const [isOpenCredentials, toggleModalCredentials] = useState(false);
  const [isOpenCheckEmail, toggleModalCheckEmail] = useState(false);
  const [isOpenError, toggleModalError] = useState(false);
  const [errorLogin, setErrorLogin] = useState();
  const [disableButton, setDisableButton] = useState(false);
  const [emailValidation, setEmailValidation] = useState(false);
  const [loading, setLoading] = useState(false);

  const [errorApi, setErrorApi] = useState();

  const [qualificationApi, setQualificationApi] = useState("Qualifiche");
  const [institutionApi, setInstitutionApi] = useState("Enti");
  const [qualificationSelect, setQualificationSelect] = useState({});
  const [institutionSelect, SetInstitutionSelect] = useState({});
  const [forgotError, setForgotError] = useState(false);

  const [saveLocalStorage, setSaveLocalStorage, removeLocal] =
    useLocalStorage("UserLoggedIn");

  const sendForm = (event) => {
    event.preventDefault();
    setValidation(true);

    const regexCF =
      /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/gm;
    const regexPW =
      /^(?=.*[A-Za-z])(?=.*[A-Z])(?=.*\d)(?=.*[-_.,:@$!%*#&])[-_A-Za-z\d.,:@$!%*#&]{8,}$/m;
    const regexEmail = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/;

    let passedValidation = true;

    if (!firstName) passedValidation = false;

    if (!lastName) passedValidation = false;

    if (!cf) passedValidation = false;
    else {
      if (!cf.match(regexCF)) {
        setCfValidation(false);
        passedValidation = false;
      } else setCfValidation(true);
    }
    // if (!institution) passedValidation = false;
    if (!street) {
      passedValidation = false;
    }
    if (!houseNumber) {
      passedValidation = false;
    }
    if (!city) {
      passedValidation = false;
    }
    if (!email) {
      passedValidation = false;
    } else {
      if (!email.match(regexEmail)) {
        setEmailValidation(false);
        passedValidation = false;
      } else {
        setEmailValidation(true);
      }
    }

    if (!cap) {
      passedValidation = false;
    }
    if (!phone) {
      passedValidation = false;
    }

    if (!password) {
      passedValidation = false;
    } else {
      setPasswordValidation(true);

      if (password !== confirmPassword) {
        passedValidation = false;
        setPasswordValidation(false);
      } else {
        if (!password.match(regexPW)) {
          passedValidation = false;
          setPasswordValidation(false);
        }
      }
    }

    if (!privacyOne) {
      passedValidation = false;
      setValidationPrivacy(false);
    }

    if (passedValidation) {
      var request = {
        nome: firstName,
        cognome: lastName,
        email: email,
        password: password,
        codiceFiscale: cf,
        isTesserato: true,
        telefono: phone,
        qualifica: null,
        via: street,
        nCivico: houseNumber,
        luogoResidenza: city,
        cap: cap,
        enteID: null,
      };

      Repo.postRegistration(JSON.stringify(request))
        .then((response) => {
          setLoading(true);
          if (response.status == 200) {
            setDisableButton(true);
            toggleModal(!isOpen);
            setLoading(false);
            removeLocal();
          }
        })
        .catch((error) => {
          setErrorApi(error.response.data.errors);
          toggleModalError(!isOpenError);
          setLoading(false);
        });
    } else {
      setDisableButton(false);
      setLoading(false);
    }
  };

  const loginForm = (event) => {
    event.preventDefault();

    const data = {
      userName: email,
      password: password,
    };

    setLoading(false);

    Repo.postLogin(JSON.stringify(data))
      .then((response) => {
        setSaveLocalStorage(response.data.data);
        setLoading(true);
        if (response.data.statusCode == 400) {
          setErrorLogin(response.data.userMessage);
          setLoading(false);
        } else {
          window.location.href = props.subfolder + "/";
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const changeInstitution = (event) => {
    setInstitution(event.value);
  };

  const changeQualification = (event) => {
    setQualification(event.value);
  };

  useEffect(() => {
    if (forgotError) {
      const timer = setTimeout(() => {
        setForgotError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [forgotError]);

  const postForgot = (event) => {
    if (email) {
      Repo.postForgotPassword(email)
        .then((response) => {
          if (response.data.statusCode == 200) {
            setLoading(true);
            toggleModalCredentials(false);
            toggleModalCheckEmail(true);
          } else {
            setLoading(false);
            toggleModalCredentials(false);
            setForgotError(true);
          }
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          toggleModalCredentials(false);
          setForgotError(true);
        });
    }
  };

  useEffect(() => {
    if (isOpenError) {
      const timer = setTimeout(() => toggleModalError(false), 5000);
      return () => clearTimeout(timer);
    }
  }, [isOpenError]);

  useEffect(() => {
    if (isOpen) {
      const timer = setTimeout(
        () => (window.location.href = props.subfolder + "/"),
        5000
      );
      return () => clearTimeout(timer);
    }
  }, [isOpen]);

  useEffect(() => {
    if (isOpenCheckEmail) {
      setLoading(false);
      const timer = setTimeout(
        () => (window.location.href = props.subfolder + "/"),
        5000
      );
      return () => clearTimeout(timer);
    }
  }, [isOpenCheckEmail]);

  return (
    <>
      {registrationPannel ? (
        <>
          <div className="col d-flex justify-content-center mt-5">
            <div className="card card-teaser " style={{ width: "50em" }}>
              <div className="card-body">
                <div className="needs-validation">
                  <h5 className="card-title">
                    <div className="row">
                      <div className="col-lg-8">
                        <h3>Registrati</h3>
                      </div>

                      <div className="col-lg-4 text-right">
                        <svg
                          className="icon icon-primary "
                          style={{ cursor: "pointer" }}
                          onClick={() => setRegistrationPannel(false)}
                        >
                          <use href={`${iconSprite}#it-close-big`} />
                        </svg>
                      </div>
                    </div>
                  </h5>
                  <br />

                  <div className="row">
                    <div className="col-12 mb-2">
                      <h6>Dati Anagrafici</h6>
                    </div>

                    <div className="col-lg-4">
                      <Input
                        onChange={(e) => setFirstName(e.target.value)}
                        id="firstName "
                        label="Nome*"
                        name="firstName"
                        {...(validation &&
                          !firstName && {
                            infoText: "Il campo non può essere vuoto!",
                          })}
                        {...(validation &&
                          !firstName && { className: "text-danger" })}
                      />
                    </div>

                    <div className="col-lg-4">
                      <Input
                        label="Cognome*"
                        id="lastName"
                        name="lastName"
                        {...(validation &&
                          !lastName && {
                            infoText: "Il campo non può essere vuoto!",
                          })}
                        {...(validation &&
                          !lastName && { className: "text-danger" })}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-4">
                      <Input
                        label="Codice Fiscale*"
                        id="cf"
                        name="cf"
                        {...(validation &&
                          !cf && {
                            infoText: "Il campo non può essere vuoto!",
                          })}
                        {...(validation && !cf && { className: "text-danger" })}
                        {...(validation &&
                          !cfValidation && { className: "text-danger" })}
                        {...(validation &&
                          !cfValidation && {
                            infoText: "Codice Fiscale non coretto",
                          })}
                        onChange={(e) => setCf(e.target.value)}
                      />
                    </div>
                  </div>
                  {/* <div className="row mt-2">
                    
                    <div className="col-lg-6 mt-2">
                      <div className="bootstrap-select-wrapper">
                        <label htmlFor="selectExampleDisabled">Ente</label>
                        <SelectInstitution
                          setValue={setInstitution}
                        ></SelectInstitution>

                        {validation && !institution ? (
                          <small
                            id="errorSelect"
                            className="form-text  text-danger"
                          >
                            Seleziona un campo
                          </small>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>

                    <div className="col-lg-6 mt-2">
                      <div className="bootstrap-select-wrapper">
                        <label htmlFor="selectExampleDisabled">Qualifica</label>
                        <SelectQualification
                          setValue={setQualification}
                        ></SelectQualification>

                        {validation && !qualification ? (
                          <small
                            id="errorSelect"
                            className="form-text  text-danger"
                          >
                            Seleziona un campo
                          </small>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div> */}
                  <div className="row mt-3">
                    <div className="col-12 mb-3">
                      <h6>Residenza</h6>
                    </div>
                    <div className="col-lg-3">
                      <Input
                        onChange={(e) => setStreet(e.target.value)}
                        label="Via*"
                        name="street"
                        id="street"
                        {...(validation &&
                          !street && {
                            infoText: "Il campo non può essere vuoto!",
                          })}
                        {...(validation &&
                          !street && { className: "text-danger" })}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Input
                        onChange={(e) => setHouseNumber(e.target.value)}
                        type="text"
                        label="Numero Civico*"
                        id="houseNumber"
                        name="houseNumber"
                        {...(validation &&
                          !houseNumber && {
                            infoText: "Il campo non può essere vuoto!",
                          })}
                        {...(validation &&
                          !houseNumber && { className: "text-danger" })}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Input
                        onChange={(e) => setCity(e.target.value)}
                        label="Città*"
                        name="city"
                        id="city"
                        {...(validation &&
                          !city && {
                            infoText: "Il campo non può essere vuoto!",
                          })}
                        {...(validation &&
                          !city && { className: "text-danger" })}
                      />
                    </div>

                    <div className="col-lg-3">
                      <Input
                        onChange={(e) => setCap(e.target.value)}
                        label="Cap*"
                        name="cap"
                        id="cap"
                        type="text"
                        {...(validation &&
                          !cap && {
                            infoText: "Il campo non può essere vuoto!",
                          })}
                        {...(validation &&
                          !cap && { className: "text-danger" })}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-12 mb-3">
                      <h6>Recapiti</h6>
                    </div>
                    <div className="col-lg-6">
                      <Input
                        onChange={(e) => setPhone(e.target.value)}
                        label="Telefono*"
                        name="phone"
                        id="phone"
                        {...(validation &&
                          !phone && {
                            infoText: "Il campo non può essere vuoto!",
                          })}
                        {...(validation &&
                          !phone && { className: "text-danger" })}
                      />
                    </div>

                    <div className="col-lg-6">
                      <Input
                        onChange={(e) => setEmail(e.target.value)}
                        label="Email*"
                        name="email"
                        id="email"
                        {...(validation &&
                          !email && {
                            infoText: "Il campo non può essere vuoto!",
                            className: "text-danger",
                          })}
                        {...(validation &&
                          !emailValidation && {
                            infoText: "Il formato non è corretto!",
                            className: "text-danger",
                          })}
                      />
                    </div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12 mb-3">
                      <h6>Credenziali</h6>
                    </div>

                    <div className="col-lg-4">
                      {passwordValidation}
                      <Input
                        onChange={(e) => setPassword(e.target.value)}
                        type="password"
                        name="password*"
                        id="password"
                        label="Password"
                        {...(validation &&
                          !passwordValidation && { className: "text-danger" })}
                        infoText="Inserisci almeno 8 caratteri e una lettera maiuscola ed un carattere speciale (ad eccezione del simbolo '?')"
                      />
                    </div>

                    <div className="col-lg-4">
                      <Input
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        type="password"
                        id="confirmPassword"
                        name="confirmPassword*"
                        label="Conferma Password"
                        {...(validation &&
                          confirmPassword !== password && {
                            className: "text-danger",
                          })}
                        {...(validation && confirmPassword !== password
                          ? { infoText: "La Password non corrisponde" }
                          : {
                              infoText:
                                "Ripeti la password inserita nel campo precedente",
                            })}
                      />
                    </div>

                    {/* <div className="col-lg-4">
                      <FormGroup check>
                        <Input
                          id="tesserato"
                          type="checkbox"
                          name="tesserato"
                          onChange={() => setTesserato(!tesserato)}
                        />
                        <Label for="tesserato" check>
                          <i>
                            Sei tesserato?
                            <a target="_blank" className="ml-2">
                              <b>Info</b>
                            </a>
                          </i>
                        </Label>
                      </FormGroup>
                    </div> */}
                  </div>

                  {validationPrivacy == false && (
                    <>
                      <div className="row mt-4">
                        <div className="col-12">
                          <span className="text text-danger">
                            <b>
                              Per completare la registrazione è necessario
                              accettare termini e condizioni.
                            </b>
                          </span>
                        </div>
                      </div>
                    </>
                  )}

                  <div className="row mt-4">
                    <div className="col-lg-10">
                      <FormGroup check>
                        <Input
                          id="disabled-checkbox1"
                          type="checkbox"
                          name="privacyOne"
                          onChange={() => setPrivacyOne(!privacyOne)}
                        />
                        <Label for="disabled-checkbox1" check>
                          <i>
                            accetto{" "}
                            <a target="_blank">
                              <b>i termini e le condizioni</b>
                            </a>{" "}
                            autorizzando il trattamento dei miei dati personali
                            ai sensi dell'
                            <a target="_blank">
                              <b>informativa</b>
                            </a>
                          </i>
                        </Label>
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          id="disabled-checkbox2"
                          type="checkbox"
                          name="privacyTwo"
                          onChange={() => setPrivacyTwo(!privacyTwo)}
                        />
                        <Label for="disabled-checkbox2" check>
                          <i>
                            acconsento al trattamento dei dati per l'invio di
                            comunicazioni commerciali di cui all'art. 1.B dell'
                            <a target="_blank">
                              <b>informativa</b>
                            </a>
                          </i>
                        </Label>
                      </FormGroup>
                    </div>

                    <div className="col-lg-2 text-right ">
                      <button
                        disabled={disableButton}
                        className="btn btn-primary mt-4"
                        onClick={(e) => {
                          setLoading(true);
                          sendForm(e);
                        }}
                      >
                        Registrati
                      </button>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col-12">
                      <i>
                        Altrimenti -
                        <Link
                          to="#"
                          onClick={() => setRegistrationPannel(false)}
                        >
                          {" "}
                          Accedi al servizio
                        </Link>
                      </i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="row">
          <div className="col-lg-7 mt-4">
            <h3>Accedi con le tue credenziali</h3>

            <br />

            <h5>
              Accedi all'area riservata inserendo l'indirizzo e-mail e la
              password associati al tuo account.
            </h5>
          </div>
          <div className="col-lg-5">
            <div className="col d-flex justify-content-center mt-4">
              <div
                className="card card-teaser roundedCustom shadow"
                style={{ width: "25em" }}
              >
                <div className="card-body">
                  <h5 className="card-title">
                    <div className="row">
                      <div className="col-6">
                        <h5 className="card-title">Accedi al tuo account</h5>
                      </div>

                      <div className="col-6 text-right">
                        <button
                          type="button"
                          className="btn btn-outline-primary btn-xs"
                          onClick={() => setRegistrationPannel(true)}
                        >
                          Registrati
                        </button>
                      </div>
                    </div>
                  </h5>
                  <br />
                  <form onSubmit={loginForm} autoComplete="on">
                    <Input
                      label="Email"
                      type="email"
                      name="email"
                      placeholder="Email"
                      required
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <br />
                    <Input
                      type="password"
                      onChange={(e) => setPassword(e.target.value)}
                      id="exampleInputPassword"
                      label="Password"
                      name="password"
                      placeholder="Password"
                      infoText="Inserisci almeno 8 caratteri e una lettera maiuscola"
                      required
                    />

                    <div className="col-lg-12 text-danger">{errorLogin}</div>

                    <div className="row mt-3">
                      <div className="col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block rounded"
                        >
                          Accedi
                        </button>
                      </div>
                      <div className="col-lg-12 text-center mt-3">
                        <Link
                          to="#"
                          onClick={() => toggleModalCredentials(true)}
                        >
                          {" "}
                          Recupera le tue credenziali
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div>
        <Modal
          isOpen={isOpenCredentials}
          toggle={() => toggleModalCredentials(!isOpenCredentials)}
          labelledBy="esempio1"
        >
          <ModalHeader id="esempio1">Recupera le tue credenziali</ModalHeader>
          <ModalBody>
            <div className="col-lg-12 mt-3">
              <Input
                label="Email"
                type="email"
                name="email"
                placeholder="Email"
                required
                defaultValue={email ? email : ""}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={() => postForgot()}>
              Conferma
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={isOpenCheckEmail}
          toggle={() => toggleModalCheckEmail(!isOpenCheckEmail)}
          labelledBy="esempio1"
        >
          <ModalHeader id="esempio1">Recupera le tue credenziali</ModalHeader>
          <ModalBody>
            Ti abbiamo inviato un messaggio nella casella di posta elettronica
            da te indicata!
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>

        {/* SCOMMENTARE PER EMAIL */}
        {/* <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          labelledBy="esempio1"
        >
          <ModalHeader id="esempio1">
            La tua registrazione è andata a buon fine.
          </ModalHeader>
          <ModalBody>
            Sarai reindirizzato alla pagina di Login. Riceverai una mail con un
            link per poter confermare le credenziali. Nel caso di mancata
            ricezione della mail verifica la cartella spam.
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => (window.location.href = props.subfolder + "/")}
            >
              Avanti
            </Button>
          </ModalFooter>
        </Modal> */}

        {/* COMMENTARE PER EMAIL */}
        <Modal
          isOpen={isOpen}
          toggle={() => toggleModal(!isOpen)}
          labelledBy="esempio1"
        >
          <ModalHeader id="esempio1">
            La tua registrazione è andata a buon fine.
          </ModalHeader>
          <ModalBody>
            Sarai reindirizzato alla pagina di Login per effettuare l'accesso a
            SemplicitaLearning.
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => (window.location.href = props.subfolder + "/")}
            >
              Avanti
            </Button>
          </ModalFooter>
        </Modal>

        <Modal
          isOpen={isOpenError}
          toggle={() => toggleModalError(!isOpenError)}
          labelledBy="esempio1"
        >
          <ModalHeader id="esempio1">ERRORE</ModalHeader>
          <ModalBody>{errorApi}</ModalBody>
          <ModalFooter>
            <Button
              onClick={() => toggleModalError(!isOpenError)}
              color="primary"
            >
              Chiudi
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      {forgotError && (
        <>
          <div
            className="alert alert-danger"
            role="alert"
            style={{
              position: "fixed",
              backgroundColor: "white",
              bottom: "0px",
              right: "5px",
            }}
          >
            Non è possibile recuperare le credenziali! Email non esistente!
          </div>
        </>
      )}
      {loading && (
        <div className="col-12">
          <div className="trasparente">
            <div className="progress-spinner progress-spinner-active spinner"></div>
            <span
              className="customTextSpinnerReg"
              style={{
                top: "45%",
                position: "fixed",
                left: "46.5%",
                zIndex: "401",
              }}
            >
              Caricamento...
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default SignedUp;
