import { useEffect, useState } from "react";
import Repo from "../Utility/Repo";
import { Input, FormGroup, Label } from "design-react-kit";
import useLocalStorage from "../Utility/useLocalStorage";
import ViewUserProfile from "../Objects/ViewUserProfile";

function PageUserProfile(props) {
  const [qualifiche, setQualifiche] = useState("Qualifiche");
  const [institution, setInstitution] = useState("Enti");
  const [qualifySelect, setQualifySelect] = useState({});
  const [institutionSelect, SetInstitutionSelect] = useState({});
  const [apiToken, setApiToken] = useState(props.apiToken);
  const [params, setParams] = useState(props.children.email);
  const [sendEnte, setSendEnte] = useState(true);
  const [loginLocalStorage, setLoginLocalStorage] =
    useLocalStorage("UserLoggedIn");

  useEffect(() => {
    if (apiToken) {
      Repo.getSelect(qualifiche, apiToken)
        .then((response) => {
          if (response.status == 200) {
            setQualifySelect(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });

      Repo.getSelect(institution, apiToken)
        .then((response) => {
          if (response.status == 200) {
            SetInstitutionSelect(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, []);

  const editSend = (request) => {
    if (apiToken && params) {
      Repo.putUser(apiToken, JSON.stringify(request))
        .then((response) => {
          if (response.status == 200) {
            var actualLocalStorage = loginLocalStorage;
            actualLocalStorage.utente = response.data.data;
            setLoginLocalStorage(actualLocalStorage);
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  return (
    <>
      <ViewUserProfile
        user={!props.operator}
        displayMode={true}
        editMethod={editSend}
        profile={props.children}
        qualifySelect={qualifySelect}
        institutionSelect={institutionSelect}
        sendMail={false}
      ></ViewUserProfile>
    </>
  );
}

export default PageUserProfile;
