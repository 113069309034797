import { useEffect, useState } from "react";
import Repo from "../Utility/Repo";
import ViewSubject from "../Objects/ViewSubject";
import { useLocation } from "react-router";

function PageSubjectDetail(props) {
  const [isOperator, setIsOperator] = useState(props.operator);
  const [subject, setSubject] = useState();
  const [apiToken, setApiToken] = useState(props.apiToken);
  const [sendEnte, setSendEnte] = useState(true);
  const [isOpen, toggleModal] = useState(false);
  const [subjectError, setSubjectError] = useState(false);
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const paramsId = query.get("id");

  useEffect(() => {
    setIsOperator(props.operator);

    if (apiToken && paramsId) {
      Repo.getOneSubject(apiToken, paramsId)
        .then((response) => {
          if (response.status == 200) {
            setSubject(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, [props.operator]);

  const editSend = (request) => {
    if (apiToken && paramsId) {
      Repo.putSubject(apiToken, JSON.stringify(request), paramsId)

        .then((response) => {
          window.location.href =
            props.subfolder + "/subject_detail?id=" + paramsId;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  useEffect(() => {
    if (subjectError) {
      const timer = setTimeout(() => {
        setSubjectError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [subjectError]);

  const deleteSub = () => {
    if (apiToken && paramsId) {
      Repo.deleteSubject(apiToken, paramsId)
        .then((response) => {
          window.location.href = props.subfolder + "/subjects_management";
        })
        .catch((error) => {
          toggleModal(false);
          setSubjectError(true);
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  return (
    <>
      {/* Filtri */}

      {/* Ente */}

      <ViewSubject
        editMethod={editSend}
        displayMode={true}
        sendEnte={sendEnte}
        delete={deleteSub}
        isOpen={isOpen}
        toggleModal={toggleModal}
      >
        {subject}
      </ViewSubject>

      {subjectError && (
        <>
          <div
            class="alert alert-danger"
            role="alert"
            style={{
              position: "fixed",
              backgroundColor: "white",
              bottom: "0px",
              right: "5px",
              zIndex: "100",
            }}
          >
            <b>Non è possibile eliminare la materia selezionata.</b>
          </div>
        </>
      )}
    </>
  );
}

export default PageSubjectDetail;
