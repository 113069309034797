import { useEffect, useState, useRef } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Repo from "../Utility/Repo";
import RichTextEditor from "../Objects/RichTextEditor";
import SelectSubjects from "../Objects/SelectSubjects";
import { useLocation } from "react-router";
import SelectPayments from "../Objects/SelectPayments";
import moment from "moment";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import SelectCourse from "../Objects/SelectCourse";
import SelectTemplate from "../Objects/SelectTemplate";
import ModalDeleteSession from "../Objects/ModalDeleteSession";
import { Link } from "react-router-dom";
import UploadFile from "../Objects/UploadFile";

function PageEditSession(props) {
  const [subject, setSubject] = useState();
  const [name, setName] = useState();
  const [description, setDescription] = useState();
  const [loading, setLoading] = useState(false);
  let { search } = useLocation();
  const query = new URLSearchParams(search);

  const [isOpenConfirmation, toggleModalConfirmation] = useState(false);
  const [sendResult, setSendResult] = useState(false);
  const [startValidate, setStartValidate] = useState(false);
  const [idSessioResult, setIdSessioResult] = useState();

  const [startDate, setStartDate] = useState("");
  const [dataEnd, setDataEnd] = useState("");
  const [locality, setLocality] = useState("");
  const [edition, setEdition] = useState("");
  const paramsId = query.get("id");
  const [relation, setRelation] = useState("");
  const [presence, setpresence] = useState(false);
  const [sessionID, setSessionID] = useState(query.get("id"));
  const [subscription, setSubscription] = useState();
  const [uploadImage, setUploadImage] = useState();
  const [uploadImageBase64, setUploadImageBase64] = useState();
  const [uploadImageTemp, setUploadImageTemp] = useState();
  const [uploadTypeImage, setUploadTypeImage] = useState();
  const [registrationClosingDate, setRegistrationClosingDate] = useState();
  const [isPagamento, setIsPagamento] = useState(false);
  const [importPrice, setImportPrice] = useState("0");
  const regexImport = new RegExp("^[+]?([0-9]{0,})*[,]?([0-9]{0,2})?$", "g");

  const [session, setSession] = useState({});

  const [errorDate, setErrorDate] = useState(false);

  const [paymentsSelect, setPaymentsSelect] = useState([]);
  const [templateID, setTemplateID] = useState();

  const [modalDeleteSession, setModalDeleteSession] = useState(false);

  const reloadPage = () => {
    setLoading(true);
    Repo.getOneSession(props.apiToken, sessionID)
      .then((response) => {
        if (response.status == 200) {
          setSession(response.data);
          setEdition(response.data.edizione);
          setLocality(response.data.localita);
          setRelation(response.data.relatori);
          setpresence(response.data.isPresenza);
          setTemplateID(response.data.templateID);
          setUploadImageTemp(response.data.copertina);
          setUploadTypeImage(response.data.tipoCopertina);
          setSubscription(response.data.maxUtenti);

          if (response.data.prezzo != 0) {
            setIsPagamento(true);
            setImportPrice(response.data.prezzo.toFixed(2).replace(".", ","));
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  const deleteSession = () => {
    if (props.apiToken) {
      Repo.deleteSession(props.apiToken, paramsId)
        .then((response) => {
          window.location.href = props.subfolder + "/session_courses";
        })
        .catch((error) => {
          console.log("error: ", error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  useEffect(() => {
    if (props.apiToken && sessionID) {
      reloadPage();
    }
  }, [sessionID]);

  function getBase64(file) {
    return new Promise(function (resolve) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  }

  const sendSession = async (e) => {
    e.preventDefault();
    setStartValidate(true);

    var validatePassed = true;

    if (uploadImage) {
      var base64File = await getBase64(uploadImage);
      var splitBase64 = base64File.split(",");
    }

    if (!locality) validatePassed = false;
    if (!edition) validatePassed = false;
    if (!relation) validatePassed = false;
    if (!sessionID) validatePassed = false;
    if (!templateID) validatePassed = false;

    if (isPagamento == true && importPrice == 0) validatePassed = false;

    if (validatePassed) {
      var temp = {
        dateInizio: startDate ? startDate : formattedDate(session.dataInizio),
        dataFine: dataEnd ? dataEnd : formattedDate(session.dataFine),
        localita: locality,
        edizione: edition,
        relatori: relation,
        isPresenza: presence,
        templateID: templateID,
        // copertina: splitBase64 && splitBase64[1],
        // tipoCopertina: uploadImage && uploadImage.type,
        maxUtenti: subscription,
        chiusuraPrenotazione: registrationClosingDate
          ? registrationClosingDate
          : formattedDate(session.chiusuraPrenotazione),
        prezzo: importPrice ? parseFloat(importPrice.replace(/,/g, ".")) : 0,
      };

      if (uploadImage) {
        temp["copertina"] = splitBase64 && splitBase64[1];
        temp["tipoCopertina"] = uploadImage && uploadImage.type;
      } else if (uploadImageTemp) {
        temp["copertina"] = uploadImageTemp;
        temp["tipoCopertina"] = uploadTypeImage;
      } else {
        temp["copertina"] = "";
        temp["tipoCopertina"] = "";
      }
      var idSessioneTemp = "";
      if (props.apiToken) {
        Repo.editSessionCourse(props.apiToken, JSON.stringify(temp), sessionID)
          .then((response) => {
            window.location.href =
              props.subfolder + "/session_course_detail?id=" + sessionID;
          })
          .catch((error) => {
            setSendResult(false);
            setErrorDate(true);
            if (error.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
      }
    }
  };

  function formattedDate(d) {
    if (d) {
      var dateMomentObject = moment(d, "DD/MM/YYYY");
      var dateObject = dateMomentObject.add(1, "days").toDate();
      return dateObject.toISOString().slice(0, 10);
    }
  }

  const reverseDate = (date) => {
    if (date) {
      var dateString = date; // Oct 23
      var dateMomentObject = moment(dateString, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object

      dateObject.setDate(dateObject.getDate() + 1);
      return dateObject.toISOString().slice(0, 10);
    }

    return "";
  };

  const subscriptionCheck = (e) => {
    if (e !== undefined) {
      if (presence) {
        setSubscription(e);
      } else if (!presence && e > 100) {
        setSubscription(100);
      } else {
        setSubscription(e);
      }
    } else {
      if (subscription > 100) {
        setSubscription(100);
      }
    }
  };

  return (
    <>
      <div className="row">
        <div className=" col-12 ">
          <div className="col-4">
            <h4> Modifica Edizione </h4>
          </div>
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-6">
          {!startDate ? (
            <>
              {" "}
              <Input
                label="Data Inizio"
                id="dateStart"
                name="startDate"
                type="date"
                value={reverseDate(session.dataInizio)}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </>
          ) : (
            <>
              {" "}
              <Input
                label="Data Inizio"
                id="dateStart"
                name="startDate"
                type="date"
                placeholder="dd "
                defaultValue={startDate}
                value={startDate}
                {...(startValidate &&
                  !startDate && {
                    infoText: "Il campo non può essere vuoto!",
                  })}
                {...(startDate && !startDate && { className: "text-danger" })}
                onChange={(e) => setStartDate(e.target.value)}
              />
            </>
          )}
        </div>

        {/* moment(session.dataInizio).format("DD-MM-YYYY") */}
        <div className="col-lg-6">
          {!dataEnd ? (
            <>
              {" "}
              <Input
                label="Data Fine"
                id="datefine"
                name="dataEnd"
                type="date"
                value={reverseDate(session.dataFine)}
                onChange={(e) => setDataEnd(e.target.value)}
              />
            </>
          ) : (
            <>
              {" "}
              <Input
                label="Data Fine"
                id="dataEnd"
                name="dataEnd"
                type="date"
                placeholder="dd "
                defaultValue={dataEnd}
                value={dataEnd}
                {...(startValidate &&
                  !dataEnd && {
                    infoText: "Il campo non può essere vuoto!",
                  })}
                {...(startValidate && !dataEnd && { className: "text-danger" })}
                onChange={(e) => setDataEnd(e.target.value)}
              />
            </>
          )}
        </div>
      </div>

      <div className="row mt-4">
        <div className="col-lg-6">
          <Input
            label="Edizione"
            id="Edizione"
            name="edition"
            onChange={(e) => setEdition(e.target.value)}
            required
            type="text"
            defaultValue={session.edizione}
            value={edition}
            {...(startValidate &&
              !edition && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate && !edition && { className: "text-danger" })}
          />
        </div>

        <div className="col-lg-6">
          <Input
            label="Località"
            id="locality"
            name="locality"
            placeholder="Inserisci la località"
            defaultValue={session.localita}
            value={locality}
            {...(startValidate &&
              !locality && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate && !locality && { className: "text-danger" })}
            onChange={(e) => setLocality(e.target.value)}
          />
        </div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-6">
          <Input
            label="Relatori"
            id="relation"
            name="relation"
            placeholder="Inserisci i relatori separati da una virgola"
            defaultValue={session.relatori}
            value={relation}
            {...(startValidate &&
              !relation && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate && !relation && { className: "text-danger" })}
            onChange={(e) => setRelation(e.target.value)}
          />
        </div>
        <div className="col-lg-6">
          <SelectTemplate
            apiToken={props.apiToken}
            setValue={setTemplateID}
            defaultValue={session.nomeTemplate}
          ></SelectTemplate>

          {startValidate && !templateID ? (
            <small id="errorSelect" className="form-text  text-danger">
              Inserisci una descrizione per il corso
            </small>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="row mt-4">
        <div className="col-lg-6">
          {!registrationClosingDate ? (
            <>
              {" "}
              <Input
                label="Data Chiusura iscrizione"
                id="dateStart"
                name="startDate"
                type="date"
                value={reverseDate(session.chiusuraPrenotazione)}
                onChange={(e) => setRegistrationClosingDate(e.target.value)}
              />
            </>
          ) : (
            <>
              {" "}
              <Input
                label="Data Chiusura iscrizione"
                id="dateStart"
                name="startDate"
                type="date"
                placeholder="dd "
                defaultValue={registrationClosingDate}
                value={registrationClosingDate}
                onChange={(e) => setRegistrationClosingDate(e.target.value)}
              />
            </>
          )}

          {errorDate && (
            <small id="errorSelect" className="form-text  text-danger">
              Inserisci una data inferiore alla data di inizio
            </small>
          )}
        </div>
        <div className="col-lg-3">
          <Input
            label="Numero massimo iscritti"
            id="subscription"
            name="subscription"
            type="number"
            placeholder="Numero massimo iscritti"
            min="0"
            defaultValue={subscription}
            value={subscription && subscription}
            infoText={
              "Nel caso in cui il corso non sia in presenza il numero massimo di iscrizioni è 100"
            }
            {...(presence ? {} : { max: "100" })}
            {...(startValidate &&
              !subscription && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate &&
              !subscription && { className: "text-danger" })}
            onChange={(e) => subscriptionCheck(e.target.value)}
          />
        </div>
        <div className="col-lg-3">
          <FormGroup check>
            <>
              <Input
                id="presence"
                type="checkbox"
                name="privacyTwo"
                checked={presence}
                onChange={() => {
                  setpresence(!presence);
                  subscriptionCheck();
                }}
              />
            </>
            <Label for="presence" check>
              <i>In presenza?</i>
            </Label>
          </FormGroup>
        </div>
      </div>

      {/* <div className="row">
        <div className="col-lg-3">
          <FormGroup check>
            <Input
              id="checkFree"
              type="checkbox"
              name="checkFree"
              checked={isPagamento}
              onChange={() => {
                setIsPagamento(!isPagamento);
                setImportPrice(0);
              }}
            />
            <Label for="checkFree" check>
              <i>A Pagamento</i>
            </Label>
          </FormGroup>
        </div>
        <div className="col-lg-3">
          <Input
            label="Prezzo"
            name="prezzo"
            type="text"
            value={importPrice}
            disabled={!isPagamento}
            onChange={(e) =>
              setImportPrice(
                e.target.value.match(regexImport) ? e.target.value : importPrice
              )
            }
          />
        </div>
      </div> */}

      {importPrice != 0 && (
        <>
          <div className="row">
            <div className="col-lg-3">
              <Input
                label="Prezzo"
                name="prezzo"
                type="text"
                value={importPrice}
                disabled={true}
              />
            </div>
          </div>
        </>
      )}

      <div
        className="p-2"
        style={{
          border: "solid 1px blue",
          borderRadius: "15px 15px 15px 15px",
        }}
      >
        <div className="col-lg-12 text-center">
          <h6>Carica Copertina Edizione</h6>
          {uploadImageTemp ? (
            <figure className="img-wrapper">
              <img
                src={"data:" + uploadTypeImage + ";base64," + uploadImageTemp}
                width="310"
                height="190"
              ></img>
            </figure>
          ) : uploadImageBase64 ? (
            <figure className="img-wrapper">
              <img
                src={!uploadImageTemp && uploadImageBase64}
                width="310"
                height="190"
              ></img>
            </figure>
          ) : (
            <>
              <figure className="img-wrapper">
                <img src="https://semplicitalearning.it/img/corso.png"></img>
              </figure>
              <h6>
                In caso di mancato caricamento della copertina verrà
                visualizzata questa immagine
              </h6>
            </>
          )}
        </div>

        {uploadImageTemp ? (
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4 ">
              <div className="card card-tease roundedCustom shadow mt-2 p-3 no-after">
                <div className="row">
                  <div className="col-12 text-right">
                    <svg
                      className="icon icon-danger "
                      onClick={() => {
                        setUploadImage(), setUploadImageTemp();
                      }}
                    >
                      <use xlinkHref={`${iconSprite}#it-close-circle`}></use>
                    </svg>
                  </div>
                  <div className="col-12">
                    <div
                      className="card-body p-3"
                      style={{ width: "fit-content" }}
                    >
                      <h5 className="card-title">
                        <a>Immagine copertina attuale</a>
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        ) : (
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4 text-center">
              <UploadFile
                handleFile={setUploadImage}
                getBase64={true}
                file64={setUploadImageBase64}
                testoInterno={"Carica immagine"}
              ></UploadFile>
            </div>
            <div className="col-lg-4"></div>
          </div>
        )}
      </div>

      {/* <div className="row mt-4"> 
        <div className="col-lg-12 ">
          <div id="collapseDiv1" className="collapse-div" role="tablist">
            <div className="collapse-header" id="heading1">
              <button
                data-toggle="collapse"
                data-target="#collapse1"
                aria-expanded="true"
                aria-controls="collapse1"
              >
                <b>Aggiungi Pagamenti</b>
              </button>
            </div>
            <div
              id="collapse1"
              className="collapse "
              role="tabpanel"
              aria-labelledby="heading1"
            >
              <div className="collapse-body">
                <SelectPayments
                  apiToken={props.apiToken}
                  setValue={setPaymentsSelect}
                ></SelectPayments>
              </div>
            </div>
            
          </div>
        </div>
      </div> */}

      <div className="row mt-4">
        <div className="col-lg-12 text-right">
          <button className="btn btn-primary" onClick={sendSession}>
            Salva corso
          </button>
          <button
            type="button"
            className="btn btn-danger ml-4"
            onClick={() => setModalDeleteSession(true)}
          >
            Elimina
          </button>
        </div>
      </div>

      {isOpenConfirmation && (
        <div>
          <Modal
            isOpen={isOpenConfirmation}
            toggle={() => toggleModalConfirmation(!isOpenConfirmation)}
            centered
            size={"lg"}
          >
            <ModalHeader
              toggle={() => toggleModalConfirmation(!isOpenConfirmation)}
              id="esempio2"
            >
              {sendResult
                ? "L'edizione è stata creata"
                : "Qualcosa è andato storto!"}
            </ModalHeader>
            <ModalBody>
              {sendResult ? (
                <>
                  <h5>Edizione Creata con successo</h5>
                  <div className="row">
                    <div
                      className="col-12"
                      style={{
                        position: "absolute",
                        right: "1%",
                        bottom: "5%",
                      }}
                    >
                      <Link
                        to={"/session_course_detail?id=" + idSessioResult}
                        className="btn btn-primary btn-sm float-right"
                      >
                        Vai alla Sessione
                      </Link>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <h4>Qualcosa è andato storto!</h4>
                  <div className="row">
                    {" "}
                    <div className="col-12 text-right">
                      <button
                        type="submit"
                        className="btn btn-primary"
                        onClick={() =>
                          toggleModalConfirmation(!isOpenConfirmation)
                        }
                      >
                        Indietro
                      </button>
                    </div>{" "}
                  </div>
                </>
              )}
            </ModalBody>
            <ModalFooter></ModalFooter>
          </Modal>
        </div>
      )}

      <ModalDeleteSession
        session={session ? session : {}}
        deleteMethod={() => deleteSession()}
        open={modalDeleteSession}
        setOpen={() => setModalDeleteSession()}
      ></ModalDeleteSession>

      {loading && (
        <div className="col-12">
          <div className="trasparente">
            <div className="progress-spinner progress-spinner-active spinner"></div>
            <span
              className="customTextSpinnerReg"
              style={{
                top: "45%",
                position: "fixed",
                left: "46.5%",
                zIndex: "401",
              }}
            >
              Caricamento...
            </span>
          </div>
        </div>
      )}
    </>
  );
}

export default PageEditSession;
