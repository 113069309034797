import React, { useState } from "react";
import styled from "styled-components";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

const Button = styled.button`
  /* Insert your favorite CSS code to style a button */
`;
const UploadFile = (props) => {
  const hiddenFileInput = React.useRef(null);

  const handleClick = (event) => {
    hiddenFileInput.current.click();
  };

  const handleChange = async (event) => {
    const fileUploaded = event.target.files[0];
    setFile(fileUploaded);
    props.handleFile(fileUploaded);
    if (props.getBase64 == true) {
      var base64File = await getBase64(fileUploaded);
      props.file64(base64File);
    }
  };

  function getBase64(file) {
    return new Promise(function (resolve) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  }

  const deleteFile = () => {
    setFile();
    props.handleFile();
    if (props.getBase64 == true) {
      props.file64();
    }
  };

  const [file, setFile] = useState();

  const [position] = useState(props.position ? props.position : "text-center");
  return (
    <div className="row mt-2">
      {!file && (
        <>
          <div className={"col-12 mt-2 " + position}>
            {" "}
            <Button onClick={handleClick} className="btn btn-outline-primary">
              {props.testoInterno ? props.testoInterno : "Carica"}
            </Button>
            <input
              type="file"
              ref={hiddenFileInput}
              onChange={handleChange}
              style={{ display: "none" }}
            />
          </div>
        </>
      )}

      {file && (
        <div className="card card-tease roundedCustom shadow mt-2 p-2 no-after">
          <div className="row">
            <div className="col-12 text-right">
              <svg className="icon icon-danger " onClick={() => deleteFile()}>
                <use xlinkHref={`${iconSprite}#it-close-circle`}></use>
              </svg>
            </div>
            <div className="col-12">
              <div className="card-body px-3" style={{ width: "fit-content" }}>
                <h5 className="card-title">
                  <a>{file.name.replace("_", " ").substring(0, 50)}</a>
                </h5>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default UploadFile;
