import React, { useEffect, useState } from "react";
import {
  Input,
  Fade,
  Dimmer,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Repo from "../Utility/Repo";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import iconCheckOk from "bootstrap-italia/dist/svg/sprite.svg";
import MaterialList from "../Objects/MaterialList";
import UsersListSession from "../Objects/UsersListSession";
import { useLocation } from "react-router";
import Notification from "../Objects/Notification";
import SearchUser from "../Objects/SearchUser";
import ModalCloseSession from "../Objects/ModalCloseSession";
import moment from "moment";
import { Link } from "react-router-dom";
import ZoomWrapper from "./ZoomWrapper";
import useLocalStorage from "../Utility/useLocalStorage";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function SessionCourseDetail(props) {
  const [pageCount, setPageCount] = useState(0);
  const [loginLocalStorage, setLoginLocalStorage, deleteLoginLocalStorage] =
    useLocalStorage("UserLoggedIn");
  const [isOperator, setIsOperator] = useState(props.operator);
  const [session, setSession] = useState();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramsId = query.get("id");
  const [isOpenConfirmation, toggleModalConfirmation] = useState(false);
  //const [corelatedCourses, setCorelatedCourses] = useState();
  //const [request, setRequest] = useState({});
  const [validateStart, setValidateStart] = useState(false);
  const [uploadReceipt, setUploadReceipt] = useState();
  const [isMyCourse, setIsMyCourse] = useState(false);
  const [material, setMaterial] = useState([]);
  const [users, setUsers] = useState([]);
  const [closingResult, setClosingResult] = useState(true);
  const [isCloseOpenConfirmation, toggleCloseModalConfirmation] =
    useState(false);
  const [zoomUrl, setZoomUrl] = useState(process.env.REACT_APP_ZOOM_URL);

  const [passed, setOpenModalePassed] = useState(false);
  const [selectUserPassed, setSelectUserPassed] = useState();

  const [modalCloseSession, setModalCloseSession] = useState(false);

  const [meetingData, setMeetingData] = useState();
  const [signature, setSignature] = useState();
  const [hostEmail, setHostEmail] = useState("");
  // const [join, setJoin] = useState(false);
  const [user, setUser] = useState("");
  const [alertMessage, setAlertMessage] = useState("");
  const [meetingError, setMeetingError] = useState(false);
  const [currentDate, setCurrentDate] = useState(moment().format("DD/MM/YYYY"));
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [recordingsList, setRecordingsList] = useState([]);
  const [currentRec, setCurrentRec] = useState({});
  const [newYTLink, setNewYTLink] = useState("");
  const [validationURL, setValidationURL] = useState(true);

  //PAGAMENTO STRIPE
  const [isPayed, setIsPayed] = useState();
  const [isVerified, setIsVerified] = useState();

  const [isOpenYoutube, setModalYoutube] = useState(false);
  const regexURL =
    /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/gi;

  let queryP = useQuery();

  // useEffect(() => {
  //   const zoomView = document.getElementById("zmmtg-root");
  //   zoomView.setAttribute("style", "display: None");
  // }, []);

  useEffect(() => {
    if (meetingError) {
      const timer = setTimeout(() => {
        setMeetingError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [meetingError]);

  useEffect(() => {
    if (isOperator === true) {
      Repo.getMeetingBySessione(props.apiToken, paramsId, 1)
        .then((response) => {
          if (response.data.statusCode == 200) {
            setMeetingData(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    } else {
      setUser(loginLocalStorage.utente);
      Repo.getMeetingBySessione(props.apiToken, paramsId, 0)
        .then((response) => {
          if (response.data.statusCode == 200) {
            setMeetingData(response.data);
          }
        })
        .catch((error) => {
          console.log("error");
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }

    Repo.getRecordingsBySessione(props.apiToken, paramsId)
      .then((response) => {
        if (response.data.statusCode == 200) {
          setRecordingsList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });

    if (queryP.get("success")) {
      setIsPayed(true);
      if (queryP.get("success") == "False") {
        setIsVerified(false);
      }
      if (queryP.get("success") == "True") {
        setIsVerified(true);
      }
    }
  }, []);

  function createMeeting() {
    setLoading(true);
    Repo.createMeeting()
      .then(function (response) {
        setMeetingData(response.data);
        let newMeeting = {
          meetingNumber: response.data.meetingNumber,
          joinUrl: response.data.joinUrl,
          startUrl: response.data.startUrl,
          pwd: response.data.pwd,
          hostEmail: null,
          sessioneCorsoID: paramsId,
          apiKey: response.data.apiKey,
          isClosed: null,
        };

        Repo.postNewMeeting(props.apiToken, JSON.stringify(newMeeting))
          .then((response) => {
            setLoading(false);
          })
          .catch((error) => {
            console.log("error");
            setMeetingData(null);
            setMeetingError(true);
            setLoading(false);
            setAlertMessage("Impossibile Creare il Meeting!");
          });
      })
      .catch(function (error) {
        console.log(error);
        setMeetingError(true);
        setLoading(false);
        setAlertMessage("Impossibile Creare il Meeting!");
      });
  }

  const joinMeeting = (operator) => {
    if (meetingData && meetingData.meetingNumber) {
      Repo.getSignature(meetingData.meetingNumber, operator)
        .then(function (response) {
          setSignature(response.data.token);
          if (response.data.token && operator == 1) {
            Repo.setMeetingStatus(
              props.apiToken,
              meetingData.meetingNumber,
              false
            )
              .then((response) => {})
              .catch((error) => {
                setMeetingError(true);
                setAlertMessage("Impossibile partecipare al Meeting!");
              });
          }
          setHostEmail(response.data.hostEmail);
          let params = {
            meetingData: JSON.stringify(meetingData),
            signature: response.data.token,
            hostEmail: response.data.hostEmail,
            clientName: process.env.REACT_APP_CLIENT_NAME,
            isOperator: isOperator,
            user: !isOperator ? JSON.stringify(user) : null,
          };

          const queryString = new URLSearchParams(params).toString();
          window.location.href = zoomUrl + "?" + queryString;
        })
        .catch(function (error) {
          console.log(error);
          setMeetingError(true);
          setAlertMessage("Impossibile partecipare al Meeting!");
        });
    }
  };

  const [obbCosti, setObbCosti] = useState([]);
  const reloadPage = () => {
    setLoading(true);
    Repo.getOneSession(props.apiToken, paramsId)
      .then((response) => {
        if (response.status == 200) {
          setIsMyCourse(response.data.isIscritto);
          setSession(response.data);
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };
  useEffect(() => {
    window.scroll(0, 0);

    setIsOperator(props.operator);
    if (props.apiToken && paramsId) {
      reloadPage();
    }
  }, [props.operator, paramsId]);

  useEffect(() => {
    setIsOperator(props.operator);

    // if (session && session.nomiMaterie.length > 0) {
    //   setLoading(true);
    //   var requestTemp = request;
    //   request.materia = session.nomiMaterie[0];

    //   Repo.postAllSession(props.apiToken, JSON.stringify(requestTemp))
    //     .then((response) => {
    //       if (response.status == 200) {
    //         setCorelatedCourses(response.data.slice(0, 3));
    //         setLoading(false);
    //       }
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       setLoading(false);
    //       if (error.response.status == 401) {
    //         props.deleteCoockieExpired();
    //       }
    //     });
    // }

    if (isOperator && session) {
      var tempRequest = {
        sessioneCorsoID: session && session.sessioneCorsoID,
      };
      setLoading(true);
      Repo.postUserFromCourse(props.apiToken, JSON.stringify(tempRequest))
        .then((response) => {
          if (response.status == 200) {
            setUsers(response.data.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }

    if (session) {
      setLoading(true);
      Repo.getMaterial(props.apiToken, paramsId)
        .then((response) => {
          if (response.status == 200) {
            setMaterial(response.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, [session]);

  // const checkPayments = (check) => {
  //   var paymentsTemp = payments;
  //   if (payments.includes(check.pagamentoSessioneID)) {
  //     var paymentsTemp = paymentsTemp.filter(function (e) {
  //       return e !== check.pagamentoSessioneID;
  //     });
  //   } else {
  //     paymentsTemp.push(check.pagamentoSessioneID);
  //   }
  //   setPayments(paymentsTemp);
  // };

  const [registrationResult, setRegistrationResult] = useState(false);

  const deleteIscrizione = () => {
    if (props.user) {
      var idUser = "";
      if (!isOperator) {
        idUser = props.user.utenteID;
      }
      Repo.deleteSignedUp(props.apiToken, idUser, session.sessioneCorsoID)
        .then((response) => {
          reloadPage();
        })
        .catch((error) => {
          toggleModalConfirmation(true);
          setRegistrationResult(false);
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    } else {
    }
  };

  const deleteFromOperator = (idUser) => {
    if (props.apiToken) {
      Repo.deleteSignedUp(
        props.apiToken,
        idUser.utenteID,
        session.sessioneCorsoID
      )
        .then((response) => {
          reloadPage();
        })
        .catch((error) => {
          toggleModalConfirmation(true);
          setRegistrationResult(false);
          setErrorMessage("Non è possibile eliminare l'iscrizione");
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  function getBase64(file) {
    return new Promise(function (resolve) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  }

  const setIscrizione = async () => {
    setValidateStart(false);

    var tempRequest = {
      sessioneCorsoID: session && session.sessioneCorsoID,
    };

    if (uploadReceipt) {
      var base64File = await getBase64(uploadReceipt);
      var splitBase64 = base64File.split(",");
      tempRequest["allegato"] = splitBase64[1];
      tempRequest["tipoFile"] = uploadReceipt.type;
    }

    Repo.postSignedUp(props.apiToken, JSON.stringify(tempRequest))
      .then((response) => {
        if (response.data.pagamentoId != null) {
          let paymentData = {
            id: response.data.pagamentoId,
            price: response.data.sessioneCorso.prezzo.toFixed(2),
            description:
              "LEARNING - ISCRIZIONE" +
              response.data.iscrizioneID +
              " di € " +
              response.data.sessioneCorso.prezzo.toFixed(2),
          };
          //PAGAMENTO STRIPE
          Repo.postPagamento(props.token, JSON.stringify(paymentData))
            .then((response) => {
              window.location.href = response.data;
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          toggleModalConfirmation(true);
          setRegistrationResult(true);
        }
        reloadPage();
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          setErrorMessage(error.response.data);
        } else {
          setErrorMessage();
        }
        toggleModalConfirmation(true);
        setRegistrationResult(false);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  const [modalAddUser, setModalAddUser] = useState(false);

  const downloadList = () => {
    if (props.apiToken) {
      Repo.postUsersList(props.apiToken, paramsId)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "ListaUtenti.pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log("error: ", error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  const downloadCertificato = () => {
    if (props.apiToken) {
      Repo.getCertificatoCorso(props.apiToken, session.iscrizioneID)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Certificato.pdf");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log("error: ", error);
        });
    }
  };

  const selectUser = (user) => {
    setSelectUserPassed(user);
    setOpenModalePassed(true);
  };

  const passedSession = () => {
    if (props.apiToken) {
      Repo.putSessionPassed(props.apiToken, selectUserPassed.iscrizioneID)
        .then((response) => {
          setOpenModalePassed(false);

          var tempRequest = {
            sessioneCorsoID: session && session.sessioneCorsoID,
          };

          Repo.postUserFromCourse(props.apiToken, JSON.stringify(tempRequest))
            .then((response) => {
              if (response.status == 200) {
                setUsers(response.data.data);
              }
            })
            .catch((error) => {
              console.log(error);
              if (error.response.status == 401) {
                props.deleteCoockieExpired();
              }
            });
        })
        .catch((error) => {
          console.log("error: ", error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  const addUserFromOperator = (userId) => {
    setModalAddUser(false);

    var tempRequest = {
      utenteID: userId,
      sessioneCorsoID: session && session.sessioneCorsoID,
    };

    Repo.postSignedUpOperator(props.apiToken, JSON.stringify(tempRequest))
      .then((response) => {
        if (response.data.statusCode == 200) {
          toggleModalConfirmation(true);
          setRegistrationResult(true);
          reloadPage();
        } else {
          toggleModalConfirmation(true);
          setRegistrationResult(false);
          console.log(error);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response) {
          setErrorMessage(error.response.data);
        } else {
          setErrorMessage();
        }
        toggleModalConfirmation(true);
        setRegistrationResult(false);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  const closeSession = () => {
    if (props.apiToken) {
      Repo.closeSession(props.apiToken, paramsId)
        .then((response) => {
          if (response.data.statusCode == 200) {
            setClosingResult(true);
            window.location.href = props.subfolder + "/session_courses";
          } else if (response.data.statusCode == 400) {
            toggleCloseModalConfirmation(true);
            setClosingResult(false);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          toggleCloseModalConfirmation(true);
          setClosingResult(false);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  const reverseDate = (date) => {
    if (date) {
      var dateString = date; // Oct 23
      var dateMomentObject = moment(dateString, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object
      return dateObject;
    }
  };

  const downloadRecording = (recordingId) => {
    setLoading(true);
    Repo.getRecording(props.apiToken, recordingId)
      .then((response) => {
        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Recording.mp4");
          document.body.appendChild(link);
          link.click();
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });
  };

  const editYoutubeLink = () => {
    if (newYTLink.match(regexURL)) {
      setValidationURL(true);
      Repo.postYTLink(
        props.apiToken,
        currentRec.recordingID,
        encodeURIComponent(newYTLink)
      )
        .then((response) => {
          if (response.status == 200) {
            window.location.href =
              props.subfolder + "/session_course_detail?id=" + paramsId;
          }
        })
        .catch((error) => {
          console.log(error);
          setCurrentRec({});
          setNewYTLink("");
        });
    } else {
      setValidationURL(false);
    }
  };

  return isPayed ? (
    <div className="main-body mt-2 mb-0 text-center">
      <div className="col-lg-12">
        <div className="card card-teaser rounded shadow">
          <div className="card-body p-3">
            {isVerified ? (
              <>
                <svg className="icon-success icon-xl">
                  <use href={`${iconCheckOk}#it-check-circle`} />
                </svg>
                <br />
                <br />
                <h3>Il pagamento è stato effettuato con successo</h3>
                <h4>
                  Riceverai una mail di conferma dell'ordine, potrebbe essere
                  necessario verificare all'interno dello spam
                </h4>
              </>
            ) : (
              <>
                <svg
                  className="icon-danger icon-xl"
                  style={{ paddingTop: "10%" }}
                >
                  <use href={`${iconCheckOk}#it-close-circle`} />
                </svg>
                <br />
                <br />
                <h3>Il pagamento non è andato a buon fine</h3>
              </>
            )}
            <br />
            <br />
            <Button
              onClick={() =>
                (window.location.href =
                  props.subfolder + "/session_course_detail?id=" + paramsId)
              }
              className="btn btn-primary btn-sm ml-auto"
            >
              Torna al corso
            </Button>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      {session ? (
        <>
          <div>
            <div className="col-lg-12 text-right">
              {isOperator && (
                <Link
                  className="btn btn-outline-primary btn-icon btn-xs ml-2 px-2 py-2 "
                  to={"/course_detail?id=" + (session && session.corsoID)}
                >
                  <span>Visualizza Corso</span>
                </Link>
              )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-lg-8">
                  <h3>{session ? session.nome : "/"}</h3>
                  <div className="row mt-2 mb-2">
                    <div className="col-12">
                      <b>Edizione: </b>
                      {session && session.edizione}
                    </div>
                  </div>
                  <hr />

                  {session && session.descrizione && (
                    <p
                      style={{ padding: "12px" }}
                      dangerouslySetInnerHTML={{ __html: session.descrizione }}
                    />
                  )}
                </div>
                <div className="col-lg-4">
                  <div className="card card-teaser roundedCustom shadow primary-border-color border p-3">
                    <div className="card-body">
                      <div className="row mt-2 mb-2">
                        {session && session.prezzo != 0 && !isMyCourse ? (
                          <div
                            className="col-lg-12 d-flex align-items-center"
                            style={{
                              fontSize: "32px",
                              fontWeight: "800",
                              paddingRight: "50px",
                            }}
                          >
                            {session.prezzo.toFixed(2) + "€"}
                          </div>
                        ) : (
                          <>
                            <div className="col-lg-4 ">
                              <h5 className="card-title ">Dettaglio</h5>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="card-text">
                        <div className="row mt-2 mb-2">
                          <div className="col-4">
                            <b>Relatore</b>
                          </div>
                          <div className="col-8">
                            {session && session.relatori}
                          </div>
                        </div>
                        <div className="row mt-2 mb-2">
                          <div className="col-4">
                            <b>Data Inizio</b>
                          </div>
                          <div className="col-8">
                            {session && session.dataInizio}
                          </div>
                        </div>
                        <div className="row mt-2 mb-2">
                          <div className="col-4">
                            <b>Data Fine</b>
                          </div>
                          <div className="col-8">
                            {session && session.dataFine}
                          </div>
                        </div>
                        <div className="row mt-2 mb-2">
                          <div className="col-4">
                            <b>Numero Crediti</b>
                          </div>

                          <div className="col-8">
                            {session && session.creditiFormativi}
                          </div>
                        </div>
                        <div className="row mt-2 mb-2">
                          <div className="col-4">
                            <b>In Presenza</b>
                          </div>
                          <div className="col-8">
                            {session && session.isPresenza ? (
                              <p>Si</p>
                            ) : (
                              <p>No</p>
                            )}
                          </div>
                        </div>
                        <div className="row mt-2 mb-2">
                          <div className="col-4">
                            <b>Argomenti</b>
                          </div>
                          <div className="col-8">
                            {" "}
                            {session &&
                              session.nomiMaterie.length > 0 &&
                              session.nomiMaterie.map((x, index) => (
                                <div
                                  className="btn btn-outline-success btn-xs mx-1"
                                  style={{ cursor: "auto" }}
                                  key={index}
                                >
                                  {x}
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="row mt-2 mb-2">
                          <div className="col-4">
                            <b>Stato</b>
                          </div>
                          <div className="col-8">
                            {session && session.isTerminato
                              ? "Terminato"
                              : "Aperto"}
                          </div>
                        </div>
                        {session && session.maxUtenti && (
                          <>
                            <div className="row mt-2 mb-2">
                              <div className="col-4">
                                <b>Posti totali</b>
                              </div>
                              <div className="col-8">{session.maxUtenti}</div>
                            </div>
                            <div className="row mt-2 mb-2">
                              <div className="col-4">
                                <b>Posti disponibili</b>
                              </div>
                              <div className="col-8">
                                {session &&
                                  session.maxUtenti - session.utentiIscritti}
                              </div>
                            </div>
                          </>
                        )}

                        {isMyCourse && (
                          <>
                            {/* se il pagamento non è andato a buon fine, flagCorsoPagato è false */}
                            {session &&
                            session.flagCorsoPagato == true &&
                            session.prezzo != 0 ? (
                              <>
                                <div className="row mt-2 mb-2">
                                  <div className="col-4">
                                    <b>Prezzo</b>
                                  </div>
                                  <div className="col-8">
                                    {session && session.prezzo.toFixed(2) + "€"}
                                  </div>
                                </div>
                                <div className="row mt-4">
                                  <div className="col-12 d-flex justify-content-center">
                                    <div
                                      type="button"
                                      className="btn btn-outline-success p-2"
                                      style={{ cursor: "auto" }}
                                    >
                                      <svg className="icon icon-success">
                                        <use
                                          href={`${iconSprite}#it-check-circle`}
                                        />
                                      </svg>
                                      <span className="text-success pt-1 pl-2">
                                        <b>PAGAMENTO EFFETTUATO</b>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="row mt-4">
                                  <div className="col-12 d-flex justify-content-center">
                                    <div
                                      type="button"
                                      className="btn btn-outline-success p-2"
                                      style={{ cursor: "auto" }}
                                    >
                                      <svg className="icon icon-success">
                                        <use
                                          href={`${iconSprite}#it-check-circle`}
                                        />
                                      </svg>
                                      <span className="text-success pt-1 pl-2">
                                        <b>ISCRIZIONE EFFETTUATA</b>
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}

                        {isOperator ? (
                          <>
                            {session && !session.isTerminato && (
                              <>
                                <div className="row mt-4 mb-2">
                                  <div className="col-6 text-center">
                                    <Link
                                      to={"/edit_session?id=" + paramsId}
                                      className="btn btn-success"
                                    >
                                      Modifica
                                    </Link>
                                  </div>
                                  <div className="col-6 text-center">
                                    <button
                                      type="button"
                                      className="btn btn-danger"
                                      onClick={() => setModalCloseSession(true)}
                                    >
                                      Chiudi
                                    </button>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        ) : (
                          <>
                            {session && !isMyCourse ? (
                              <>
                                <div className="row mt-4 mb-2">
                                  {(session &&
                                    moment(
                                      session.chiusuraPrenotazione,
                                      "DD/MM/YYYY"
                                    ).isAfter(
                                      moment(currentDate, "DD/MM/YYYY")
                                    )) ||
                                  (session &&
                                    moment(
                                      session.chiusuraPrenotazione,
                                      "DD/MM/YYYY"
                                    ).isSame(
                                      moment(currentDate, "DD/MM/YYYY")
                                    )) ? (
                                    <div className="col-12 text-center">
                                      {session && session.prezzo == 0 ? (
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          disabled={
                                            session.utentiIscritti ==
                                            session.maxUtenti
                                              ? true
                                              : false
                                          }
                                          onClick={() => setIscrizione()}
                                        >
                                          Iscriviti
                                        </button>
                                      ) : (
                                        <button
                                          type="button"
                                          className="btn btn-primary"
                                          disabled={
                                            session.utentiIscritti ==
                                            session.maxUtenti
                                              ? true
                                              : false
                                          }
                                          onClick={() => setIscrizione()}
                                        >
                                          Acquista Ora
                                        </button>
                                      )}
                                    </div>
                                  ) : (
                                    <>
                                      <div className="col-12 text-center">
                                        <div
                                          style={{ cursor: "auto" }}
                                          type="button"
                                          className="btn btn-outline-info"
                                        >
                                          Iscrizioni chiuse
                                        </div>
                                      </div>
                                    </>
                                  )}
                                </div>
                              </>
                            ) : (
                              <>
                                {session && !session.isTerminato && (
                                  <>
                                    {session &&
                                      (moment(
                                        session.chiusuraPrenotazione,
                                        "DD/MM/YYYY"
                                      ).isAfter(
                                        moment(currentDate, "DD/MM/YYYY")
                                      ) ||
                                        moment(
                                          session.chiusuraPrenotazione,
                                          "DD/MM/YYYY"
                                        ).isSame(
                                          moment(currentDate, "DD/MM/YYYY")
                                        )) &&
                                      session.prezzo == 0 && (
                                        <div className="row mt-4 mb-2">
                                          <div className="col-12 text-center">
                                            <button
                                              type="button"
                                              className="btn btn-danger"
                                              onClick={() => deleteIscrizione()}
                                            >
                                              Elimina iscrizione
                                            </button>
                                          </div>
                                        </div>
                                      )}
                                  </>
                                )}
                              </>
                            )}{" "}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <br />

              {!isOperator &&
                session &&
                (session.isTerminato ||
                  moment(session.dataFine, "DD/MM/YYYY").isBefore(
                    moment(currentDate, "DD/MM/YYYY")
                  )) &&
                isMyCourse && (
                  <div className="row mb-5 card card-teaser primary-border-color border roundedCustom shadow p-4">
                    <div className="col-12">
                      <h3>Esito e Certificato</h3>
                    </div>

                    <div className="col-12">
                      <hr />
                    </div>
                    <div className="col-12">
                      <div className="row align-items-center">
                        <div className="col-lg-4">
                          <h5 className="mb-1">Esito Finale:</h5>
                        </div>
                        <div className="col-lg-2">
                          {session && session.isSuperato ? (
                            <div
                              type="button"
                              className="btn btn-outline-success"
                              style={{ cursor: "auto" }}
                            >
                              Superato
                            </div>
                          ) : (
                            <div
                              type="button"
                              className="btn btn-outline-danger"
                              style={{ cursor: "auto" }}
                            >
                              Non Superato
                            </div>
                          )}
                        </div>
                        {session && session.isSuperato ? (
                          <>
                            <div className="col-lg-4">
                              <h5 className="mb-1">Certificato:</h5>
                            </div>
                            <div className="col-lg-2">
                              <button
                                onClick={() => downloadCertificato()}
                                type="button"
                                className="btn btn-success"
                              >
                                Scarica
                              </button>
                            </div>
                          </>
                        ) : (
                          <>
                            {" "}
                            <div className="col-lg-6 text-center">
                              <a className="mb-1">
                                Superando il corso potrai visualizzare il
                                certificato
                              </a>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}
              {isOperator ? (
                <>
                  {/* LATO OPERATORE */}
                  <div className="row">
                    <div className="col-12">
                      <h3>Videolezioni</h3>
                    </div>

                    <div className="col-12">
                      <hr />
                    </div>

                    {/* GESTIONE VIDEOLEZIONI  */}
                    {session && !session.isTerminato ? (
                      <>
                        {(session &&
                          moment(session.dataInizio, "DD/MM/YYYY").isBefore(
                            moment(currentDate, "DD/MM/YYYY")
                          )) ||
                        (session &&
                          moment(session.dataInizio, "DD/MM/YYYY").isSame(
                            moment(currentDate, "DD/MM/YYYY")
                          )) ? (
                          <>
                            <div className="col-12 mb-3">
                              <div className="row">
                                <div className="col-12 px-0 ml-2 px-3">
                                  {!meetingData ? (
                                    <>
                                      <button
                                        className="btn btn btn-primary btn-icon btn-xs px-2 py-2"
                                        onClick={() => createMeeting()}
                                      >
                                        Crea Meeting
                                      </button>{" "}
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        className="btn btn btn-success btn-icon btn-xs px-2 py-2"
                                        onClick={() => joinMeeting(1)}
                                      >
                                        Avvia Meeting
                                      </button>
                                    </>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <span
                              className="text mb-3"
                              style={{ fontSize: "24px" }}
                            >
                              Non è possibile creare un meeting in quanto il
                              corso non è ancora iniziato.
                            </span>
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <span
                          className="text mb-3"
                          style={{ fontSize: "24px" }}
                        >
                          Non è possibile creare un meeting in quanto il corso è
                          terminato.
                        </span>
                      </>
                    )}

                    {recordingsList && recordingsList.length > 0 && (
                      <div className="row mb-4 mt-3 card card-teaser primary-border-color border roundedCustom shadow p-4">
                        <div className="col-12 mb-3">
                          <h3>Lista Registrazioni</h3>
                        </div>

                        {recordingsList.map((rec, index) => (
                          <React.Fragment key={index}>
                            <div className="col-12">
                              <div className="row d-flex align-items-center">
                                <div className="col-7">
                                  - Lezione del {rec.dataRec}
                                </div>
                                <div className="col-2">
                                  <button
                                    className="btn btn-outline-danger btn-icon btn-xs m-2"
                                    onClick={() => {
                                      setCurrentRec(rec);
                                      setModalYoutube(!isOpenYoutube);
                                    }}
                                  >
                                    <svg
                                      className="icon icon-danger icon-sm"
                                      style={{ cursor: "pointer" }}
                                    >
                                      <use href={`${iconSprite}#it-youtube`} />
                                    </svg>
                                    Modifica
                                  </button>
                                </div>

                                <div className="col-3 text-right pr-4">
                                  {rec.linkYoutube && rec.linkYoutube != null && (
                                    <a href={rec.linkYoutube} target="_blank">
                                      <svg
                                        className="icon icon-danger icon-xl"
                                        style={{ cursor: "pointer" }}
                                      >
                                        <use
                                          href={`${iconSprite}#it-youtube`}
                                        />
                                      </svg>
                                    </a>
                                  )}

                                  <button
                                    onClick={() =>
                                      downloadRecording(rec.recordingID)
                                    }
                                    key={index}
                                    className="btn btn-success btn-icon btn-xs m-2"
                                  >
                                    <svg className="icon icon-white ">
                                      <use href={`${iconSprite}#it-download`} />
                                    </svg>
                                    <span>Download</span>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </React.Fragment>
                        ))}
                      </div>
                    )}

                    <div className="row col-lg-12">
                      <div className="col-lg-12">
                        <h3>Lista Iscritti</h3>
                      </div>

                      <div className="col-lg-12">
                        <hr />
                      </div>

                      <div className="col-12 mb-5">
                        <div className="text-right">
                          <div className="row">
                            <div className="col-12 px-0 text-right ml-2 px-3">
                              {users && users.length > 0 && (
                                <>
                                  <button
                                    onClick={() => downloadList()}
                                    className="btn btn btn-success btn-icon btn-xs px-2 py-2"
                                  >
                                    <svg className="icon icon-white ">
                                      <use href={`${iconSprite}#it-download`} />
                                    </svg>
                                    <span>Scarica Lista</span>
                                  </button>
                                </>
                              )}

                              {session &&
                                session.prezzo == 0 &&
                                session.utentiIscritti != session.maxUtenti && (
                                  <>
                                    <button
                                      className="btn btn-primary btn-icon btn-xs ml-2 px-2 py-2"
                                      onClick={() =>
                                        setModalAddUser(!modalAddUser)
                                      }
                                    >
                                      <svg className="icon icon-white ">
                                        <use href={`${iconSprite}#it-upload`} />
                                      </svg>
                                      <span>Iscrivi Utente</span>
                                    </button>
                                  </>
                                )}
                            </div>
                          </div>
                        </div>
                        {users && users.length > 0 ? (
                          <>
                            <br />
                            <UsersListSession
                              operator={isOperator}
                              apiToken={props.apiToken}
                              setDeleteMethod={deleteFromOperator}
                              customMessage="Vuoi procedere con l'eliminazione di questo iscritto dal corso?"
                              hideCheckIcon={true}
                              dataCloseSession={session && session.dataFine}
                              starMethod={selectUser}
                              isPagamento={
                                session && session.prezzo && session.prezzo != 0
                                  ? true
                                  : false
                              }
                            >
                              {users}
                            </UsersListSession>
                          </>
                        ) : (
                          <span
                            className="text mb-3"
                            style={{ fontSize: "24px" }}
                          >
                            Non ci sono utenti iscritti a questo corso!
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                // LATO UTENTE
                <>
                  <div className="row">
                    <div className="col-12">
                      <h3>Videolezioni</h3>
                    </div>

                    <div className="col-12">
                      <hr />
                    </div>
                    {isMyCourse && !session.isTerminato ? (
                      <>
                        {meetingData && meetingData.isClosed == false ? (
                          <>
                            <div className="col-12 mb-5">
                              <div className="row">
                                <div className="col-4">
                                  <h5>Partecipa al meeting: </h5>
                                </div>
                                <div className="col-8">
                                  <button
                                    className="btn btn btn-success btn-icon btn-xs px-2 py-2"
                                    onClick={() => joinMeeting(0)}
                                  >
                                    Partecipa
                                  </button>
                                </div>
                              </div>
                              <div className="row mt-2">
                                <div className="col-12">
                                  <span
                                    className="text"
                                    style={{ fontSize: "15px" }}
                                  >
                                    Se hai problemi a partecipare al meeting,{" "}
                                    <a href={meetingData.joinUrl}>
                                      clicca qui!
                                    </a>
                                  </span>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            {session &&
                            moment(
                              session.chiusuraPrenotazione,
                              "DD/MM/YYYY"
                            ).isAfter(moment(currentDate, "DD/MM/YYYY")) ? (
                              <>
                                <span
                                  className="text mb-5"
                                  style={{ fontSize: "24px" }}
                                >
                                  Il corso non è ancora iniziato
                                </span>
                              </>
                            ) : (
                              <>
                                <span
                                  className="text mb-5"
                                  style={{ fontSize: "24px" }}
                                >
                                  Al momento non ci sono videolezioni.
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </>
                    ) : (
                      <>
                        <span
                          className="text mb-5"
                          style={{ fontSize: "24px" }}
                        >
                          E' possibile partecipare alle videolezioni solo se si
                          è iscritti al corso o se non è terminato.
                        </span>
                      </>
                    )}

                    {isMyCourse && recordingsList && recordingsList.length > 0 && (
                      <div className="row mb-4 mt-3 card card-teaser primary-border-color border roundedCustom shadow p-4">
                        <div className="col-12">
                          <h4>Lista Registrazioni</h4>
                        </div>

                        <div className="col-12">
                          {recordingsList.map((rec, index) => (
                            <React.Fragment key={index}>
                              <div className="col-12">
                                <div className="row d-flex align-items-center">
                                  <div className="col-8">
                                    - Lezione del {rec.dataRec}
                                  </div>
                                  <div className="col-4 text-right pr-4">
                                    {rec.linkYoutube &&
                                      rec.linkYoutube != null && (
                                        <a
                                          href={rec.linkYoutube}
                                          target="_blank"
                                        >
                                          <svg
                                            className="icon icon-danger icon-xl"
                                            style={{ cursor: "pointer" }}
                                          >
                                            <use
                                              href={`${iconSprite}#it-youtube`}
                                            />
                                          </svg>
                                        </a>
                                      )}

                                    <button
                                      onClick={() =>
                                        downloadRecording(rec.recordingID)
                                      }
                                      key={index}
                                      className="btn btn btn-success btn-icon btn-xs m-2"
                                    >
                                      <svg className="icon icon-white ">
                                        <use
                                          href={`${iconSprite}#it-download`}
                                        />
                                      </svg>
                                      <span>Download</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </React.Fragment>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              )}

              {/* {isOperator &&
          session &&
          moment(session.dataInizio, "DD/MM/YYYY").isBefore(
            moment(currentDate, "DD/MM/YYYY")
          ) ? (
            <>
              <div className="row">
                <div className="col-12">
                  <h3>Videolezioni</h3>
                </div>

                <div className="col-12">
                  <hr />
                </div>

                <div className="col-12 mb-5">
                  <div className="row">
                    <div className="col-12 px-0 ml-2 px-3">
                      {!meetingData ? (
                        <>
                          <button
                            className="btn btn btn-primary btn-icon btn-xs px-2 py-2"
                            onClick={() => createMeeting()}
                          >
                            Crea Meeting
                          </button>{" "}
                        </>
                      ) : (
                        <>
                          <button
                            className="btn btn btn-success btn-icon btn-xs px-2 py-2"
                            onClick={() => joinMeeting(1)}
                          >
                            Avvia Meeting
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <h3>Lista Iscritti</h3>
                </div>

                <div className="col-12">
                  <hr />
                </div>

                <div className="col-12">
                  <div className="text-right">
                    <div className="row">
                      <div className="col-12 px-0 text-right ml-2 px-3">
                        <button
                          onClick={() => downloadList()}
                          className="btn btn btn-success btn-icon btn-xs px-2 py-2"
                        >
                          <svg className="icon icon-white ">
                            <use href={`${iconSprite}#it-download`} />
                          </svg>
                          <span>Scarica Lista</span>
                        </button>

                        <button
                          className="btn btn-primary btn-icon btn-xs ml-2 px-2 py-2"
                          onClick={() => setModalAddUser(!modalAddUser)}
                        >
                          <svg className="icon icon-white ">
                            <use href={`${iconSprite}#it-upload`} />
                          </svg>
                          <span>Iscrivi Utente</span>
                        </button>
                      </div>
                    </div>
                  </div>
                  <br />

                  <UsersListSession
                    operator={isOperator}
                    apiToken={props.apiToken}
                    setDeleteMethod={deleteFromOperator}
                    customMessage="Vuoi procedere con l'eliminazione di questo iscritto dal corso?"
                    hideCheckIcon={true}
                    dataCloseSession={session && session.dataFine}
                    starMethod={selectUser}
                  >
                    {users}
                  </UsersListSession>
                </div>
              </div>
            </>
          ) : (
            <>
              {!isOperator &&
                meetingData &&
                isMyCourse &&
                meetingData.isClosed == false && (
                  <>
                    <div className="row">
                      <div className="col-6">
                        <h4>Partecipa al meeting: </h4>
                      </div>
                      <div className="col-6">
                        <button
                          className="btn btn btn-success btn-icon btn-xs px-2 py-2"
                          onClick={() => joinMeeting(0)}
                        >
                          Partecipa
                        </button>
                      </div>
                    </div>
                  </>
                )}
            </>
          )} */}
              {/* <br />
          <div className="row mb-3">
            {!isOperator && (
              <>
                {session && !session.isTerminato && !isMyCourse && (
                  <>
                    {(session &&
                      moment(
                        session.chiusuraPrenotazione,
                        "DD/MM/YYYY"
                      ).isAfter(moment(currentDate, "DD/MM/YYYY"))) ||
                    (session &&
                      moment(session.chiusuraPrenotazione, "DD/MM/YYYY").isSame(
                        moment(currentDate, "DD/MM/YYYY")
                      )) ? (
                      <div className="col-lg-12 mb-3">
                        <div className="col-12">
                          <h3>
                            Chiede di partecipare all'iniziativa scegliendo:
                          </h3>
                        </div>

                        <div className="mt-2">
                          <div className="table-responsive">
                            <table className="table">
                              {obbCosti.length != 0 ? (
                                <tbody>
                                  {obbCosti.map((x, index) => (
                                    <tr key={index}>
                                      <td style={{ width: "10%" }}>
                                        <input
                                          id={"checkbox" + index}
                                          type="checkbox"
                                          aria-labelledby={
                                            "checkbox" + index + "-help"
                                          }
                                          onChange={() => checkPayments(x)}
                                        />
                                      </td>
                                      <td style={{ width: "70%" }}>
                                        <b>{x.descrizione}</b>
                                      </td>

                                      <td style={{ width: "20%" }}>
                                        <b>{x.importo} €</b>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              ) : (
                                <tbody>
                                  <tr>
                                    <td>
                                      Non ci sono pagamenti e servizi
                                      disponibili.
                                    </td>
                                  </tr>
                                </tbody>
                              )}
                            </table>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </>
                )}
              </>
            )}
          </div>

          {session && !isMyCourse && !session.isTerminato && (
            <>
              {(session &&
                moment(session.chiusuraPrenotazione, "DD/MM/YYYY").isAfter(
                  moment(currentDate, "DD/MM/YYYY")
                )) ||
              (session &&
                moment(session.chiusuraPrenotazione, "DD/MM/YYYY").isSame(
                  moment(currentDate, "DD/MM/YYYY")
                )) ? (
                <>
                  <div className="row mb-5 card card-teaser primary-border-color border roundedCustom shadow p-4">
                    <div className="col-lg-8 mb-3">
                      <div className="col-12">
                        <h3>Metodi di pagamento disponibili</h3>
                      </div>
                      {paymentM ? (
                        <div className="mt-3">
                          {paymentM &&
                            paymentM.map((x, index) => (
                              <div className="pl-1" key={index}>
                                <a>- {x.descrizione}</a>
                                <hr />
                              </div>
                            ))}
                        </div>
                      ) : (
                        <>
                          <a>
                            Non sono presenti metodi di pagamento per questo
                            corso
                          </a>
                        </>
                      )}{" "}
                    </div>

                    {!isOperator && (
                      <div className="col-lg-4  ">
                        <div className="col-12  ">
                          <UploadFile
                            handleFile={setUploadReceipt}
                            testoInterno={"Carica Ricevuta"}
                          ></UploadFile>
                        </div>{" "}
                        <div className="col-12 text-center ">
                          {validateStart && (
                            <>
                              {!uploadReceipt && (
                                <b className="text-danger">
                                  Per proseguire, devi caricare la ricevuta di
                                  pagamento
                                </b>
                              )}
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
            </>
          )} */}

              <div className="row mb-3 card card-teaser primary-border-color border roundedCustom shadow p-4">
                <div className="col-12">
                  <h3>Materiale</h3>
                </div>

                <div className="col-12">
                  <hr />

                  {!isOperator ? (
                    <>
                      {session && !isMyCourse ? (
                        <>
                          {" "}
                          <div>
                            <Fade style={{ height: "150px" }} className="mt-3">
                              <div className="row">
                                <div className="col-12 ">
                                  {/* <MaterialList operator={isOperator}>{obbj}</MaterialList> */}
                                </div>
                              </div>
                              <Dimmer>
                                <h5>
                                  {" "}
                                  Iscriviti per poter visualizzare il materiale
                                  di studio
                                </h5>
                              </Dimmer>
                            </Fade>
                          </div>
                        </>
                      ) : (
                        <MaterialList
                          apiToken={props.apiToken}
                          operator={false}
                        >
                          {material}
                        </MaterialList>
                      )}
                    </>
                  ) : (
                    <>
                      <MaterialList
                        apiToken={props.apiToken}
                        operator={isOperator}
                      >
                        {material}
                      </MaterialList>
                    </>
                  )}
                </div>
              </div>
              <br />
              <br />
            </div>
          </div>
        </>
      ) : (
        <>
          <h3>Corso non trovato!</h3>
        </>
      )}

      <div>
        {isOpenConfirmation && (
          <Notification
            style={{ zIndex: "1" }}
            header={
              registrationResult
                ? "ISCRIZIONE AVVENUTA CON SUCCESSO"
                : errorMessage
                ? errorMessage
                : "Utente già iscritto"
            }
            state={registrationResult ? "success" : "error"}
            withIcon
            closeState={toggleModalConfirmation}
            dismissable={true}
          />
        )}
      </div>

      {isOpenYoutube && (
        <div>
          <Modal
            isOpen={isOpenYoutube}
            toggle={() => setModalYoutube(!isOpenYoutube)}
            centered
            size={"lg"}
          >
            <ModalHeader
              toggle={() => setModalYoutube(!isOpenYoutube)}
              id="youtubeModal"
            >
              Inserisci il link del video Youtube
            </ModalHeader>
            <ModalBody>
              <Input
                label="Link Youtube"
                name="youtubeLink"
                type="text"
                placeholder="Inserisci il link"
                className="mt-2"
                defaultValue={
                  currentRec.linkYoutube ? currentRec.linkYoutube : ""
                }
                {...(!validationURL && {
                  infoText: "Formato del link non corretto!",
                  className: "text-danger",
                })}
                onChange={(e) => {
                  setValidationURL(true);
                  setNewYTLink(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                className="btn btn-info text-white"
                onClick={() => setModalYoutube(!isOpenYoutube)}
              >
                Chiudi
              </Button>
              <Button
                color="primary"
                onClick={() => {
                  editYoutubeLink();
                }}
              >
                Salva modifiche
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}

      {modalAddUser && (
        <div>
          <Modal
            isOpen={modalAddUser}
            toggle={() => setModalAddUser(!modalAddUser)}
            centered
            size={"lg"}
          >
            <ModalHeader
              toggle={() => setModalAddUser(!modalAddUser)}
              id="esempio2"
            >
              Iscrivi utente
            </ModalHeader>
            <ModalBody>
              <SearchUser
                apiToken={props.apiToken}
                addUserFromOperator={addUserFromOperator}
              ></SearchUser>
            </ModalBody>
          </Modal>
        </div>
      )}

      {passed && (
        <div>
          <Modal
            isOpen={passed}
            toggle={() => setOpenModalePassed(!passed)}
            centered
          >
            <ModalHeader
              toggle={() => setOpenModalePassed(!passed)}
              id="esempio2"
            >
              Vuoi promuovere l'utente?
            </ModalHeader>
            <ModalBody></ModalBody>
            <ModalFooter>
              <Button color="primary" onClick={() => passedSession()}>
                Si
              </Button>
              <Button
                color="danger"
                onClick={() => setOpenModalePassed(!passed)}
              >
                No
              </Button>
            </ModalFooter>
          </Modal>
        </div>
      )}

      {isOperator && (
        <ModalCloseSession
          session={session ? session : {}}
          deleteMethod={() => closeSession()}
          open={modalCloseSession}
          setOpen={() => setModalCloseSession()}
        ></ModalCloseSession>
      )}

      {isCloseOpenConfirmation && (
        <Notification
          style={{ zIndex: "10000" }}
          header={
            closingResult
              ? "CHIUSURA AVVENUTA CON SUCCESSO!"
              : "Chiusura Fallita"
          }
          state={closingResult ? "success" : "error"}
          withIcon
          closeState={toggleCloseModalConfirmation}
          dismissable={true}
        />
      )}

      {meetingError && (
        <>
          <div
            class="alert alert-danger"
            role="alert"
            style={{
              position: "fixed",
              backgroundColor: "white",
              bottom: "0px",
              right: "5px",
            }}
          >
            {alertMessage}
          </div>
        </>
      )}

      {loading && (
        <div className="col-12">
          <div className="trasparente">
            <div className="progress-spinner progress-spinner-active spinner"></div>
            <span
              className="customTextSpinner"
              style={{
                top: "45%",
                position: "fixed",
                left: "46.5%",
                zIndex: "401",
              }}
            >
              Caricamento...
            </span>
          </div>
        </div>
      )}

      {/* {join && (
        <>
          <ZoomWrapper
            subfolder={props.subfolder}
            meetingData={meetingData}
            signature={signature}
            hostEmail={hostEmail}
            isOperator={isOperator}
            user={!isOperator ? user : null}
          ></ZoomWrapper>
        </>
      )} */}
    </>
  );
}
export default SessionCourseDetail;
