import React from "react";

import mainLogo from "../../Img/tipografia-learning.png";

import logo from "../../Img/pittogramma-learning.svg";

import logoServizi from "../../Img/servizi-onlife.png";
import { Link } from "react-router-dom";

function Footer(props) {
  return (
    <footer className="it-footer mt-4">
      <div className="it-footer-main footerCustom">
        <div className="container footerText">
          {/* Loghi */}
          <section>
            <div className="row ">
              <div className="col-sm-6">
                <div className="it-brand-wrapper">
                  <Link to="#" className="">
                    <img alt="dd" height="75" src={logo} />
                    <div className="it-brand-text">
                      <h2 className="no_toc">
                        {" "}
                        <img
                          className="img-fluid"
                          src={mainLogo}
                          style={{ marginLeft: "5px", width: "200px" }}
                        />
                      </h2>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </section>
          {/* Info */}
          <section className="py-4">
            <div className="row clearfix">
              <div className="col-lg-3 col-md-3 pb-2">
                <h6>SEGNALAZIONI</h6>
                <p>
                  <Link
                    className="text-white"
                    to="#"
                    onClick={(e) => {
                      window.location.href =
                        "mailto:info@semplicitalearning.it";
                      e.preventDefault();
                    }}
                  >
                    info@semplicitalearning.it
                  </Link>
                </p>
              </div>
              <div className="col-lg-6 col-md-3 pb-2">
                <h6>CONTATTI</h6>
                <p>
                  <strong>Servizi Locali SPA</strong> <br />
                  Via G. Schiaparelli 17 - 20122 Milano (MI) <br />
                  C.F./P.IVA: 03170580751 <br />
                  <a
                    href="http://www.servizilocalispa.it"
                    className="small-prints text-white"
                  >
                    www.servizilocalispa.it
                  </a>
                </p>
              </div>
              <div className="col-sm-3">
                <div className="it-brand-wrapper pt-3">
                  <a href="http://www.servizilocalispa.it">
                    <img alt="logo" height="65" src={logoServizi} />
                    <div className="it-brand-text"></div>
                  </a>
                </div>
              </div>
            </div>
          </section>
          {/* Privacy */}
          <section className="py-4 border-white border-top">
            <div className="container  ">
              <h3 className="sr-only">Sezione Link Utili</h3>
              <ul className="it-footer-small-prints-list list-inline mb-0 d-flex flex-column flex-md-row">
                <li className="list-inline-item">
                  <Link
                    to="/privacy_policy"
                    className="small-prints text-white"
                    title="Privacy-Cookies"
                  >
                    Privacy policy
                  </Link>
                </li>
              </ul>
            </div>
          </section>
          <div className="cookiebar">
            <p>
              Questo sito utilizza cookie tecnici, analytics e di terze parti.{" "}
              <br />
              Proseguendo nella navigazione accetti l’utilizzo dei cookie.
            </p>
            <div className="cookiebar-buttons">
              <Link to="/privacy_policy" className="cookiebar-btn">
                Privacy policy<span className="sr-only">cookies</span>
              </Link>
              <button
                data-accept="cookiebar"
                className="cookiebar-btn cookiebar-confirm"
              >
                Accetto<span className="sr-only"> i cookies</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
export const MemoizedFooter = React.memo(Footer);
