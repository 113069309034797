import { useEffect, useState } from "react";
import Repo from "../Utility/Repo";
import ViewSubject from "../Objects/ViewSubject";

function PageCreateSubject(props) {
  const [isOperator, setIsOperator] = useState(props.operator);
  const [institution, setInstitution] = useState();
  const [apiToken, setApiToken] = useState(props.apiToken);
  const [sendEnte, setSendEnte] = useState(false);
  const [backButton, setBackButton] = useState(true);

  const createSend = (request) => {
    if (apiToken) {
      Repo.postSubject(apiToken, JSON.stringify(request))
        .then((response) => {
          window.location.href = props.subfolder + "/subjects_management";
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  return (
    <>
      {/* Filtri */}

      {/* Ente */}

      <ViewSubject
        editMethod={createSend}
        displayMode={false}
        sendEnte={sendEnte}
        backButton={backButton}
      ></ViewSubject>
    </>
  );
}

export default PageCreateSubject;
