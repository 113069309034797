import { useEffect, useState } from "react";
import Select from "../Objects/Select";

import Repo from "../Utility/Repo";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";

function ModalCloseSession(props) {
  const [name, setName] = useState();

  const [startValidate, setStartValidate] = useState(false);

  const confirmTheDeletion = () => {
    if (name === props.session.edizione) {
      props.deleteMethod();
    } else {
      setStartValidate(true);
    }
  };

  return (
    <div>
      <Modal
        isOpen={props.open}
        toggle={() => props.setOpen(!props.open)}
        centered
        size={"lg"}
        labelledBy="esempio9"
      >
        <ModalHeader toggle={() => props.setOpen(!props.open)} id="essempio9">
          Chiusura: {props.session.nome}
          <br />
          Edizione: <u>{props.session.edizione}</u>
        </ModalHeader>
        <ModalBody>
          <p>
            Questa azione non può essere annullata. Ciò chiuderà definitivamente
            l'edizione <b>{props.session.edizione}</b> per il corso{" "}
            <b>{props.session.nome}</b>.
          </p>
          <br />
          <div className="mt-3">
            {" "}
            Si prega di digitare{" "}
            <b>
              <u>{props.session.edizione}</u>
            </b>{" "}
            per confermare.
          </div>

          <Input
            id="name"
            name="name"
            onChange={(e) => setName(e.target.value)}
            placeholder="DIGITA IL TESTO RIPORTATO SOPRA"
            required
            {...(startValidate &&
              !name && {
                infoText: "Il campo non può essere vuoto!",
              })}
            {...(startValidate &&
              name !== props.session.edizione && {
                infoText: "Il tempo inserito non corisponde",
              })}
            {...(startValidate && !name && { className: "text-danger" })}
            {...(startValidate &&
              name !== props.session.edizione && { className: "text-danger" })}
          />
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => props.setOpen(!props.open)}>
            Chiudi
          </Button>
          <Button color="primary" onClick={() => confirmTheDeletion()}>
            Salva modifiche
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalCloseSession;
