import { useEffect, useState } from "react";
import useLocalStorage from "../Utility/useLocalStorage";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import ViewUserProfile from "./ViewUserProfile";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "design-react-kit";
import Repo from "../Utility/Repo";

function UsersList(props) {
  const [isOpen, toggleModal] = useState(false);
  const [isOpenModalUsers, setIsOpenModalUsers] = useState(false);
  const [isOpenConfirmation, toggleModalConfirmation] = useState(false);
  const [selectUserFromDelete, setSelectUserFromDelete] = useState();
  const [selectUser, setSelectUser] = useState();
  const [apiToken, setApiToken] = useState(props.apiToken);
  const ids = ["example1", "example2", "example3", "example4", "example5"];
  const [qualifySelect, setQualifySelect] = useState({});
  const [institutionSelect, SetInstitutionSelect] = useState({});
  const [qualifiche, setQualifiche] = useState("Qualifiche");
  const [institution, setInstitution] = useState("Enti");

  // Workaround for testing, do not use this
  ids.map((id, i) => {
    const div = document.createElement("div");
    div.setAttribute("id", id);
    document.body.appendChild(div);
    return null;
  });

  useEffect(() => {
    if (apiToken) {
      Repo.getSelect(qualifiche, apiToken)
        .then((response) => {
          if (response.status == 200) {
            setQualifySelect(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      Repo.getSelect(institution, apiToken)
        .then((response) => {
          if (response.status == 200) {
            SetInstitutionSelect(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const editSend = (request, id) => {
    if (apiToken) {
      Repo.putUserAdmin(apiToken, JSON.stringify(request), id)
        .then((response) => {
          if (response.status == 200) {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          {props.children && props.children.length > 0 ? (
            props.children.map((x, index) => (
              <div key={index}>
                <div className="card card-teaser rounded shadow mb-2 col-12">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-4 d-flex align-items-center">
                        <h4>
                          {x.cognome || x.nome
                            ? x.cognome + " " + x.nome
                            : "Non Presente"}
                        </h4>
                      </div>

                      <div className=" col-8 text-right">
                        <div className="bd-example tooltip-demo">
                          <div className="muted">
                            {!props.hideCheckIcon && (
                              <div id={ids[0]} className="btn">
                                <svg
                                  className="icon icon-white bg-success rounded-circle"
                                  style={{
                                    width: "35px",
                                    height: "35px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    toggleModalConfirmation(
                                      !isOpenConfirmation
                                    ),
                                      setSelectUser(x);
                                  }}
                                >
                                  <use href={`${iconSprite}#it-check`} />
                                </svg>
                              </div>
                            )}

                            {!props.hideCloseIcon && (
                              <div
                                className="btn"
                                id={ids[1]}
                                onClick={() => {
                                  setIsOpenModalUsers(!isOpenModalUsers),
                                    setSelectUserFromDelete(x);
                                }}
                              >
                                <svg
                                  className="icon icon-white bg-primary rounded-circle"
                                  style={{
                                    width: "35px",
                                    height: "35px",
                                    cursor: "pointer",
                                  }}
                                >
                                  <use href={`${iconSprite}#it-close-big`} />
                                </svg>
                              </div>
                            )}

                            {!props.hideUserIcon && (
                              <a id={ids[2]}>
                                <svg
                                  className="icon icon-white bg-info rounded-circle ml-3"
                                  style={{
                                    width: "36px",
                                    height: "36px",
                                    padding: "4px",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => {
                                    toggleModal(!isOpen), setSelectUser(x);
                                  }}
                                >
                                  <use href={`${iconSprite}#it-pencil`} />
                                </svg>
                              </a>
                            )}
                          </div>

                          <UncontrolledTooltip placement="top" target={ids[0]}>
                            Conferma Utente
                          </UncontrolledTooltip>
                          <UncontrolledTooltip placement="top" target={ids[1]}>
                            Elimina Richiesta
                          </UncontrolledTooltip>
                          <UncontrolledTooltip placement="top" target={ids[2]}>
                            Modifica Utente
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-lg-3  mt-2">
                        <b className="mr-1"> Codice Fiscale:</b>{" "}
                        {x.codiceFiscale ? x.codiceFiscale : "/"}
                      </div>

                      <div className="col-sm-6 col-lg-2  mt-2 ">
                        <b className="mr-1"> Residenza: </b>
                        {x.luogoResidenza ? x.luogoResidenza : "/"}
                      </div>

                      <div className="col-sm-6 col-lg-4  mt-2 ">
                        <b className="mr-2"> Email: </b>
                        {x.email ? x.email : "/"}
                      </div>

                      <div className="col-sm-6 col-lg-3  mt-2">
                        <b className="mr-1"> Telefono: </b>
                        {x.telefono ? x.telefono : "/"}
                      </div>
                    </div>

                    <div className="row"></div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="row">
              <div className="text-center">
                <b className="mx-2">
                  Non sono presenti utenti {props.textUser}
                </b>
              </div>
            </div>
          )}

          {/* <div className="text-right">
            <b>Totale: {props.totElement && props.totElement}</b>
          </div> */}
          <br />
        </div>
      </div>
      <div>
        <Modal isOpen={isOpen} toggle={() => toggleModal(!isOpen)} size={"lg"}>
          <ModalBody>
            <div className="row">
              <div className="col-12 text-right">
                <svg
                  className="icon icon-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleModal(!isOpen)}
                >
                  <use href={`${iconSprite}#it-close-big`} />
                </svg>
              </div>
            </div>
            <ViewUserProfile
              user={false}
              displayMode={true}
              profile={selectUser}
              editMethod={editSend}
              sendMail={true}
              qualifySelect={qualifySelect}
              institutionSelect={institutionSelect}
            ></ViewUserProfile>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={isOpenModalUsers}
          toggle={() => setIsOpenModalUsers(!isOpenModalUsers)}
          size={"lg"}
        >
          <ModalHeader
            toggle={() => setIsOpenModalUsers(!isOpenModalUsers)}
            id="essempio9"
          >
            Eliminazione iscritto
          </ModalHeader>

          <ModalBody>
            <p>
              {props.customMessage
                ? props.customMessage
                : "Stai eliminando elemento, sei sicuro di voler procedere ?"}{" "}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                props.setDeleteMethod(selectUserFromDelete),
                  setIsOpenModalUsers(!isOpenModalUsers);
              }}
            >
              Si
            </Button>
            <Button
              color="secondary"
              onClick={() => setIsOpenModalUsers(!isOpenModalUsers)}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>

      <div>
        <Modal
          isOpen={isOpenConfirmation}
          toggle={() => toggleModalConfirmation(!isOpenConfirmation)}
          centered
          size={"lg"}
        >
          <ModalBody>
            <div className="row">
              <div className="col-10">
                <h4>
                  Vuoi Confermare l'iscrizione di{" "}
                  <u>
                    {selectUser && selectUser.cognome}{" "}
                    {selectUser && selectUser.nome}
                  </u>{" "}
                </h4>
              </div>

              <div className="col-2 text-right">
                <svg
                  className="icon icon-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleModalConfirmation(!isOpenConfirmation)}
                >
                  <use href={`${iconSprite}#it-close-big`} />
                </svg>
              </div>

              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        toggleModalConfirmation(!isOpenConfirmation),
                          props.checkMethod(selectUser);
                      }}
                    >
                      Conferma
                    </button>
                  </div>

                  <div className="col-6">
                    <button
                      onClick={() =>
                        toggleModalConfirmation(!isOpenConfirmation)
                      }
                      className="btn btn-primary"
                    >
                      Annulla
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default UsersList;
