import { useEffect, useState } from "react";
import Repo from "../Utility/Repo";
import ViewInstitution from "../Objects/ViewInstitution";
import { useLocation } from "react-router";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import ViewBooking from "../Objects/ViewBooking";

function PageBookingDetail(props) {
  const [apiToken, setApiToken] = useState(props.apiToken);
  const [isOpen, toggleModal] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  const [booking, setBooking] = useState();
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const paramsId = query.get("id");

  useEffect(() => {
    if (apiToken && paramsId) {
      Repo.getOneBooking(apiToken, paramsId)
        .then((response) => {
          if (response.status == 200) {
            setBooking(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, [props.operator]);

  const editSend = (request) => {
    if (apiToken && paramsId) {
      Repo.putInstitution(apiToken, JSON.stringify(request), paramsId)

        .then((response) => {
          window.location.href =
            props.subfolder + "/booking_detail?id=" + paramsId;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  const deleteBooking = () => {
    if (apiToken && paramsId) {
      Repo.deleteBooking(props.apiToken, paramsId)
        .then((response) => {
          window.location.href = props.subfolder + "/bookings_management";
        })
        .catch((error) => {
          console.log("error: ", error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  return (
    <>
      <ViewBooking
        editMethod={editSend}
        displayMode={true}
        deleteBooking={deleteBooking}
        booking={booking}
        apiToken={props.apiToken}
        subfolder={props.subfolder}
      ></ViewBooking>
    </>
  );
}

export default PageBookingDetail;
