import { useEffect, useState } from "react";
import { Input } from "design-react-kit";
import useLocalStorage from "../Utility/useLocalStorage";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
function InstitutionList(props) {
  return (
    <>
      <div className="row">
        <div className="col-12">
          {props.children && props.children.length > 0 ? (
            props.children.map((x, index) => (
              <div key={index}>
                <div className="card card-teaser roundedCustom shadow mb-2 col-12">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-lg-3 d-flex align-items-center">
                        <h5 className="card-title">
                          {x.nomeEnte ? x.nomeEnte : "Non Presente"}
                        </h5>
                      </div>
                      <div className="col-sm-6 col-lg-2 d-flex align-items-center">
                        {x.citta ? x.citta : "Non Presente"}
                      </div>
                      <div className="col-sm-6 col-lg-2 d-flex align-items-center">
                        {x.quotaPartecipazione && (
                          <>
                            Abitanti: <b className="ml-1">{x.numeroAbitanti}</b>
                          </>
                        )}
                      </div>
                      <div className="col-sm-6 col-lg-2 d-flex align-items-center">
                        {x.quotaPartecipazione && (
                          <>
                            Quota:{" "}
                            {x.quotaPartecipazione !== "string" ? (
                              <b className="ml-1">{x.quotaPartecipazione}</b>
                            ) : (
                              <b>NO</b>
                            )}
                          </>
                        )}
                      </div>
                      <div className="col-sm-6 col-lg-1 d-flex align-items-center">
                        {x.quotaPartecipazione && (
                          <>
                            Pagato:{" "}
                            <b className="ml-1">
                              {" "}
                              {x.isPagato ? <b>SI</b> : <b>NO</b>}
                            </b>
                          </>
                        )}
                      </div>
                      <div className="col-sm-12 col-lg-2">
                        <div className="text-right">
                          <Link
                            className="btn btn-outline-primary btn-icon"
                            to={"/institution_detail?id=" + x.enteID}
                          >
                            <svg className="icon icon-primary ">
                              <use href={`${iconSprite}#it-pencil`} />
                            </svg>
                            <span>Modifica</span>
                          </Link>
                        </div>
                      </div>
                    </div>

                    <div className="row"></div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <div colSpan="5" className="text-left">
                <b>Nessun Ente Presente</b>
              </div>
            </div>
          )}

          <br />
        </div>
      </div>
    </>
  );
}

export default InstitutionList;
