import { useEffect, useState } from "react";
import BookingsList from "../Objects/BookingsList";
import Repo from "../Utility/Repo";
import ReactPaginate from "react-paginate";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

function PageBooking(props) {
  const [items, setItems] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(5);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (props.apiToken) {
      Repo.GetAllBookings(props.apiToken)
        .then((response) => {
          setItems(response.data);
          setCurrentItems(items.slice(0, itemsPerPage));
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, [props.apiToken]);

  return (
    <>
      {props.apiToken && props.operator && (
        <>
          <div className="row mt-5 mb-3 p-2">
            <div className="col-10 text-left">
              <h4>Lista Pernottamenti</h4>
            </div>
          </div>

          <BookingsList>{currentItems}</BookingsList>

          {currentItems && currentItems.length !== 0 && pageCount > 1 && (
            <ReactPaginate
              previousLabel={
                <svg className="icon icon-primary">
                  <use xlinkHref={`${iconSprite}#it-chevron-left`}></use>
                </svg>
              }
              nextLabel={
                <svg className="icon icon-primary">
                  <use xlinkHref={`${iconSprite}#it-chevron-right`}></use>
                </svg>
              }
              breakLabel={"..."}
              pageCount={pageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={4}
              onPageChange={handlePageClick}
              containerClassName={"pagination justify-content-center col-12"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
              initialPage={0}
            />
          )}

          <div className="text-right">
            <b>Totale: </b>
            {items ? items.length : ""}
          </div>
        </>
      )}
    </>
  );
}

export default PageBooking;
