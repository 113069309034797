import SessionCourses from "../Objects/SessionCourses";
import { useEffect, useState } from "react";
import Repo from "../Utility/Repo";
import Spinner from "../Objects/Spinner";
import { Link } from "react-router-dom";
import { useLocation } from "react-router";
import iconCheckOk from "bootstrap-italia/dist/svg/sprite.svg";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function HomePage(props) {
  const [items, setItems] = useState([]);
  const [coursesAvailable, SetCoursesAvailable] = useState([]);
  const [loaded, setLoading] = useState(true);
  const [request, setRequest] = useState({});
  const [sessionsPerPage] = useState(3);
  //PAGAMENTO STRIPE
  const [isPayed, setIsPayed] = useState();
  const [isVerified, setIsVerified] = useState();
  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramsId = query.get("id");

  useEffect(() => {
    setLoading(!loaded);
  }, [coursesAvailable]);

  let queryP = useQuery();

  useEffect(() => {
    if (props.apiToken) {
      Repo.getMyCourses(props.apiToken)
        .then((response) => {
          if (response.status == 200) {
            //setItems(response.data);
            setItems(response.data.slice(0, sessionsPerPage));
          }
        })
        .catch((error) => {
          console.log(error);
        });

      Repo.postAllSessionAvailable(props.apiToken, JSON.stringify(request))
        .then((response) => {
          if (response.status == 200) {
            SetCoursesAvailable(response.data.slice(0, 3));
          }
        })
        .catch((error) => {
          console.log(error);
        });

      if (queryP.get("success")) {
        setIsPayed(true);
        if (queryP.get("success") == "False") {
          setIsVerified(false);
        }
        if (queryP.get("success") == "True") {
          setIsVerified(true);
        }
      }
    }
  }, []);

  return isPayed ? (
    <div className="main-body mt-2 mb-0 text-center">
      <div className="col-lg-12">
        <div className="card card-teaser rounded shadow">
          <div className="card-body p-3">
            {isVerified ? (
              <>
                <svg className="icon-success icon-xl">
                  <use href={`${iconCheckOk}#it-check-circle`} />
                </svg>
                <br />
                <br />
                <h3>Il pagamento è stato effettuato con successo</h3>
                <h4>
                  Riceverai una mail di conferma dell'ordine, potrebbe essere
                  necessario verificare all'interno dello spam
                </h4>
              </>
            ) : (
              <>
                <svg
                  className="icon-danger icon-xl"
                  style={{ paddingTop: "10%" }}
                >
                  <use href={`${iconCheckOk}#it-close-circle`} />
                </svg>
                <br />
                <br />
                <h3>Il pagamento non è andato a buon fine</h3>
              </>
            )}
            <br />
            <br />
            <Link
              to={"/session_course_detail?id=" + paramsId}
              className="btn btn-primary btn-sm ml-auto"
            >
              Torna al corso
            </Link>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <>
      <div className="row mb-4">
        <div className="col-8">
          <h3>I Miei Corsi</h3>
        </div>

        <div className="col-4 text-right">
          <Link
            to="/own_courses"
            type="button"
            className="btn btn-outline-primary"
          >
            Tutti i miei corsi
          </Link>
        </div>
      </div>
      <div className="row">
        <SessionCourses column={4} subscribe={true} apiToken={props.apiToken}>
          {items}
        </SessionCourses>
      </div>
      <Spinner loaded={loaded}></Spinner>

      <br />
      <div className="row">
        <div className="col-8">
          <h3 className="mb-4">Corsi disponibili</h3>
        </div>
        <div className="col-4 text-right">
          <Link
            type="button"
            to="/session_courses"
            className="btn btn-outline-primary mb-4"
          >
            Corsi Disponibili
          </Link>
        </div>
        <SessionCourses subscribe={false} column={4} apiToken={props.apiToken}>
          {coursesAvailable}
        </SessionCourses>
      </div>
    </>
  );
}

export default HomePage;
