import { useEffect, useState } from "react";
import SessionCourses from "../Objects/SessionCourses";
import Repo from "../Utility/Repo";
import { Link, useLocation } from "react-router-dom";
import iconCheckOk from "bootstrap-italia/dist/svg/sprite.svg";

function PageOperator(props) {
  const [pageCount, setPageCount] = useState(0);
  const [coursesItems, setCurrentItems] = useState(null);
  const [usersItems, setUsersItems] = useState(null);
  const [items, setItems] = useState([]);
  const [CoursesPerPage] = useState(3);
  const [itemsPerPage] = useState(5);
  const [itemOffset, setItemOffset] = useState(0);
  const [request, setRequest] = useState({});
  const [loaded, setLoading] = useState(true);

  const [isOpenConfirmation, toggleModalConfirmation] = useState(false);

  const [textUser, setTextUser] = useState(
    "con richieste di tesseramento in sospeso"
  );

  const [requestUser, setRequestUser] = useState({
    isTesserato: true,
    isVerificaTesserato: false,
  });

  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const meetingNumber = query.get("meetingNumber");

  const reloadApi = () => {
    if (props.apiToken) {
      Repo.postAllSession(props.apiToken, JSON.stringify(request))
        .then((response) => {
          if (response.status == 200) {
            setCurrentItems(response.data.slice(0, CoursesPerPage));
          }
        })
        .catch((error) => {
          console.log(error);
        });

      Repo.getUsers(props.apiToken, JSON.stringify(requestUser))
        .then((response) => {
          if (response.status == 200) {
            setItems(response.data.data);
            setUsersItems(items.slice(0, itemsPerPage));
          }
        })

        .catch((error) => {
          console.log(error);
        });
    }

    setLoading(true);
  };

  useEffect(() => {
    setLoading(false);
    reloadApi();
  }, [props.apiToken]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setUsersItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const setConfirmation = (user) => {
    Repo.getConfirmRegistration(props.apiToken, user.utenteID).then(
      (response) => {
        if (response.status == 200) {
          reloadApi();
          toggleModalConfirmation(false);
        }
      }
    );
  };

  useEffect(() => {
    if (meetingNumber) {
      Repo.setMeetingStatus(props.apiToken, meetingNumber, true)
        .then((response) => {})
        .catch((error) => {});
    }
  }, []);

  return meetingNumber ? (
    <>
      <div className="main-body mt-2 mb-0 text-center">
        <div className="col-lg-12">
          <div className="card card-teaser rounded shadow">
            <div className="card-body p-3">
              <svg className="icon-success icon-xl">
                <use href={`${iconCheckOk}#it-check-circle`} />
              </svg>
              <br />
              <br />
              <h3>La riunione è terminata!</h3>
              <br />
              <br />
              <Link to={"/"} className="btn btn-primary btn-sm ml-auto">
                Torna all'Home Page
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="row mb-0">
        <div className="col-8">
          <h3>Edizioni Corsi</h3>
        </div>
        <div className="col-lg-4 text-right">
          <Link
            to={"/session_courses"}
            className="btn  btn-outline-primary btn-icon"
          >
            <span>Visualizza tutte le edizioni</span>
          </Link>
        </div>
      </div>

      <br />
      <div className="row ">
        <SessionCourses column={4} apiToken={props.apiToken}>
          {coursesItems}
        </SessionCourses>
      </div>
      <br />
      {/* <div className="row mb-4">
        <div className="col-8">
          <h3>Richieste di tesseramento</h3>
        </div>
      </div>

      <Spinner loaded={loaded}></Spinner>

      <UsersList
        operator={true}
        totElement={usersItems ? usersItems.length : 0}
        reload={reloadApi}
        apiToken={props.apiToken}
        checkMethod={setConfirmation}
        textUser={textUser}
        hideCloseIcon={true}
      >
        {usersItems}
      </UsersList>

      {usersItems && usersItems.length !== 0 && (
        <ReactPaginate
          previousLabel={
            <svg className="icon icon-primary">
              <use xlinkHref={`${iconSprite}#it-chevron-left`}></use>
            </svg>
          }
          nextLabel={
            <svg className="icon icon-primary">
              <use xlinkHref={`${iconSprite}#it-chevron-right`}></use>
            </svg>
          }
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center col-12"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          initialPage={0}
        />
      )}

      <div className="text-right">
        <b>Richieste tesseramento in sospeso: </b>
        {items ? items.length : ""}
      </div> */}
    </>
  );
}

export default PageOperator;
