import { useEffect, useState } from "react";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";

import Select from "./Select";

function ViewInstitution(props) {
  const [validation, setValidation] = useState();
  const [enteName, setEnteName] = useState();
  const [cf, setCf] = useState();
  const [pIva, setPIva] = useState();
  const [fee, setFee] = useState();
  const [costFee, setCostFee] = useState();
  const [citizens, setCitizens] = useState();
  const [city, setCity] = useState();
  const [cap, setCap] = useState(false);
  const [street, setStreet] = useState();
  const [houseNumber, setHouseNumber] = useState();
  const [phone, setPhone] = useState();
  const [fax, setFax] = useState();
  const [webSite, setwebSite] = useState();
  const [email, setEmail] = useState();
  const [pec, setPec] = useState();
  const [payed, setPayed] = useState();
  const [qSelect, setQSelect] = useState();
  const [cfValidation, setCfValidation] = useState(true);
  const [pIvaValidation, setPIvaValidation] = useState();
  const [subscribers, setSubscribers] = useState(false);
  const [isOpen, toggleModal] = useState(false);

  const [selectTown, setSelectTown] = useState();

  const [editForce, setEditForce] = useState();

  const sendForm = () => {
    setValidation(true);

    const regexCF =
      /^[A-Za-z]{6}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{2}[A-Za-z]{1}[0-9LMNPQRSTUV]{3}[A-Za-z]{1}$/gm;
    const regexPIva = /^[0-9]{11}$/gm;
    const regexCFtest = /^[0-9A-Za-z]{11,16}$/gm;
    let passedValidation = true;

    if (!pIva) passedValidation = false;
    else {
      if (!pIva.match(regexPIva)) {
        setPIvaValidation(false);
        passedValidation = false;
      } else setPIvaValidation(true);
    }

    if (cf) {
      if (!cf.match(regexCFtest)) {
        setCfValidation(false);
        passedValidation = false;
      } else setCfValidation(true);
    }

    if (!enteName) passedValidation = false;
    if (!fee) passedValidation = false;
    if (!costFee) passedValidation = false;
    if (!citizens) passedValidation = false;
    if (!city) passedValidation = false;
    if (!cap) passedValidation = false;
    if (!street) passedValidation = false;
    if (!houseNumber) passedValidation = false;
    if (!phone) passedValidation = false;
    if (!email) passedValidation = false;

    if (passedValidation) {
      if (props.sendEnte) {
        var request = {
          enteID: props.institution.enteID,
          nomeEnte: enteName,
          partitaIva: pIva,
          codiceFiscale: cf,
          quotaPartecipazione: fee,
          costoPartecipazione: costFee,
          numeroAbitanti: citizens,
          citta: city,
          cap: cap,
          via: street,
          nCivico: houseNumber,
          telefono: phone,
          fax: fax,
          sitoWeb: webSite,
          pec: pec,
          email: email,
          isPagato: payed,
        };
      } else {
        var request = {
          nomeEnte: enteName,
          partitaIva: pIva,
          codiceFiscale: cf,
          quotaPartecipazione: fee,
          costoPartecipazione: costFee,
          numeroAbitanti: citizens,
          citta: city,
          cap: cap,
          via: street,
          nCivico: houseNumber,
          telefono: phone,
          fax: fax,
          sitoWeb: webSite,
          pec: pec,
          email: email,
          isPagato: payed,
        };
      }
      props.editMethod(request);
    }
  };

  const deleteInstitution = () => {
    props.deleteInst();
  };

  useEffect(() => {
    if (props.quoteSelect) {
      setQSelect(props.quoteSelect.data);
    }

    if (props.institution) {
      var data = props.institution;

      setEditForce(props.displayMode);
      if (data.data) {
        setEnteName(data.data.nomeEnte);
        setCf(data.data.codiceFiscale);
        setPIva(data.data.partitaIva);
        setFee(data.data.quotaPartecipazione);
        setCostFee(data.data.costoPartecipazione);
        setCitizens(data.data.numeroAbitanti);
        setCity(data.data.citta);
        setCap(data.data.cap);
        setStreet(data.data.via);
        setHouseNumber(data.data.nCivico);
        setPhone(data.data.telefono);
        setwebSite(data.data.sitoWeb);
        setFax(data.data.fax);
        setPec(data.data.pec);
        setEmail(data.data.email);
        setPayed(data.data.isPagato);
      }
    }
  }, [props]);

  const changeFee = (event) => {
    setFee(event.value);
  };

  const changePayed = (event) => {
    setPayed(event.value);
  };

  const obbjPayed = [
    { label: "SI", value: true },
    { label: "NO", value: false },
  ];

  return (
    <>
      <div className="col d-flex justify-content-center mt-3">
        <div className="card-body">
          <div className="needs-validation">
            <br />

            <div className="row ">
              <div className="col-lg-4">
                <Input
                  onChange={(e) => setEnteName(e.target.value)}
                  label="Nome Ente"
                  id="enteName"
                  name="enteName"
                  value={enteName ? enteName : ""}
                  {...(validation &&
                    !enteName && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation && !enteName && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  onChange={(e) => setCf(e.target.value)}
                  label="Codice Fiscale"
                  id="cf"
                  name="cf"
                  value={cf ? cf : ""}
                  {...(validation &&
                    !cfValidation && {
                      infoText: "Codice fiscale non corretto",
                    })}
                  disabled={editForce}
                  onChange={(e) => setCf(e.target.value)}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  onChange={(e) => setPIva(e.target.value)}
                  label="Partita Iva"
                  id="pIva"
                  name="pIva"
                  value={pIva ? pIva : ""}
                  {...(validation &&
                    !pIva && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation && !pIva && { className: "text-danger" })}
                  {...(validation &&
                    !pIvaValidation && { className: "text-danger" })}
                  {...(validation &&
                    !pIvaValidation && {
                      infoText: "Partita Iva non corretta",
                    })}
                  disabled={editForce}
                  onChange={(e) => setPIva(e.target.value)}
                />
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-lg-3">
                <Input
                  onChange={(e) =>
                    setCitizens(e.target.value.replace(/[^0-9.]/g, ""))
                  }
                  label="Numero Abitanti"
                  type="text"
                  name="citizens"
                  id="citizens"
                  value={citizens ? citizens : ""}
                  {...(validation &&
                    !citizens && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation && !citizens && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>
              <div className="col-lg-4 ">
                <div className="bootstrap-select-wrapper">
                  <label htmlFor="selectExampleDisabled">
                    Quota Partecipazione
                  </label>
                  <Select
                    label="Scegli una opzione"
                    clearText="Annulla"
                    icon={true}
                    options={qSelect}
                    defaultValue={fee}
                    id="fee"
                    onChange={changeFee}
                    isSearchable
                    placeholder={fee ? fee : "Scegli una opzione"}
                    isDisabled={editForce}
                  />

                  {validation && !fee ? (
                    <small id="errorSelect" className="form-text  text-danger">
                      Seleziona un campo
                    </small>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="col-lg-3 ">
                <Input
                  onChange={(e) => setCostFee(e.target.value)}
                  label="Costo Partecipazione"
                  name="costFee"
                  type="number"
                  id="costFee"
                  value={costFee ? costFee.toString() : ""}
                  {...(validation &&
                    !costFee && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation && !costFee && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>
              <div className="col-lg-2">
                <div className="bootstrap-select-wrapper">
                  <label htmlFor="selectExampleDisabled">Quota Pagata</label>
                  <Select
                    label="Scegli una opzione"
                    clearText="Annulla"
                    value={{
                      label: payed ? "SI" : "NO",
                      value: payed ? true : false,
                    }}
                    defaultMenuIsOpen={false}
                    icon={true}
                    options={obbjPayed}
                    id="payed"
                    onChange={changePayed}
                    isSearchable
                    isDisabled={editForce}
                  />
                </div>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-12 mb-2 ">
                <h6>Sede</h6>
              </div>

              <div className="col-lg-4">
                <Input
                  onChange={(e) => setCity(e.target.value)}
                  label="Città"
                  name="city"
                  id="city"
                  value={city ? city : ""}
                  {...(validation &&
                    !city && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation && !city && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>

              <div className="col-lg-2">
                <Input
                  onChange={(e) => setCap(e.target.value)}
                  label="CAP"
                  name="cap"
                  id="cap"
                  value={cap ? cap : ""}
                  {...(validation &&
                    !cap && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation && !cap && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>

              <div className="col-lg-4">
                <Input
                  onChange={(e) => setStreet(e.target.value)}
                  label="Via"
                  name="street"
                  id="street"
                  value={street ? street : ""}
                  {...(validation &&
                    !street && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation && !street && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>

              <div className="col-lg-2">
                <Input
                  onChange={(e) => setHouseNumber(e.target.value)}
                  label="N. Civico"
                  name="houseNumber"
                  id="houseNumber"
                  value={houseNumber ? houseNumber : ""}
                  {...(validation &&
                    !houseNumber && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation &&
                    !houseNumber && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12 mb-2 ">
                <h6>Recapiti</h6>
              </div>

              <div className="col-lg-3">
                <Input
                  onChange={(e) => setEmail(e.target.value)}
                  label="Email"
                  name="email"
                  id="email"
                  value={email ? email : ""}
                  {...(validation &&
                    !email && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation && !email && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>
              <div className="col-lg-3">
                <Input
                  onChange={(e) => setPec(e.target.value)}
                  label="Pec"
                  name="pec"
                  id="pec"
                  value={pec ? pec : ""}
                  disabled={editForce}
                />
              </div>

              <div className="col-lg-2">
                <Input
                  onChange={(e) => setFax(e.target.value)}
                  label="Fax"
                  name="fax"
                  id="fax"
                  value={fax ? fax : ""}
                  disabled={editForce}
                />
              </div>
              <div className="col-lg-2">
                <Input
                  onChange={(e) => setwebSite(e.target.value)}
                  label="Sito Web"
                  name="webSite"
                  id="webSite"
                  value={webSite ? webSite : ""}
                  disabled={editForce}
                />
              </div>
              <div className="col-lg-2">
                <Input
                  onChange={(e) => setPhone(e.target.value)}
                  label="Telefono"
                  name="phone"
                  id="phone"
                  value={phone ? phone : ""}
                  {...(validation &&
                    !phone && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation && !phone && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>
            </div>
            <div className="row">
              {editForce ? (
                <>
                  {" "}
                  <div className="col-10 text-right">
                    <button
                      type="button"
                      className="btn btn-primary "
                      onClick={() => setEditForce(!editForce)}
                    >
                      Modifica
                    </button>
                  </div>
                  <div className="col-2 text-right">
                    <button
                      type="button"
                      className="btn btn-danger"
                      onClick={() => toggleModal(!isOpen)}
                    >
                      Elimina
                    </button>
                  </div>
                </>
              ) : (
                <>
                  {" "}
                  <div className="col-12 text-right ">
                    <button
                      onClick={() => sendForm()}
                      className="btn btn-primary mx-5"
                    >
                      Salva
                    </button>

                    {!props.backButton && (
                      <button
                        type="submit"
                        className="btn btn-info text-white ml-5"
                        onClick={() => setEditForce(!editForce)}
                      >
                        Indietro
                      </button>
                    )}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal(!isOpen)}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">Attenzione</ModalHeader>
        <ModalBody>Vuoi procedere con l'eliminazione dell'ente?</ModalBody>
        <ModalFooter>
          <Button onClick={() => toggleModal(!isOpen)} color="primary">
            Chiudi
          </Button>
          <Button onClick={() => deleteInstitution()} color="danger">
            Elimina
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ViewInstitution;
