import { useEffect, useState, useRef } from "react";

import JoditEditor from "jodit-react";

function RichTextEditor(props) {
  const editor = useRef(null);
  const [content, setContent] = useState("");

  const config = {
    readonly: props.readonly ? props.readonly : false, // all options from https://xdsoft.net/jodit/doc/,
    minHeight: "400px",
    allowTabNavigation: true,
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };

  return (
    <JoditEditor
      ref={editor}
      value={props.contentDefault ? props.contentDefault : content}
      config={config}
      tabIndex={1} // tabIndex of textarea
      onBlur={(newContent) => props.setContentText(newContent)} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => {}}
    />
  );
}

export default RichTextEditor;
