import { useEffect, useState } from "react";

import {
  Input,
  Fade,
  Dimmer,
  DimmerButtons,
  Button,
  Card,
  CardBody,
  CardTitle,
  CardText,
  Form,
  FormGroup,
  Label,
  FormText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "design-react-kit";

import Repo from "../Utility/Repo";

import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

import { useLocation } from "react-router";
import UploadFile from "../Objects/UploadFile";
import RichTextEditor from "../Objects/RichTextEditor";
import SelectSubjects from "../Objects/SelectSubjects";
import SessionCourses from "../Objects/SessionCourses";
import ReactPaginate from "react-paginate";
import Spinner from "../Objects/Spinner";

function CourseDetail(props) {
  const [isOperator, setIsOperator] = useState(props.operator);

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramsId = query.get("id");
  const [course, setCourse] = useState({});
  const [uploadReceipt, setUploadReceipt] = useState();
  const [subject, setSubject] = useState();

  const [preSelectSubject, setPreSelectSubject] = useState();

  const [sessioniCorso, setSessioniCorso] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [itemsPerPage] = useState(3);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [loaded, setLoading] = useState(true);

  const [modifica, setModifica] = useState(false);

  const [name, setName] = useState();
  const [description, setDescription] = useState();

  const [formativeCredits, setFormativeCredits] = useState();
  const [isOpenConfirmation, toggleModalConfirmation] = useState(false);
  const [courseEditError, setCourseEditError] = useState(false);

  const [startValidate, setStartValidate] = useState(false);

  const [isOpen, toggleModal] = useState(false);
  const [errorApi, setErrorApi] = useState(false);

  function getBase64(file) {
    return new Promise(function (resolve) {
      var reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        resolve(reader.result);
      };
      reader.onerror = function (error) {
        console.log("Error: ", error);
      };
    });
  }

  const getFile = (id) => {
    if (props.operator) {
      Repo.getDownloadMaterial(props.apiToken, id)
        .then((response) => {
          if (response.status == 200) {
            var b64 = response.data.base64File;

            var a = window.document.createElement("a");

            a.href = `data:${response.data.tipoFile};base64,${b64}`;

            a.download = response.data.nomeFile;

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const loadFile = async () => {
    var tempRequest = {
      corsoID: paramsId,
    };

    var name = uploadReceipt.name.split(".")[0];
    var replaceName = name.replace(/[^\w\s]/gi, "");

    if (uploadReceipt) {
      var base64File = await getBase64(uploadReceipt);
      var splitBase64 = base64File.split(",");
      tempRequest["allegato"] = splitBase64[1];
      tempRequest["tipoFile"] = uploadReceipt.type;
      tempRequest["nome"] = replaceName;
    }

    if (props.operator) {
      Repo.postMaterial(props.apiToken, tempRequest)
        .then((response) => {
          window.location.reload();
        })

        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteFile = (id) => {
    if (props.operator) {
      Repo.deleteMaterial(props.apiToken, id)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  const reloadPage = () => {
    Repo.getOneCourse(props.apiToken, paramsId)
      .then((response) => {
        if (response.status == 200) {
          setCourse(response.data);
          setName(response.data.nome);
          setDescription(response.data.descrizione);
          setFormativeCredits(response.data.creditiFormativi);
          setSubject(response.data.materie.map((a) => a.materiaID));

          var labelTemp = [];

          response.data.materie.map((a) => {
            labelTemp.push({ label: a.nome, value: a.materiaID });
          });
          setPreSelectSubject(labelTemp);
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          props.deleteCoockieExpired();
        }
      });

    if (props.operator) {
      if (paramsId) {
        Repo.getSessioniByCorso(props.apiToken, paramsId)
          .then((response) => {
            setItemOffset(0);
            setSessioniCorso(response.data);
            setCurrentItems(response.data.slice(0, itemsPerPage));
          })
          .catch((error) => {
            console.log(error);
            if (error.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
      }
    }
  };

  useEffect(() => {
    setIsOperator(props.operator);
    if (props.apiToken && paramsId) {
      reloadPage();
    }
  }, [props.operator]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % sessioniCorso.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(sessioniCorso.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(sessioniCorso.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, sessioniCorso]);

  useEffect(() => {
    setLoading(!loaded);
  }, [sessioniCorso]);

  const deleteCourse = () => {
    if (props.apiToken) {
      Repo.deleteCourse(props.apiToken, paramsId)
        .then((response) => {
          if (response.data.statusCode == 200) {
            window.location.href = props.subfolder + "/courses";
          } else if (response.data.statusCode == 400) {
            setErrorApi(response.data.userMessage);
            toggleModal(!isOpen);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  useEffect(() => {
    if (courseEditError) {
      const timer = setTimeout(() => {
        setCourseEditError(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [courseEditError]);

  const sendCourse = (e) => {
    e.preventDefault();
    setStartValidate(true);

    var validatePassed = true;

    if (!name) validatePassed = false;
    if (subject.length < 1) validatePassed = false;

    if (!description) validatePassed = false;
    if (!formativeCredits) validatePassed = false;

    if (validatePassed) {
      var tempRequest = {
        nome: name,
        creditiFormativi: formativeCredits,
        descrizione: description,
        materieList: subject,
      };
      toggleModalConfirmation(true);
      if (props.apiToken) {
        Repo.putCourse(props.apiToken, JSON.stringify(tempRequest), paramsId)
          .then((response) => {
            window.location.reload();
          })
          .catch((error) => {
            setCourseEditError(true);
            console.log(error);
            if (error.response.status == 401) {
              props.deleteCoockieExpired();
            }
          });
      }
    }
  };

  return (
    <>
      <Spinner loaded={loaded}></Spinner>
      <div className="row  ">
        {modifica ? (
          <div className="col-9 mb-4">
            <Input
              id="name"
              name="name"
              onChange={(e) => setName(e.target.value)}
              required
              value={name ? name : course.nome}
              {...(startValidate &&
                !name && {
                  infoText: "Il campo non può essere vuoto!",
                })}
              {...(startValidate && !name && { className: "text-danger" })}
            />
          </div>
        ) : (
          <div className="col-9 mb-4">
            <h3>{course.nome}</h3>
          </div>
        )}

        {modifica ? (
          <div className="col-3">
            <div className="row">
              <div className="col-6 ">
                <button
                  className="btn btn-info text-white "
                  onClick={() => setModifica(!modifica)}
                >
                  Indietro
                </button>
              </div>
              <div className="col-6 pr-5">
                <button className="btn btn-success" onClick={sendCourse}>
                  Salva
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-3">
            <div className="row">
              <div className="col-6 ">
                <button
                  className="btn btn-success"
                  onClick={() => setModifica(!modifica)}
                >
                  Modifica
                </button>
              </div>
              <div className="col-6 pr-5">
                <button
                  onClick={() => deleteCourse()}
                  className="btn btn-danger"
                >
                  Elimina
                </button>
              </div>
            </div>
          </div>
        )}

        <div className="card card-teaser roundedCustom shadow">
          <div className="card-body row">
            {modifica ? (
              <div className="col-6 mt-4">
                <b className="col-9 mb-4">Crediti Formativi:</b>{" "}
                <Input
                  id="formativeCredits"
                  name="formativeCredits"
                  placeholder={"0"}
                  value={course.formativeCredits && formativeCredits}
                  type="number"
                  min={0}
                  max={100}
                  // value={formativeCredits ? formativeCredits.toString() : ""}
                  {...(startValidate &&
                    !formativeCredits && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(startValidate &&
                    !formativeCredits && { className: "text-danger" })}
                  onChange={(e) => setFormativeCredits(e.target.value)}
                />
              </div>
            ) : (
              <div className="col-3 mt-4">
                <b>Crediti Formativi:</b> {course.creditiFormativi}
              </div>
            )}

            {modifica ? (
              <>
                {" "}
                <div className="col-6 mt-4 bootstrap-select-wrapper">
                  <b className="col-9 mb-4">Materie:</b>
                  <SelectSubjects
                    setValue={setSubject}
                    defaulValue={preSelectSubject}
                  ></SelectSubjects>

                  {startValidate && subject.length < 1 ? (
                    <small id="errorSelect" className="form-text  text-danger">
                      Seleziona almeno campo
                    </small>
                  ) : (
                    <></>
                  )}
                </div>
              </>
            ) : (
              <>
                {" "}
                <div className="col-9 mt-4">
                  {course.materie &&
                    course.materie.length > 0 &&
                    course.materie.map((x, index) => (
                      <button
                        className="btn btn-outline-success btn-xs mx-1"
                        key={index}
                      >
                        {x.nome}
                      </button>
                    ))}
                </div>
              </>
            )}

            <div
              className="mt-4 col-12  "
              style={{ bmaxHeight: "450px", overflow: "auto" }}
            >
              <b>Descrizione</b>
              <div className="col-12  mt-4 ">
                {modifica ? (
                  <>
                    <RichTextEditor
                      setContentText={setDescription}
                      contentDefault={course.descrizione}
                    ></RichTextEditor>

                    {startValidate && !description ? (
                      <small
                        id="errorSelect"
                        className="form-text  text-danger"
                      >
                        Inserisci una descrizione
                      </small>
                    ) : (
                      <></>
                    )}
                  </>
                ) : (
                  <p dangerouslySetInnerHTML={{ __html: course.descrizione }} />
                )}
              </div>
            </div>
          </div>
        </div>

        <div className="card card-teaser roundedCustom shadow mt-4">
          <div className="card-body row">
            <div className="col-12 mb-4">
              <h3>Materiale</h3>
            </div>

            {course.materialiDidattici &&
            course.materialiDidattici.length > 0 ? (
              <>
                <div className="col-7 mt-4 mb-4 ">
                  <table className="table">
                    <thead>
                      <tr>
                        <th scope="col">File</th>
                        <th scope="col">Scarica</th>
                        <th scope="col">Elimina</th>
                      </tr>
                    </thead>
                    <tbody>
                      {course.materialiDidattici &&
                        course.materialiDidattici.length > 0 &&
                        course.materialiDidattici.map((x, index) => (
                          <tr key={index}>
                            <td style={{ verticalAlign: "middle" }}>
                              {x.nome}
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <button
                                className="btn btn-primary"
                                onClick={() => getFile(x.materialeDidatticoID)}
                              >
                                Scarica
                              </button>
                            </td>
                            <td style={{ verticalAlign: "middle" }}>
                              <svg
                                className="icon icon-danger"
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  deleteFile(x.materialeDidatticoID)
                                }
                              >
                                <use href={`${iconSprite}#it-close`} />
                              </svg>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </>
            ) : (
              <div className="col-7 mt-4 mb-4 ">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">File</th>
                      <th scope="col">Scarica</th>
                      <th scope="col">Elimina</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="3" className="text-center">
                        {" "}
                        Non ci sono materiali
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            )}

            <div className="mt-4 col-5  text-right">
              <div className="col-12  ">
                <UploadFile
                  handleFile={setUploadReceipt}
                  position={"text-right"}
                  testoInterno={"Carica nuovo materiale"}
                ></UploadFile>
              </div>

              {uploadReceipt && (
                <div className="col-12 text-right mt-3 ">
                  <button
                    className="btn btn-primary"
                    onClick={() => loadFile()}
                  >
                    Salva
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="col-9 mb-4 mt-5">
          <h3>Edizioni Correlate</h3>
        </div>
        {sessioniCorso && sessioniCorso.length > 0 ? (
          <>
            <SessionCourses
              column={4}
              subscribe={true}
              apiToken={props.apiToken}
              operator={props.operator}
            >
              {currentItems}
            </SessionCourses>
            {currentItems && currentItems.length !== 0 && pageCount > 1 && (
              <ReactPaginate
                previousLabel={
                  <svg className="icon icon-primary">
                    <use xlinkHref={`${iconSprite}#it-chevron-left`}></use>
                  </svg>
                }
                nextLabel={
                  <svg className="icon icon-primary">
                    <use xlinkHref={`${iconSprite}#it-chevron-right`}></use>
                  </svg>
                }
                breakLabel={"..."}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={4}
                onPageChange={handlePageClick}
                containerClassName={"pagination justify-content-center col-12"}
                pageClassName={"page-item"}
                pageLinkClassName={"page-link"}
                previousClassName={"page-item"}
                previousLinkClassName={"page-link"}
                nextClassName={"page-item"}
                nextLinkClassName={"page-link"}
                breakClassName={"page-item"}
                breakLinkClassName={"page-link"}
                activeClassName={"active"}
                initialPage={0}
              />
            )}
          </>
        ) : (
          <>
            <div className="col-9">
              <h5>Non ci sono sessioni correlate</h5>
            </div>
          </>
        )}
      </div>
      {courseEditError && (
        <>
          <div
            className="alert alert-danger"
            role="alert"
            style={{
              position: "fixed",
              backgroundColor: "white",
              bottom: "0px",
              right: "5px",
              zIndex: "100",
            }}
          >
            <b>Non è possibile modificare il corso selezionato.</b>
          </div>
        </>
      )}
      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal(!isOpen)}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">ERRORE</ModalHeader>
        <ModalBody>{errorApi}</ModalBody>
        <ModalFooter>
          <Button onClick={() => toggleModal(!isOpen)} color="primary">
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}
export default CourseDetail;
