import React, { useEffect, useState } from "react";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";
import moment from "moment";

import {
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";

function SessionCourses(props) {
  const [isOpen, toggleModal] = useState(false);
  const [apiToken, SetApiToken] = useState(props.apiToken && props.apiToken);
  const [currentDate, setCurrentDate] = useState(moment().format("DD/MM/YYYY"));

  return (
    <>
      {props.children && props.children.length > 0 ? (
        props.children.map((obbj, index) => (
          <React.Fragment key={index}>
            <div className="col-12 col-lg-4">
              <div className="card-wrapper">
                <div className="card card-bg roundedCustom shadow secondary-border-color border no-after">
                  <div
                    className="head-tags justify-content-end"
                    style={{ marginBottom: "10px", marginTop: "10px" }}
                  >
                    {obbj.nomiMaterie &&
                      obbj.nomiMaterie.length > 0 &&
                      obbj.nomiMaterie.map((x, index) => (
                        <div
                          className="btn btn-xs btn-outline-primary mr-2 float-right"
                          style={{ cursor: "auto" }}
                          key={index}
                        >
                          {x}
                        </div>
                      ))}
                  </div>
                  <div className="card card-img no-after mt-2">
                    <div className="img-responsive-wrapper">
                      <div className="img-responsive d-flex justify-content-center">
                        {obbj.isTerminato ? (
                          <>
                            <figure className="img-wrapper">
                              <img
                                src={
                                  obbj.copertina
                                    ? "data:" +
                                      obbj.tipoCopertina +
                                      ";base64," +
                                      obbj.copertina
                                    : "https://semplicitalearning.it/img/corso.png"
                                }
                                title="titolo immagine"
                                alt="descrizione immagine"
                              ></img>
                              <figcaption
                                className="overlay-panel overlay-panel-fullheight overlay-black pb-0"
                                style={{ backgroundColor: "rgba(0,0,0,.72)" }}
                              >
                                <svg className="icon icon-white icon-lg">
                                  <use
                                    xlinkHref={`${iconSprite}#it-locked`}
                                  ></use>
                                </svg>
                                <span
                                  className="pb-2"
                                  style={{ fontSize: "20px" }}
                                >
                                  Iscrizioni chiuse
                                </span>
                              </figcaption>
                            </figure>
                          </>
                        ) : (
                          <>
                            {moment(
                              obbj.chiusuraPrenotazione,
                              "DD/MM/YYYY"
                            ).isBefore(moment(currentDate, "DD/MM/YYYY")) ? (
                              <>
                                <figure className="img-wrapper">
                                  <img
                                    src={
                                      obbj.copertina
                                        ? "data:" +
                                          obbj.tipoCopertina +
                                          ";base64," +
                                          obbj.copertina
                                        : "https://semplicitalearning.it/img/corso.png"
                                    }
                                    title="titolo immagine"
                                    alt="descrizione immagine"
                                  ></img>
                                  <figcaption
                                    className="overlay-panel overlay-panel-fullheight overlay-black pb-0"
                                    style={{
                                      backgroundColor: "rgba(0,0,0,.72)",
                                    }}
                                  >
                                    <svg className="icon icon-white icon-lg">
                                      <use
                                        xlinkHref={`${iconSprite}#it-locked`}
                                      ></use>
                                    </svg>
                                    <span
                                      className="pb-2"
                                      style={{ fontSize: "20px" }}
                                    >
                                      Iscrizioni chiuse
                                    </span>
                                  </figcaption>
                                </figure>
                              </>
                            ) : (
                              <>
                                <figure className="img-wrapper">
                                  <img
                                    src={
                                      obbj.copertina
                                        ? "data:" +
                                          obbj.tipoCopertina +
                                          ";base64," +
                                          obbj.copertina
                                        : "https://semplicitalearning.it/img/corso.png"
                                    }
                                    title="titolo immagine"
                                    alt="descrizione immagine"
                                  ></img>
                                </figure>
                              </>
                            )}
                          </>
                        )}
                        {/* {moment(
                          obbj.chiusuraPrenotazione,
                          "DD/MM/YYYY"
                        ).isBefore(moment(currentDate, "DD/MM/YYYY")) ||
                        obbj.isTerminato ? (
                          <figure className="img-wrapper">
                            <img
                              src={
                                obbj.copertina
                                  ? "data:" +
                                    obbj.tipoCopertina +
                                    ";base64," +
                                    obbj.copertina
                                  : "https://semplicitalearning.it/img/corso.png"
                              }
                              title="titolo immagine"
                              alt="descrizione immagine"
                            ></img>
                            <figcaption
                              className="overlay-panel overlay-panel-fullheight overlay-black pb-0"
                              style={{ backgroundColor: "rgba(0,0,0,.72)" }}
                            >
                              <svg className="icon icon-white icon-lg">
                                <use
                                  xlinkHref={`${iconSprite}#it-locked`}
                                ></use>
                              </svg>
                              <span
                                className="pb-2"
                                style={{ fontSize: "20px" }}
                              >
                                Iscrizioni chiuse
                              </span>
                            </figcaption>
                          </figure>
                        ) : (
                          <figure className="img-wrapper">
                            <img
                              src={
                                obbj.copertina
                                  ? "data:" +
                                    obbj.tipoCopertina +
                                    ";base64," +
                                    obbj.copertina
                                  : "https://semplicitalearning.it/img/corso.png"
                              }
                              title="titolo immagine"
                              alt="descrizione immagine"
                            ></img>
                          </figure>
                        )} */}
                        <div className="card-calendar d-flex flex-column justify-content-center priceCustom">
                          {obbj.prezzo == 0 || obbj.prezzo == null ? (
                            <span className="card-day">Gratuito</span>
                          ) : (
                            <>A Pagamento</>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="card-body d-flex flex-column">
                    <div className="row">
                      <div
                        className="col-lg-12 text-center mb-3"
                        style={{
                          border: "solid 2px #06c",
                          borderRadius: "20px 20px 20px 20px",
                          color: "#435a70",
                        }}
                      >
                        <b>Posti disponibili</b>:{" "}
                        {obbj.maxUtenti - obbj.utentiIscritti}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-12">
                        {" "}
                        <h3
                          className="card-title h5 mb-1"
                          style={{ fontWeight: "600" }}
                        >
                          {obbj.edizione}
                        </h3>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-3">Corso:</div>
                      <div className="col-lg-9">
                        <b>{obbj.nome}</b>
                      </div>
                    </div>
                    <div
                      className="card-text mt-4"
                      style={{ fontSize: "17px" }}
                    >
                      <div className="row">
                        <div className="col-lg-12">
                          Da: <b className="px-1">{obbj.dataInizio}</b> a:{" "}
                          <b className="px-1">{obbj.dataFine}</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-5">Crediti:</div>
                        <div className="col-lg-7">
                          <b>{obbj.creditiFormativi}</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-5">Località:</div>
                        <div className="col-lg-7">
                          <b>{obbj.localita}</b>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-5">In presenza:</div>
                        <div className="col-lg-7">
                          {obbj && obbj.isPresenza ? <b>Si</b> : <b>No</b>}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-5">Posti Totali:</div>
                        <div className="col-lg-7">
                          <b>{obbj.maxUtenti}</b>
                        </div>
                      </div>
                    </div>

                    <div className="it-card-footer mt-auto d-flex">
                      {!props.showResult && (
                        <>
                          {!props.operator && (
                            <>
                              {props.subscribe && obbj.isTerminato && (
                                <>
                                  {obbj.isSuperato ? (
                                    <b className="text-success">Superato</b>
                                  ) : (
                                    <b className="text-danger">Non superato</b>
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      )}

                      {apiToken && apiToken ? (
                        <Link
                          to={
                            "/session_course_detail?id=" + obbj.sessioneCorsoID
                          }
                          className="btn btn-primary btn-sm ml-auto"
                        >
                          Dettaglio
                        </Link>
                      ) : (
                        <Button
                          color="primary"
                          onClick={() => toggleModal(!isOpen)}
                          className="ml-auto"
                        >
                          Dettaglio
                        </Button>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
          // <div
          //   className={`col-lg-${
          //     props.column ? props.column : 4
          //   } card-wrapper card-space`}
          //   key={index}
          // >
          //   <div
          //     className="card card-bg roundedCustom shadow secondary-border-color border no-after"
          //     style={{ minWidth: "280px", height: "fit-content" }}
          //   >
          //     <div className="text-right mt-2">
          //       {obbj.nomiMaterie &&
          //         obbj.nomiMaterie.length > 0 &&
          //         obbj.nomiMaterie.map((x, index) => (
          //           <div
          //             className="btn btn-xs btn-outline-primary mr-2"
          //             style={{ cursor: "auto" }}
          //             key={index}
          //           >
          //             {x}
          //           </div>
          //         ))}
          //     </div>
          //     <div className="card card-img no-after mt-2">
          //       <div className="img-responsive-wrapper">
          //         <div className="img-responsive">
          //           {obbj.copertina ? (
          //             <figure className="img-wrapper">
          //               <img
          //                 src={
          //                   "data:" +
          //                   obbj.tipoCopertina +
          //                   ";base64," +
          //                   obbj.copertina
          //                 }
          //                 title="titolo immagine"
          //                 alt="descrizione immagine"
          //               ></img>
          //             </figure>
          //           ) : (
          //             <figure className="img-wrapper">
          //               <img
          //                 src="https://semplicitalearning.it/img/corso.png"
          //                 title="titolo immagine"
          //                 alt="descrizione immagine"
          //               ></img>
          //             </figure>
          //           )}
          //           <div className="card-calendar d-flex flex-column justify-content-center priceCustom">
          //             {(obbj.prezzo && obbj.prezzo == 0) ||
          //             obbj.prezzo == null ? (
          //               <span className="card-day">Gratuito</span>
          //             ) : (
          //               <> A Pagamento</>
          //             )}
          //           </div>
          //         </div>
          //       </div>
          //     </div>
          //     <div
          //       className="card-body d-flex align-items-end flex-column py-2"
          //       style={{ minHeight: "320px", paddingRight: "12px" }}
          //     >
          //       <div
          //         className="col-12 mt-2 mb-2 text-center"
          //         style={{
          //           border: "solid 2px #06c",
          //           borderRadius: "20px 20px 20px 20px",
          //         }}
          //       >
          //         <b>Posti disponibili</b>:{" "}
          //         {obbj.maxUtenti - obbj.utentiIscritti}
          //       </div>
          //       <div className={"col-" + (props.subscribe ? 12 : 10) + " mt-2"}>
          //         <h3>{obbj.nome}</h3>
          //         <b className="mr-1">Edizione:</b>{" "}
          //         <p style={{ width: "289px" }}>{obbj.edizione}</p>
          //       </div>

          //       {!props.subscribe && (
          //         <>
          //           <div className="col-2">
          //             <h5 className="card-title">
          //               {moment(
          //                 obbj.chiusuraPrenotazione,
          //                 "DD/MM/YYYY"
          //               ).isBefore(moment(currentDate, "DD/MM/YYYY")) ? (
          //                 <>
          //                   <svg className="icon icon-primary icon-lg">
          //                     <use xlinkHref={`${iconSprite}#it-locked`}></use>
          //                   </svg>
          //                   <span
          //                     className="text-sm-center"
          //                     style={{ fontSize: "15px", color: "primary" }}
          //                   >
          //                     Chiuso
          //                   </span>
          //                 </>
          //               ) : (
          //                 <>
          //                   <svg className="icon icon-primary icon-lg">
          //                     <use
          //                       xlinkHref={`${iconSprite}#it-unlocked`}
          //                     ></use>
          //                   </svg>
          //                   <span
          //                     className="text-sm-center"
          //                     style={{ fontSize: "15px", color: "primary" }}
          //                   >
          //                     Aperto
          //                   </span>
          //                 </>
          //               )}
          //             </h5>
          //           </div>
          //         </>
          //       )}

          //       <div className="col-lg-6 mt-2">
          //         <b>Inizio</b>: {obbj.dataInizio}
          //       </div>
          //       <div className="col-lg-6 mt-2">
          //         <b>Fine</b>: {obbj.dataFine}
          //       </div>

          //       <div className="col-lg-6 mt-1">
          //         <b>Crediti</b>: {obbj.creditiFormativi}
          //       </div>

          //       <div className="col-lg-6 mt-1">
          //         <b>Località</b>: {obbj.localita}
          //       </div>
          //       <div className="col-lg-6 mt-1">
          //         {obbj && obbj.isPresenza ? (
          //           <b>In Presenza: Si</b>
          //         ) : (
          //           <b>In Presenza: No</b>
          //         )}
          //       </div>
          //       <div className="col-lg-6 mt-1">
          //         <b>Posti totali</b>: {obbj.maxUtenti}
          //       </div>

          //       <div className="row mb-2 mt-auto">
          //         {!props.showResult && (
          //           <>
          //             {!props.operator && (
          //               <>
          //                 {props.subscribe && obbj.isTerminato && (
          //                   <>
          //                     <div
          //                       className="col-8"
          //                       style={{
          //                         position: "absolute",
          //                         right: "29%",
          //                         bottom: "7%",
          //                       }}
          //                     >
          //                       {obbj.isSuperato ? (
          //                         <b className="text-success">Superato</b>
          //                       ) : (
          //                         <b className="text-danger">Non superato</b>
          //                       )}
          //                     </div>
          //                   </>
          //                 )}
          //               </>
          //             )}
          //           </>
          //         )}
          //         {apiToken && apiToken ? (
          //           <>
          //             <div className="col">
          //               <Link
          //                 to={
          //                   "/session_course_detail?id=" + obbj.sessioneCorsoID
          //                 }
          //                 className="btn btn-primary btn-sm float-right"
          //               >
          //                 Dettaglio
          //               </Link>
          //             </div>
          //           </>
          //         ) : (
          //           <>
          //             <div className="col text-right">
          //               <Button
          //                 color="primary"
          //                 onClick={() => toggleModal(!isOpen)}
          //               >
          //                 Dettaglio
          //               </Button>
          //             </div>
          //           </>
          //         )}
          //       </div>
          //     </div>
          //   </div>
          // </div>
        ))
      ) : (
        <div className="row ">
          <div className="col-12 text-center ">
            <h5 className="ml-3">Non sono presenti corsi</h5>
          </div>
        </div>
      )}

      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal(!isOpen)}
        labelledBy="esempio3"
      >
        <ModalHeader icon="it-info-circle" id="esempio3">
          Accesso non autorizzato
        </ModalHeader>
        <ModalBody>
          <p>
            Il dettaglio del corso può essere visualizzato solo dopo aver
            effettuato il Login.
          </p>
          <p>Clicca il bottone qui sotto per effettuare il Login</p>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={() => {
              toggleModal(!isOpen);
              window.location.href = props.subfolder + "/";
            }}
          >
            Login
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default SessionCourses;
