import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import { Link } from "react-router-dom";

function SubjectsList(props) {
  return (
    <>
      <div className="row">
        <div className="col-12">
          {props.children && props.children.length > 0 ? (
            props.children.map((x, index) => (
              <div key={index}>
                <div className="card card-teaser roundedCustom shadow mb-2 col-12">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-lg-5 d-flex align-items-center">
                        <h5 className="card-title">
                          {x.nome ? x.nome : "Non Presente"}
                        </h5>
                      </div>
                      <div className="col-sm-6 col-lg-5 d-flex align-items-center">
                        {x.descrizione && (
                          <>
                            <b>
                              {x.descrizione.length > 60 ? (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html:
                                      x.descrizione.toString().slice(0, 60) +
                                      "...",
                                  }}
                                />
                              ) : (
                                <p
                                  dangerouslySetInnerHTML={{
                                    __html: x.descrizione,
                                  }}
                                />
                              )}
                            </b>
                          </>
                        )}
                      </div>
                      <div className="col-sm-12 col-lg-2">
                        <div className="text-right">
                          <Link
                            className="btn btn-outline-primary btn-icon"
                            to={"/subject_detail?id=" + x.materiaID}
                          >
                            <svg className="icon icon-primary ">
                              <use href={`${iconSprite}#it-pencil`} />
                            </svg>
                            <span>Modifica</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <div colSpan="5" className="text-left">
                <b>Nessuna Materia Presente</b>
              </div>
            </div>
          )}
          <br />
        </div>
      </div>
    </>
  );
}

export default SubjectsList;
