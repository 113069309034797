import { useEffect, useState } from "react";
import { Input } from "design-react-kit";
import useLocalStorage from "../Utility/useLocalStorage";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import Repo from "../Utility/Repo";

function MaterialList(props) {
  const getFile = (id) => {
    if (props.apiToken) {
      Repo.getDownloadMaterial(props.apiToken, id)
        .then((response) => {
          if (response.status == 200) {
            var b64 = response.data.base64File;

            var a = window.document.createElement("a");

            a.href = `data:${response.data.tipoFile};base64,${b64}`;

            a.download = response.data.nomeFile;

            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const deleteFile = (id) => {
    if (props.operator) {
      Repo.deleteMaterial(props.apiToken, id)
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const loadFile = (obb) => {
    if (props.operator) {
      Repo.postMaterial(props.apiToken, obb)
        .then((response) => {})
        .catch((error) => {
          console.log(error);
        });
    }
  };

  return (
    <>
      <div className="row">
        {props.children && props.children.length > 0 ? (
          props.children.map((obbj, index) => (
            <div className="col-lg-4" key={index}>
              <div
                className="card card-teaser roundedCustom shadow mt-2  primary-border-color border p-3"
                style={{ minHeight: "130px" }}
              >
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-10" style={{ width: "250px" }}>
                      <h5 className="card-title">{obbj.nomeFile}</h5>
                    </div>

                    <div className="col-lg-2 text-right">
                      <svg
                        className="icon icon-primary"
                        style={{ cursor: "pointer" }}
                        onClick={() => getFile(obbj.materialeDidatticoID)}
                      >
                        <use href={`${iconSprite}#it-download`} />
                      </svg>

                      {props.operator && (
                        <svg
                          className="icon icon-danger"
                          style={{ cursor: "pointer" }}
                          onClick={() => deleteFile(obbj.materialeDidatticoID)}
                        >
                          <use href={`${iconSprite}#it-close-big`} />
                        </svg>
                      )}
                    </div>

                    <div className="col">{obbj.dataRilascio}</div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <>Non è presente materiale didattico</>
        )}
      </div>
    </>
  );
}

export default MaterialList;
