import "bootstrap-italia/dist/css/bootstrap-italia.min.css";
import "bootstrap-italia/dist/js/bootstrap-italia.bundle.min.js";
import useLocalStorage from "./Components/Utility/useLocalStorage";
// import { ZoomMtg } from "@zoomus/websdk";

import "./App.css";

import Repo from "./Components/Utility/Repo";
import { useEffect, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { useCookies } from "react-cookie";
import Header from "./Components/Layout/Header";
import { MemoizedFooter } from "./Components/Layout/Footer";
import Body from "./Components/Layout/Body";

function App() {
  const [cookies, setCookie] = useCookies();
  const [loginLocalStorage, setLoginLocalStorage, deleteLoginLocalStorage] =
    useLocalStorage("UserLoggedIn");
  const [apiToken, setApiToken] = useState();
  const [user, setUser] = useState();
  const [operator, setOperator] = useState();
  const [credits, setCredits] = useState();

  const [subfolder, setSubFolder] = useState(process.env.PUBLIC_URL);

  // ZoomMtg.preLoadWasm();
  // ZoomMtg.prepareWebSDK();
  // ZoomMtg.setZoomJSLib("https://source.zoom.us/2.4.0/lib", "/av");

  // ZoomMtg.i18n.load("it-IT");
  // ZoomMtg.i18n.reload("it-IT");

  // useEffect(() => {
  //   const zoomView = document.getElementById("zmmtg-root");
  //   zoomView.setAttribute("style", "display: None");
  // });

  const listaLinkUser = [
    { testo: "Home", url: "/" },
    { testo: "I miei corsi", url: "/own_courses" },
    { testo: "Corsi Disponibili", url: "/session_courses" },
  ];
  const listaLinkOperator = [
    { testo: "Home", url: "/" },
    { testo: "Gestione Utenti", url: "/user_management" },
    { testo: "Gestione Corsi", url: "/courses" },
    { testo: "Gestione Edizioni", url: "/session_courses" },
    { testo: "Gestione Materie", url: "/subjects_management" },
    // { testo: "Gestione Enti", url: "/institution_management" },
    // { testo: "Gestione Pernottamenti", url: "/bookings_management" },
  ];
  const listaLink = [
    { testo: "Home", url: "/" },
    { testo: "Corsi Disponibili", url: "/session_courses" },
  ];

  useEffect(() => {
    try {
      if (loginLocalStorage) {
        if (apiToken) {
          Repo.getAuthenticated(apiToken)
            .then((response) => {
              if (response.status !== 200) {
              }
            })
            .catch((error) => {
              deleteLoginLocalStorage();
              window.location.href = subfolder + "/";
              console.log(error);
            });
        }

        setUser(loginLocalStorage.utente);
        setCredits(loginLocalStorage.utente.crediti);
        setOperator(loginLocalStorage.roles.includes("Operator"));
        setApiToken(loginLocalStorage.accessToken);
      }
    } catch (error) {
      console.error(error);
      deleteLoginLocalStorage();
    }
  }, [apiToken]);

  const returnlink = () => {
    if (operator) {
      return listaLinkOperator;
    } else if (apiToken && !operator) {
      return listaLinkUser;
    } else {
      return listaLink;
    }
  };

  return (
    <div className="App">
      <Router {...(subfolder && { basename: subfolder })}>
        <div className="headerCustom">
          <Header
            user={user}
            isLogged={apiToken ? true : false}
            url="http://www.servizilocalispa.com/"
            Title="Servizi Locali Spa"
            operator={operator}
            linksUtente={listaLinkUser}
            links={returnlink()}
            linksOperator={listaLinkOperator}
            credits={credits}
            subfolder={subfolder ? subfolder : ""}
          />
        </div>

        <Body
          operator={operator}
          isLogged={apiToken ? true : false}
          user={user}
          apiToken={apiToken}
          subfolder={subfolder ? subfolder : ""}
        ></Body>

        <MemoizedFooter> </MemoizedFooter>
      </Router>
    </div>
  );
}

export default App;
