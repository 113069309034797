import { useEffect, useState } from "react";
import { Input } from "design-react-kit";
import ReactPaginate from "react-paginate";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import SessionCourses from "../Objects/SessionCourses";
import Repo from "../Utility/Repo";

function PageOwnCourses(props) {
  const [pageCount, setPageCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(0);
  const [countElement, setCountElement] = useState([]);
  const [itemsPerPage] = useState(3);
  const [currentItems, setCurrentItems] = useState(null);
  const [itemOffset, setItemOffset] = useState(0);
  const [items, setItems] = useState([]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage, items]);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    setItemOffset(newOffset);
  };

  const [request, setRequest] = useState({});

  useEffect(() => {
    if (props.apiToken) {
      Repo.getMyCourses(props.apiToken, JSON.stringify(request))
        .then((response) => {
          if (response.status == 200) {
            setItems(response.data);
            setCurrentItems(items.slice(0, itemsPerPage));
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, []);

  return (
    <div className="row">
      <SessionCourses column={4} subscribe={true} apiToken={props.apiToken}>
        {currentItems}
      </SessionCourses>

      {currentItems && currentItems.length !== 0 && pageCount > 1 && (
        <ReactPaginate
          previousLabel={
            <svg className="icon icon-primary">
              <use xlinkHref={`${iconSprite}#it-chevron-left`}></use>
            </svg>
          }
          nextLabel={
            <svg className="icon icon-primary">
              <use xlinkHref={`${iconSprite}#it-chevron-right`}></use>
            </svg>
          }
          breakLabel={"..."}
          pageCount={pageCount}
          marginPagesDisplayed={2}
          pageRangeDisplayed={4}
          onPageChange={handlePageClick}
          containerClassName={"pagination justify-content-center col-12"}
          pageClassName={"page-item"}
          pageLinkClassName={"page-link"}
          previousClassName={"page-item"}
          previousLinkClassName={"page-link"}
          nextClassName={"page-item"}
          nextLinkClassName={"page-link"}
          breakClassName={"page-item"}
          breakLinkClassName={"page-link"}
          activeClassName={"active"}
          initialPage={0}
        />
      )}
    </div>
  );
}

export default PageOwnCourses;
