import { useEffect, useState } from "react";
import Select from "../Objects/Select";

import Repo from "../Utility/Repo";

function Spinner(props) {


    return (<>

        {!props.loaded && <div className="col-12">
            <div className="trasparente">
                <div className="progress-spinner progress-spinner-active spinner">
                    <span className="sr-only">Caricamento...</span>
                </div>
            </div>
        </div>}

    </>

    );
}

export default Spinner;
