import { useState } from "react";
var CryptoJS = require("crypto-js");
// Usage

// Hook
export default function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {


      const item = window.localStorage.getItem(key);

      if (item) {
        var bytes = CryptoJS.AES.decrypt(item, 'secret key 123');
        var plaintext = bytes.toString(CryptoJS.enc.Utf8);

      }
      return item ? JSON.parse(plaintext) : initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue;
    }
  });
  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value;

      setStoredValue(valueToStore);
      var jsonString = JSON.stringify(valueToStore)
      var CryptoJS = require("crypto-js");

      var ciphertext = CryptoJS.AES.encrypt(jsonString, 'secret key 123');



      window.localStorage.setItem(key, ciphertext.toString());
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  const removeValue = () => {
    try {
      window.localStorage.removeItem(key);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };
  return [storedValue, setValue, removeValue];
}