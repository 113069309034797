import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

const Breadcrumb = (props) => {
  const [linkHistory, setLink] = useState([]);
  let location = useLocation();

  let { search } = useLocation();
  const query = new URLSearchParams(search);
  const paramsId = query.get("id");

  useEffect(() => {
    var tempHistory = [{ label: "Home", link: "" }];

    if (location.pathname.includes("institution_detail")) {
      tempHistory.push({ label: "Enti", link: "institution_management" });
      setLink(tempHistory);
    } else if (location.pathname.includes("session_course_detail")) {
      tempHistory.push({ label: "Edizioni", link: "session_courses" });
      setLink(tempHistory);
    } else if (location.pathname.includes("edit_session")) {
      tempHistory.push({ label: "Edizioni", link: "session_courses" });

      tempHistory.push({
        label: "Dettaglio Edizione",
        link: "session_course_detail?id=" + paramsId,
      });
      setLink(tempHistory);
    } else if (location.pathname.includes("subject_detai")) {
      tempHistory.push({
        label: "Gestione Materie",
        link: "subjects_management",
      });
      setLink(tempHistory);
    } else if (location.pathname.includes("new_course")) {
      tempHistory.push({ label: "Corsi", link: "courses" });
      setLink(tempHistory);
    } else if (location.pathname.includes("course_detail")) {
      tempHistory.push({ label: "Corsi", link: "course" });
      setLink(tempHistory);
    } else if (location.pathname.includes("/create_subject")) {
      tempHistory.push({
        label: "Gestione Materie",
        link: "/subjects_management",
      });
      setLink(tempHistory);
    } else if (location.pathname.includes("/new_session")) {
      tempHistory.push({ label: "Edizioni", link: "session_courses" });
      setLink(tempHistory);
    } else if (location.pathname.includes("/create_institution")) {
      tempHistory.push({
        label: "Gestione Enti",
        link: "/institution_management",
      });
      setLink(tempHistory);
    } else if (location.pathname == "/") {
      setLink();
    }
  }, [location.pathname]);
  return (
    <>
      {linkHistory && (
        <nav className="breadcrumb-container" aria-label="breadcrumb">
          <ol className="breadcrumb">
            {linkHistory.map((x, index) => (
              <li key={index} className="breadcrumb-item">
                <Link to={x.link} className="text-white">
                  {x.label}
                </Link>
                <span className="separator">/</span>
              </li>
            ))}
          </ol>
        </nav>
      )}
    </>
  );
};

export default Breadcrumb;
