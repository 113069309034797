import { useEffect, useState } from "react";
import useLocalStorage from "../Utility/useLocalStorage";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";
import ViewUserProfile from "./ViewUserProfile";
import moment from "moment";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
} from "design-react-kit";
import Repo from "../Utility/Repo";

function UsersListSession(props) {
  const [isOpen, toggleModal] = useState(false);
  const [isOpenModalUsers, setIsOpenModalUsers] = useState(false);
  const [isOpenConfirmation, toggleModalConfirmation] = useState(false);
  const [selectUserFromDelete, setSelectUserFromDelete] = useState();
  const [selectUser, setSelectUser] = useState();
  const [qualifySelect, setQualifySelect] = useState({});
  const [institutionSelect, SetInstitutionSelect] = useState({});
  const [qualifiche, setQualifiche] = useState("Qualifiche");
  const [institution, setInstitution] = useState("Enti");

  const ids = ["example1", "example2", "example3", "example4", "example5"];

  // Workaround for testing, do not use this
  ids.map((id, i) => {
    const div = document.createElement("div");
    div.setAttribute("id", id);
    document.body.appendChild(div);
    return null;
  });

  useEffect(() => {
    if (props.apiToken) {
      Repo.getSelect(qualifiche, props.apiToken)
        .then((response) => {
          if (response.status == 200) {
            setQualifySelect(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });

      Repo.getSelect(institution, props.apiToken)
        .then((response) => {
          if (response.status == 200) {
            SetInstitutionSelect(response.data.data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const editSend = (request, id) => {
    if (props.apiToken) {
      Repo.putUserAdmin(props.apiToken, JSON.stringify(request), id)

        .then((response) => {
          if (response.status == 200) {
            window.location.reload();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const reverseDate = (date) => {
    if (date) {
      var dateString = date; // Oct 23
      var dateMomentObject = moment(dateString, "DD/MM/YYYY"); // 1st argument - string, 2nd argument - format
      var dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object

      return dateObject;
    }

    return "";
  };

  const getStyle = (x) => {
    var styleReturn = {};
    if (!x.isSuperato && reverseDate(props.dataCloseSession) < new Date()) {
      styleReturn = {
        background:
          "linear-gradient(144deg, rgba(215,162,177,0.30015756302521013) 0%, rgba(255,255,255,1) 45%)",
      };
    } else if (x.isSuperato) {
      styleReturn = {
        background:
          "linear-gradient(144deg, rgba(162,181,215,0.30015756302521013) 0%, rgba(255,255,255,1) 45%)",
      };
    }
    return styleReturn;
  };

  return (
    <>
      {props.children && props.children.length > 0 ? (
        <div className="row py-4" style={{ height: "500px", overflow: "auto" }}>
          <div className="col-12">
            {props.children &&
              props.children.length > 0 &&
              props.children.map((x, index) => (
                <div key={index}>
                  <div
                    className="card card-teaser roundedCustom shadow mb-2 col-12"
                    style={getStyle(x)}
                  >
                    <div className="card-body">
                      <div className="row">
                        <div className="col-8 d-flex align-items-center">
                          <h4>
                            {x.cognome || x.nome
                              ? x.cognome + " " + x.nome
                              : "Non Presente"}
                          </h4>

                          {x.isSuperato && (
                            <h6 className="ml-4 text-primary">Superato</h6>
                          )}

                          {!x.isSuperato &&
                            reverseDate(props.dataCloseSession) <
                              new Date() && (
                              <h6 className="ml-4 text-danger">Non Superato</h6>
                            )}
                        </div>

                        <div className="col-4 text-right">
                          <div className="bd-example tooltip-demo">
                            <div className="muted">
                              {!x.isSuperato && !props.hideStartIcon && (
                                <a id={ids[0]}>
                                  <svg
                                    className="icon icon-white bg-success rounded-circle px-1 "
                                    style={{
                                      width: "35px",
                                      height: "35px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      props.starMethod(x);
                                    }}
                                  >
                                    <use href={`${iconSprite}#it-star-full`} />
                                  </svg>
                                </a>
                              )}

                              {!x.isSuperato &&
                                reverseDate(props.dataCloseSession) >
                                  new Date() &&
                                !props.isPagamento && (
                                  <>
                                    {!props.hideCloseIcon && (
                                      <div
                                        className="btn"
                                        id={ids[1]}
                                        onClick={() => {
                                          setIsOpenModalUsers(
                                            !isOpenModalUsers
                                          ),
                                            setSelectUserFromDelete(x);
                                        }}
                                      >
                                        <svg
                                          className="icon icon-white bg-primary rounded-circle px-1 ml-2"
                                          style={{
                                            width: "35px",
                                            height: "35px",
                                            cursor: "pointer",
                                          }}
                                        >
                                          <use
                                            href={`${iconSprite}#it-close-big`}
                                          />
                                        </svg>
                                      </div>
                                    )}
                                  </>
                                )}

                              {!props.hideUserIcon && (
                                <a id={ids[2]}>
                                  <svg
                                    className="icon icon-white bg-info rounded-circle px-1 ml-2"
                                    style={{
                                      width: "35px",
                                      height: "35px",
                                      cursor: "pointer",
                                    }}
                                    onClick={() => {
                                      toggleModal(!isOpen), setSelectUser(x);
                                    }}
                                  >
                                    <use href={`${iconSprite}#it-user`} />
                                  </svg>
                                </a>
                              )}
                            </div>

                            <UncontrolledTooltip
                              placement="top"
                              target={ids[0]}
                            >
                              Promuovi Utente
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target={ids[1]}
                            >
                              Elimina Iscrizione
                            </UncontrolledTooltip>
                            <UncontrolledTooltip
                              placement="top"
                              target={ids[2]}
                            >
                              Apri Scheda
                            </UncontrolledTooltip>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-lg-5 mt-2">
                          <b className="mr-1"> Codice Fiscale:</b>{" "}
                          {x.codiceFiscale && x.codiceFiscale}
                        </div>

                        <div className="col-sm-6 col-lg-7 mt-2 ">
                          <b className="mr-2"> Email: </b>
                          {x.email && x.email}
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-6 col-lg-5 mt-2 ">
                          <b className="mr-1"> Residenza: </b>
                          {x.luogoResidenza && x.luogoResidenza}
                        </div>
                        <div className="col-sm-6 col-lg-7 mt-2">
                          <b className="mr-1"> Telefono: </b>
                          {x.telefono && x.telefono}
                        </div>
                      </div>

                      <div className="row"></div>
                    </div>
                  </div>
                </div>
              ))}

            <br />
          </div>
        </div>
      ) : (
        <div className="row">
          <div className="text-center">
            <b className="mx-2">Non sono presenti utenti {props.textUser}</b>
          </div>
        </div>
      )}

      <div>
        <Modal isOpen={isOpen} toggle={() => toggleModal(!isOpen)} size={"lg"}>
          <ModalBody>
            <div className="row">
              <div className="col-12 text-right">
                <svg
                  className="icon icon-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleModal(!isOpen)}
                >
                  <use href={`${iconSprite}#it-close-big`} />
                </svg>
              </div>
            </div>
            <ViewUserProfile
              user={true}
              displayMode={true}
              profile={selectUser}
              editMethod={editSend}
              sendMail={true}
              qualifySelect={qualifySelect}
              institutionSelect={institutionSelect}
            ></ViewUserProfile>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={isOpenModalUsers}
          toggle={() => setIsOpenModalUsers(!isOpenModalUsers)}
          size={"lg"}
        >
          <ModalHeader
            toggle={() => setIsOpenModalUsers(!isOpenModalUsers)}
            id="essempio9"
          >
            Eliminazione iscritto
          </ModalHeader>

          <ModalBody>
            <p>
              {props.customMessage
                ? props.customMessage
                : "Stai eliminando elemento, sei sicuro di voler procedere ?"}{" "}
            </p>
          </ModalBody>
          <ModalFooter>
            <Button
              color="primary"
              onClick={() => {
                props.setDeleteMethod(selectUserFromDelete),
                  setIsOpenModalUsers(!isOpenModalUsers);
              }}
            >
              Si
            </Button>
            <Button
              color="secondary"
              onClick={() => setIsOpenModalUsers(!isOpenModalUsers)}
            >
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
      <div>
        <Modal
          isOpen={isOpenConfirmation}
          toggle={() => toggleModalConfirmation(!isOpenConfirmation)}
          centered
          size={"lg"}
        >
          <ModalBody>
            <div className="row">
              <div className="col-10">
                <h4>
                  Vuoi Confermare l'iscrizione di{" "}
                  <u>
                    {selectUser && selectUser.cognome}{" "}
                    {selectUser && selectUser.nome}
                  </u>{" "}
                </h4>
              </div>

              <div className="col-2 text-right">
                <svg
                  className="icon icon-primary"
                  style={{ cursor: "pointer" }}
                  onClick={() => toggleModalConfirmation(!isOpenConfirmation)}
                >
                  <use href={`${iconSprite}#it-close-big`} />
                </svg>
              </div>

              <div className="col-12 ">
                <div className="row">
                  <div className="col-6 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={() => {
                        toggleModalConfirmation(!isOpenConfirmation),
                          props.checkMethod(selectUser);
                      }}
                    >
                      Conferma
                    </button>
                  </div>

                  <div className="col-6">
                    <button
                      onClick={() =>
                        toggleModalConfirmation(!isOpenConfirmation)
                      }
                      className="btn btn-primary"
                    >
                      Annulla
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default UsersListSession;
