import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { Link } from "react-router-dom";

import Repo from "../Utility/Repo";

function PageConfirmedSignedUp(props) {
  let { search } = useLocation();
  const query = new URLSearchParams(search);

  const paramsId = query.get("userid");

  const token = query.get("token");

  const [result, setResult] = useState();

  const [data, setData] = useState();

  useEffect(() => {
    Repo.getConfirmedSigneUp(token, paramsId)
      .then((response) => {
        setData(response.data);
        setResult(true);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [paramsId, token]);

  return (
    <>
      <div className="row">
        {result ? (
          <div className="row col-12">
            <div className="col-9">
              <h1>{data && data.userMessage}</h1>
            </div>
            <div className="col-3">
              <Link className="btn btn-primary" to="/">
                Login
              </Link>
            </div>
          </div>
        ) : (
          <div className="row col-12">
            <div className="col-9">
              <h1>Qualcosa è andato storto</h1>
            </div>
          </div>
        )}
      </div>
    </>
  );
}

export default PageConfirmedSignedUp;
