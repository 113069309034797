import { useEffect, useState } from "react";
import Select from "../Objects/Select";

import Repo from "../Utility/Repo";

function SelectTemplate(props) {
  const [templateList, setTemplateList] = useState({});
  const [templateSelect, setTemplateSelect] = useState();
  const [template, setTemplate] = useState("Template");

  const changeQualification = (event) => {
    if (event) {
      setTemplateSelect(event.value);
      props.setValue(event.value);
    } else {
      setTemplateSelect();
      props.setValue();
    }
  };

  useEffect(() => {
    Repo.getSelect(template, props.apiToken)
      .then((response) => {
        if (response.status == 200) {
          setTemplateList(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.apiToken]);
  return (
    <Select
      label="Scegli una opzione"
      clearText="Annulla"
      defaultValue={props.defaultValue}
      defaultMenuIsOpen={false}
      icon={true}
      options={templateList}
      id="qualification"
      onChange={changeQualification}
      isSearchable
      placeholder={
        props.defaultValue
          ? props.defaultValue
          : "Seleziona il template del certificato"
      }
      isClearable={true}
    />
  );
}

export default SelectTemplate;
