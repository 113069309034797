import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Input } from "design-react-kit";
import iconSprite from "bootstrap-italia/dist/svg/sprite.svg";

function BookingsList(props) {
  return (
    <>
      <div className="row">
        <div className="col-12">
          {props.children && props.children.length > 0 ? (
            props.children.map((x, index) => (
              <div key={index}>
                <div className="card card-teaser roundedCustom shadow mb-2 col-12">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-sm-6 col-lg-5 d-flex align-items-center">
                        <h5 className="card-title">
                          {x.nomeUtente
                            ? "Pernottamento di " + x.nomeUtente
                            : "Non Presente"}
                        </h5>
                      </div>
                    </div>
                    <div className="row">
                      {x.dataPartenza && (
                        <>
                          <div className="col-lg-4 d-flex align-items-center">
                            <p>
                              Pernotto dal{" "}
                              <b className="ml-1 mr-1">{x.dataPartenza}</b> al{" "}
                              <b className="ml-1 mr-1">{x.dataRitorno}</b>
                            </p>
                          </div>
                          <div className="col-lg-1 d-flex align-items-center">
                            <p>
                              #Notti:{" "}
                              <b className="ml-1 mr-1">{x.numeroNotti}</b>
                            </p>
                          </div>
                          <div className="col-lg-2 d-flex align-items-center">
                            <p>
                              Camera{" "}
                              <b className="ml-1 mr-1">{x.tipologiaCamera}</b>
                            </p>
                          </div>
                          <div className="col-lg-5 d-flex align-items-center">
                            <p>
                              Prenotazione effettuata per il corso{" "}
                              <b className="ml-1 mr-1">{x.nomeCorso}</b> -{" "}
                              <b className="ml-1 mr-1"> {x.edizione}</b>
                            </p>
                          </div>
                        </>
                      )}
                    </div>
                    <div className="row">
                      <div className="col-lg-12 text-right">
                        <Link
                          to={"/booking_detail?id=" + x.bookingID}
                          className="btn btn-outline-primary"
                        >
                          Modifica
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <div colSpan="5" className="text-left">
                <b>Nessun Pernottamento Presente</b>
              </div>
            </div>
          )}
          <br />
        </div>
      </div>
    </>
  );
}

export default BookingsList;
