import { Link } from "react-router-dom";

function Courses(props) {
  return (
    <div className="col-12">
      {props.children && props.children.length > 0 ? (
        props.children.map((x, index) => (
          <div key={index}>
            <div className="card card-teaser roundedCustom shadow mb-2 col-12">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8 d-flex align-items-center">
                    <h4>{x.nome ? x.nome : "Non Presente"}</h4>
                  </div>{" "}
                  <div className="col-lg-4">
                    <Link
                      to={"/course_detail?id=" + x.corsoID}
                      className="btn btn-outline-primary float-right"
                    >
                      Dettaglio
                    </Link>
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6 col-lg-3  mt-2">
                    <b className="mr-1">Crediti Formativi:</b>{" "}
                    {x.creditiFormativi && x.creditiFormativi}
                  </div>

                  <div className="col-sm-6 col-lg-2 mt-2">
                    <b className="mr-1">Concluso: </b>
                    {x.isConcluso ? "SI" : "NO"}
                  </div>
                </div>
                <div className="row"> </div>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div className="row">
          <div className="text-center">
            <b className="ml-5">Non sono presenti corsi</b>
          </div>
        </div>
      )}

      {/* <div className="text-right">
        <b>Totale: {props.totElement && props.totElement}</b>
      </div> */}
      <br />
    </div>
  );
}

export default Courses;
