import { useEffect, useState } from "react";
import Select from "../Objects/Select";

import Repo from "../Utility/Repo";

function SelectSubjects(props) {
  const [subjectsList, setSubjects] = useState({});
  const [selectedSubjects, setSelectedSubjects] = useState();

  const [qualifiche, setQualifiche] = useState("Materie");

  const changeSubjects = (event) => {
    if (event) {
      var tempList = [];
      event.map((x) => {
        tempList.push(x.value);
      });
      setSelectedSubjects(tempList);
      props.setValue(tempList);
    } else {
      setSelectedSubjects([]);
      props.setValue([]);
    }
  };

  useEffect(() => {
    Repo.getSelect(qualifiche, props.apiToken)
      .then((response) => {
        if (response.status == 200) {
          setSubjects(response.data.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, [props.apiToken]);

  return (
    <Select
      label="Scegli una opzione"
      clearText="Annulla"
      defaultMenuIsOpen={false}
      icon={true}
      options={subjectsList}
      id="qualification"
      onChange={changeSubjects}
      isSearchable
      placeholder="Seleziona una o più materie"
      isClearable={true}
      isMulti={true}
      defaultValue={props.defaulValue}
      style={{ zIndex: "100" }}
    />
  );
}

export default SelectSubjects;
