import { useEffect, useState } from "react";
import Repo from "../Utility/Repo";
import ViewInstitution from "../Objects/ViewInstitution";
import { useLocation } from "react-router";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
function PageInstitutionDetail(props) {
  const [isOperator, setIsOperator] = useState(props.operator);
  const [institution, setInstitution] = useState({});
  const [quote, setQuote] = useState("Quote");
  const [quoteSelect, setQuoteSelect] = useState({});
  const [apiToken, setApiToken] = useState(props.apiToken);
  const [sendEnte, setSendEnte] = useState(true);
  const [isOpen, toggleModal] = useState(false);
  const [errorApi, setErrorApi] = useState(false);
  let { search } = useLocation();

  const query = new URLSearchParams(search);
  const paramsId = query.get("id");

  useEffect(() => {
    setIsOperator(props.operator);

    if (apiToken && paramsId) {
      Repo.getOneInstitution(apiToken, paramsId)
        .then((response) => {
          if (response.status == 200) {
            setInstitution(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });

      Repo.getSelect(quote, apiToken)

        .then((response) => {
          if (response.status == 200) {
            setQuoteSelect(response.data);
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  }, [props.operator]);

  const editSend = (request) => {
    if (apiToken && paramsId) {
      Repo.putInstitution(apiToken, JSON.stringify(request), paramsId)

        .then((response) => {
          window.location.href =
            props.subfolder + "/institution_detail?id=" + paramsId;
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  const deleteInst = () => {
    if (apiToken && paramsId) {
      Repo.deleteInstitution(apiToken, paramsId)
        .then((response) => {
          if (response.data.statusCode == 200) {
            window.location.href = props.subfolder + "/institution_management";
          } else if (response.data.statusCode == 500) {
            setErrorApi(response.data.userMessage);
            toggleModal(!isOpen);
          }
        })
        .catch((error) => {
          console.log("error: ", error);
          if (error.response.status == 401) {
            props.deleteCoockieExpired();
          }
        });
    }
  };

  return (
    <>
      {/* Filtri */}

      {/* Ente */}

      <ViewInstitution
        editMethod={editSend}
        displayMode={true}
        sendEnte={sendEnte}
        deleteInst={deleteInst}
        institution={institution}
        quoteSelect={quoteSelect}
      ></ViewInstitution>
      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal(!isOpen)}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">ERRORE</ModalHeader>
        <ModalBody>{errorApi}</ModalBody>
        <ModalFooter>
          <Button onClick={() => toggleModal(!isOpen)} color="primary">
            Chiudi
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default PageInstitutionDetail;
