import { useEffect, useState } from "react";
import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Repo from "../Utility/Repo";
import Notification from "./Notification";
import moment from "moment";

function ViewBooking(props) {
  const [dataPartenza, setDataPartenza] = useState();
  const [dataRitorno, setDataRitorno] = useState();
  const [tipologiaCamera, setTipologiaCamera] = useState();
  const [numeroNotti, setNumeroNotti] = useState();
  const [validation, setValidation] = useState();
  const [isOpenConfirmation, toggleModalConfirmation] = useState(false);
  const [bookingResult, setBookingResult] = useState(true);
  const [disabledDate, setDisabledDate] = useState(true);
  const [editForce, setEditForce] = useState();
  const [isOpen, toggleModal] = useState(false);

  useEffect(() => {
    if (props.booking) {
      var data = props.booking;

      setEditForce(props.displayMode);
      if (data) {
        setDataPartenza(data.dataPartenza);
        setDataRitorno(data.dataRitorno);
        setTipologiaCamera(data.tipologiaCamera);
        setNumeroNotti(data.numeroNotti);
      }
    }
  }, [props]);

  const changeDate = (event) => {
    let current_datetime = new Date(event.value);
    let formatted_date =
      current_datetime.getDate() +
      "/" +
      (current_datetime.getMonth() + 1) +
      "/" +
      current_datetime.getFullYear();

    switch (event.name) {
      case "dataPartenza":
        setDataPartenza(formatted_date);
        break;

      case "dataRitorno":
        setDataRitorno(formatted_date);
        break;
    }
  };

  const reverseDate = (date) => {
    if (date) {
      var dateString = date; // Oct 23
      var dateMomentObject = moment(dateString, "DD-MM-YYYY"); // 1st argument - string, 2nd argument - format
      var dateObject = dateMomentObject.toDate(); // convert moment.js object to Date object

      dateObject.setDate(dateObject.getDate() + 1);

      return dateObject.toISOString().slice(0, 10);
    }

    return "";
  };

  const deleteBooking = () => {
    props.deleteBooking();
  };

  const sendForm = () => {
    setValidation(true);
    let passedValidation = true;

    if (!dataPartenza) passedValidation = false;
    if (!dataRitorno) passedValidation = false;
    else {
      if (!dataPartenza) {
        passedValidation = false;
      }
    }

    if (!tipologiaCamera) passedValidation = false;
    if (!numeroNotti) passedValidation = false;

    if (passedValidation) {
      var request = {
        dataPartenza: dataPartenza,
        dataRitorno: dataRitorno,
        tipologiaCamera: tipologiaCamera,
        numeroNotti: numeroNotti,
        iscrizioneID: props.iscrizioneID,
      };
    }

    if (passedValidation) {
      if (props.iscrizioneID) {
        Repo.PostNewBooking(props.apiToken, JSON.stringify(request))
          .then((response) => {
            if (response.status == 201) {
              toggleModalConfirmation(true);
              setBookingResult(true);
              window.location.reload(true);
            }
          })
          .catch((error) => {
            console.log(error);
            setBookingResult(false);
            toggleModalConfirmation(true);
          });
      } else {
        Repo.PostEditBooking(
          props.apiToken,
          JSON.stringify(request),
          props.booking.bookingID
        )
          .then((response) => {
            if (response.status == 201) {
              window.location.href = props.subfolder + "/bookings_management";
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    } else {
      setBookingResult(false);
    }
  };

  useEffect(() => {
    if (dataPartenza) {
      setDisabledDate(false);
    }

    if (dataRitorno && dataPartenza) {
      var ritorno = moment(dataRitorno, "DD-MM-YYYY");

      var partenza = moment(dataPartenza, "DD-MM-YYYY");

      var duration = moment.duration(ritorno.diff(partenza));
      var days = duration.asDays();
      if (days > 0) {
        setNumeroNotti(days);
      } else {
        setNumeroNotti();
      }
    }
  }, [dataRitorno, dataPartenza]);

  return (
    <>
      <div className="col d-flex justify-content-center ">
        <div className="card-body pt-0">
          <div>
            <div className="col-8 mb-5">
              <h3>Dati Pernottamento </h3>
            </div>
            <br />
            <div className="row ">
              <div className="col-lg-4">
                <Input
                  id="dataPartenza "
                  label="Data Check-in"
                  name="dataPartenza"
                  type="date"
                  placeholder=" "
                  required
                  value={reverseDate(dataPartenza)}
                  {...(validation &&
                    !dataPartenza && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation &&
                    !dataPartenza && { className: "text-danger" })}
                  onChange={(e) => {
                    changeDate(e.target);
                  }}
                  disabled={editForce}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label="Data Check-out"
                  id="dataRitorno"
                  name="dataRitorno"
                  type="date"
                  placeholder=" "
                  required
                  value={reverseDate(dataRitorno)}
                  disabled={disabledDate}
                  {...(validation &&
                    !dataRitorno && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation &&
                    !dataRitorno && { className: "text-danger" })}
                  onChange={(e) => {
                    changeDate(e.target);
                  }}
                  disabled={editForce}
                />
              </div>
              <div className="col-lg-4">
                <Input
                  label="Num. Notti"
                  id="numeroNotti"
                  name="numeroNotti"
                  value={numeroNotti ? numeroNotti : ""}
                  disabled
                  {...(validation &&
                    !numeroNotti && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation &&
                    !numeroNotti && { className: "text-danger" })}
                />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-lg-4">
                <Input
                  onChange={(e) => setTipologiaCamera(e.target.value)}
                  label="Tipologia Camera"
                  name="tipologiaCamera"
                  id="tipologiaCamera"
                  value={tipologiaCamera ? tipologiaCamera : ""}
                  {...(validation &&
                    !tipologiaCamera && {
                      infoText: "Il campo non può essere vuoto!",
                    })}
                  {...(validation &&
                    !tipologiaCamera && { className: "text-danger" })}
                  disabled={editForce}
                />
              </div>
            </div>
            <h5>
              <div className="row">
                {editForce ? (
                  <>
                    {" "}
                    <div className="col-10 text-right">
                      <button
                        type="button"
                        className="btn btn-primary "
                        onClick={() => setEditForce(!editForce)}
                      >
                        Modifica
                      </button>
                    </div>
                    <div className="col-2 text-right">
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={() => toggleModal(!isOpen)}
                      >
                        Elimina
                      </button>
                    </div>
                  </>
                ) : (
                  <>
                    {" "}
                    <div className="col-12 text-right ">
                      <button
                        onClick={() => sendForm()}
                        className="btn btn-primary mx-5"
                      >
                        Salva
                      </button>

                      {!props.backButton && (
                        <button
                          type="submit"
                          className="btn btn-info text-white ml-5"
                          onClick={() => setEditForce(!editForce)}
                        >
                          Indietro
                        </button>
                      )}
                    </div>
                  </>
                )}
              </div>
            </h5>
          </div>
        </div>
      </div>

      {isOpenConfirmation && (
        <Notification
          style={{ zIndex: "1" }}
          header={
            bookingResult
              ? "PRENOTAZIONE AVVENUTA CON SUCCESSO"
              : "Prenotazione Fallita"
          }
          state={bookingResult ? "success" : "error"}
          withIcon
          closeState={toggleModalConfirmation}
          dismissable={true}
        />
      )}
      <Modal
        isOpen={isOpen}
        toggle={() => toggleModal(!isOpen)}
        labelledBy="esempio1"
      >
        <ModalHeader id="esempio1">Attenzione</ModalHeader>
        <ModalBody>
          Vuoi procedere con l'eliminazione del pernottamento?
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => toggleModal(!isOpen)} color="primary">
            Chiudi
          </Button>
          <Button onClick={() => deleteBooking()} color="danger">
            Elimina
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default ViewBooking;
