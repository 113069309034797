import { useEffect, useState } from "react";
import Select from "../Objects/Select";

import Repo from "../Utility/Repo";

function SelectCourse(props) {
  const [course, setCourse] = useState("Enti");

  const [courseList, setCourseList] = useState({});
  const [courseSelect, setCourseSelect] = useState();

  const changeCourse = (event) => {
    if (event) {
      setCourseSelect(event.value);
      props.setValue(event.value);
    } else {
      setCourseSelect();
      props.setValue();
    }
  };

  useEffect(() => {
    Repo.getSelectCourse(props.apiToken)
      .then((response) => {
        if (response.status == 200) {
          setCourseList(response.data.data);
        }
      })
      .catch((error) => {
        setCourseList([]);
        console.log(error);
      });
  }, [props.apiToken]);

  return (
    <Select
      label="Scegli una opzione"
      clearText="Annulla"
      defaultInputValue=""
      defaultMenuIsOpen={false}
      icon={true}
      options={courseList}
      id="course"
      onChange={changeCourse}
      isSearchable
      placeholder="Seleziona un corso"
      isClearable={true}
    />
  );
}

export default SelectCourse;
