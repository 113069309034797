import { useEffect, useState } from "react";
import Select from "../Objects/Select";

import {
  Input,
  FormGroup,
  Label,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";
import Repo from "../Utility/Repo";

function SearchUser(props) {
  const [userList, setUserList] = useState();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [cf, setCf] = useState("");
  const [email, setEmail] = useState("");
  const [qualification, setQualification] = useState();
  const [institution, setInstitution] = useState();

  const startSearch = () => {
    var tempRequest = {
      firstName: firstName,
      lastName: lastName,
      codiceFiscale: cf,
      email: email,
    };
    // if (qualification) {
    //   tempRequest["qualifica"] = qualification;
    // }
    // if (institution) {
    //   tempRequest["enteID"] = institution;
    // }

    Repo.utentiFilter(props.apiToken, JSON.stringify(tempRequest))

      .then((response) => {
        setUserList(response.data);
      })
      .catch((error) => {});
  };

  return (
    <>
      <div className="row">
        <div className="col-lg-6">
          <Input
            onChange={(e) => setFirstName(e.target.value)}
            id="firstName "
            label="Nome"
            name="firstName"
          />
        </div>

        <div className="col-lg-6">
          <Input
            label="Cognome"
            id="lastName"
            name="lastName"
            onChange={(e) => setLastName(e.target.value)}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-lg-6 mt-3">
          <Input
            label="Codice Fiscale"
            id="cf"
            name="cf"
            onChange={(e) => setCf(e.target.value)}
          />
        </div>

        {/* <div className="col-lg-4 mt-4">
          <div className="bootstrap-select-wrapper">
            <label htmlFor="selectExampleDisabled">Ente</label>
            <SelectInstitution setValue={setInstitution}></SelectInstitution>
          </div>
        </div>

        <div className="col-lg-4 mt-4">
          <div className="bootstrap-select-wrapper">
            <label htmlFor="selectExampleDisabled">Qualifica</label>
            <SelectQualification
              setValue={setQualification}
            ></SelectQualification>
          </div>
        </div> */}

        <div className="col-lg-6 mt-3">
          <Input
            label="Email"
            id="email"
            name="email"
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
      </div>

      <div className="col-lg-12 ml-2 text-right">
        <Button onClick={() => startSearch()} color="primary">
          Cerca
        </Button>
      </div>

      <div className="row mt-4">
        <div className="col-12">
          {userList && userList.length > 0 ? (
            userList.map((x, index) => (
              <div key={index}>
                <div
                  className="card card-teaser roundedCustom shadow mb-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => props.addUserFromOperator(x.utenteID)}
                >
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-6 d-flex align-items-center">
                        <p>
                          Nome: <b className="ml-1 mr-1">{x.nome}</b>
                        </p>
                      </div>
                      <div className="col-lg-6 d-flex align-items-center">
                        <p>
                          Cognome: <b className="ml-1 mr-1">{x.cognome}</b>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6 d-flex align-items-center">
                        <p>
                          Email: <b className="ml-1 mr-1">{x.email}</b>
                        </p>
                      </div>
                      <div className="col-lg-6 d-flex align-items-center">
                        <p>
                          Codice Fiscale:{" "}
                          <b className="ml-1 mr-1">{x.codiceFiscale}</b>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div>
              <div
                colSpan="5"
                className="text-left"
                style={{ fontSize: "16px" }}
              >
                <b>Nessun Utente Trovato</b>
              </div>
            </div>
          )}
          <br />
        </div>
      </div>
    </>
  );
}

export default SearchUser;
