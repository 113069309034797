import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { Switch, Route } from "react-router-dom";
import sfondo from "../../Img/sfondo.png";
import PageEditSession from "../Page/PageEditSession";
import SignedUp from "../Page/SignedUp.js";
import PageOwnCourses from "../Page/PageOwnCourses.js";
import PageSessionAllCourses from "../Page/PageSessionAllCourses.js";
import SessionCourseDetail from "../Page/SessionCourseDetail.js";
import PageAllCourses from "../Page/PageAllCourses.js";
import CourseDetail from "../Page/CourseDetail.js";
import PageUserProfile from "../Page/PageUserProfile.js";
import PageOperator from "../Page/PageOperator.js";
import HomePage from "../Page/HomePage.js";
import PageSubjects from "../Page/PageSubjects";
import PageSubjectDetail from "../Page/PageSubjectDetail";
import PageCreateSubject from "../Page/PageCreateSubject";
import PageInstitution from "../Page/PageInstitution";
import PageInstitutionDetail from "../Page/PageInstitutionDetail";
import PageCreateInstitution from "../Page/PageCreateInstitution";
import PageBooking from "../Page/PageBooking";
import PrivacyPolicy from "../Page/PrivacyPolicy";
import PageBookingDetail from "../Page/PageBookingDetail";
import PageNewCourse from "../Page/PageNewCourse";
import PageNewSession from "../Page/PageNewSession";

import UsersList from "../Objects/UsersList";
import PageUserManagement from "../Page/PageUserManagement";
import Breadcrumb from "../Layout/Breadcrumb";

import PageResetPassword from "../Page/PageResetPassword";
import PageConfirmedSignedUp from "../Page/PageConfirmedSignedUp";

import Cookies from "universal-cookie";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "design-react-kit";

function Body(props) {
  const [cookies] = useCookies();
  const [codiceFiscale, setCodiceFiscale] = useState("");
  const [isOpen, toggleModal] = useState(false);

  const cookies_token = new Cookies();

  const deleteCoockieExpired = () => {
    cookies_token.remove("token_key");
    toggleModal(!isOpen);
  };

  return (
    <>
      <main role="main" className="main-body">
        <div>
          <>
            <div
              className="it-hero-wrapper it-dark it-overlay it-bottom-overlapping-content it-hero-small-size"
              style={{ maxHeight: "300px", paddingTop: "0px" }}
            >
              <div className="img-responsive-wrapper">
                <div className="img-responsive">
                  <div className="img-wrapper">
                    <img src={sfondo} title="img title" alt="imagealt" />
                  </div>
                </div>
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <div className="it-hero-text-wrapper bg-dark">
                      <Breadcrumb></Breadcrumb>

                      <Switch>
                        <Route exact path="/page_user_profile">
                          <h1 className="no_toc">Profilo utente</h1>
                        </Route>

                        <Route exact path="/own_courses">
                          <h1 className="no_toc">I miei corsi</h1>
                        </Route>
                        <Route exact path="/session_courses">
                          {props.isLogged && !props.operator ? (
                            <h1 className="no_toc">Corsi Disponibili</h1>
                          ) : (
                            <h1 className="no_toc">Edizioni Disponibili</h1>
                          )}
                        </Route>
                        <Route exact path="/session_course_detail">
                          <h1 className="no_toc">Dettaglio edizione</h1>
                        </Route>
                        <Route exact path="/courses">
                          <h1 className="no_toc">Corsi Disponibili</h1>
                        </Route>
                        <Route exact path="/course_detail">
                          <h1 className="no_toc">Dettaglio corso</h1>
                        </Route>
                        <Route exact path="/subjects_management">
                          <h1 className="no_toc">Gestione Materie</h1>
                        </Route>
                        <Route exact path="/user_management">
                          <h1 className="no_toc">Gestione Utenti</h1>
                        </Route>

                        <Route exact path="/edit_session">
                          <h1 className="no_toc">Modifica Edizione</h1>
                        </Route>

                        <Route exact path="/resetPassword">
                          <h1 className="no_toc">Reset Password</h1>
                        </Route>

                        <Route exact path="/subject_detail">
                          <h1 className="no_toc">
                            Visualizza o Modifica Materia
                          </h1>
                        </Route>
                        <Route exact path="/create_subject">
                          <h1 className="no_toc">Crea Materia</h1>
                        </Route>
                        {/* <Route exact path="/institution_management">
                          <h1 className="no_toc">Gestione Enti</h1>
                        </Route>
                        <Route exact path="/bookings_management">
                          <h1 className="no_toc">Gestione Pernottamenti</h1>
                        </Route> */}
                        {/* <Route exact path="/institution_detail">
                          <h1 className="no_toc">Visualizza o Modifica Ente</h1>
                        </Route>
                        <Route exact path="/booking_detail">
                          <h1 className="no_toc">Modifica prenotazione</h1>
                        </Route>
                        <Route exact path="/create_institution">
                          <h1 className="no_toc">Crea Ente</h1>
                        </Route> */}
                        <Route path="/">
                          {props.isLogged && !props.operator ? (
                            <>
                              <h1 className="no_toc">Home Page</h1>
                              <h6 className="d-none d-lg-block text-white">
                                Gli Esperti approfondiscono i temi più
                                ricorrenti e l'attualità più stringente con
                                corsi formativi fruibili ed efficaci.
                              </h6>
                            </>
                          ) : (
                            props.isLogged &&
                            props.operator && (
                              <>
                                <h1 className="no_toc">Pannello Operatore</h1>
                              </>
                            )
                          )}
                        </Route>
                      </Switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="card-wrapper card-space">
                    <div className="card card-bg">
                      <div className="card-body">
                        {props.isLogged && !props.operator && (
                          <Switch>
                            <Route exact path="/page_user_profile">
                              <PageUserProfile
                                apiToken={props.apiToken}
                                operator={props.operator}
                                subfolder={props.subfolder}
                                deleteCoockieExpired={deleteCoockieExpired}
                              >
                                {props.user}
                              </PageUserProfile>
                            </Route>
                            <Route exact path="/own_courses">
                              <PageOwnCourses
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></PageOwnCourses>
                            </Route>
                            <Route exact path="/session_courses">
                              <PageSessionAllCourses
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator}
                                subfolder={props.subfolder}
                              >
                                {" "}
                              </PageSessionAllCourses>
                            </Route>
                            <Route exact path="/session_course_detail">
                              <SessionCourseDetail
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                user={props.user}
                                subfolder={props.subfolder}
                              ></SessionCourseDetail>
                            </Route>

                            <Route path="/resetPassword">
                              <PageResetPassword
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></PageResetPassword>
                            </Route>
                            <Route exact path="/privacy_policy">
                              <PrivacyPolicy></PrivacyPolicy>
                            </Route>

                            <Route path="/">
                              <HomePage
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></HomePage>
                            </Route>
                          </Switch>
                        )}
                        {props.isLogged && props.operator && (
                          <Switch>
                            <Route exact path="/new_course">
                              <PageNewCourse
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></PageNewCourse>
                            </Route>

                            <Route path="/resetPassword">
                              <PageResetPassword
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></PageResetPassword>
                            </Route>

                            <Route exact path="/edit_session">
                              <PageEditSession
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></PageEditSession>
                            </Route>

                            <Route exact path="/new_session">
                              <PageNewSession
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></PageNewSession>
                            </Route>

                            <Route exact path="/page_user_profile">
                              <PageUserProfile
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              >
                                {props.user}
                              </PageUserProfile>
                            </Route>
                            <Route exact path="/own_courses">
                              <PageOwnCourses
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></PageOwnCourses>
                            </Route>
                            <Route exact path="/session_courses">
                              <PageSessionAllCourses
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator}
                                subfolder={props.subfolder}
                              ></PageSessionAllCourses>
                            </Route>
                            <Route exact path="/session_course_detail">
                              <SessionCourseDetail
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></SessionCourseDetail>
                            </Route>
                            <Route exact path="/courses">
                              <PageAllCourses
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator}
                                subfolder={props.subfolder}
                              ></PageAllCourses>
                            </Route>
                            <Route exact path="/course_detail">
                              <CourseDetail
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></CourseDetail>
                            </Route>
                            <Route exact path="/subjects_management">
                              <PageSubjects
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></PageSubjects>
                            </Route>
                            <Route exact path="/subject_detail">
                              <PageSubjectDetail
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></PageSubjectDetail>
                            </Route>
                            <Route exact path="/create_subject">
                              <PageCreateSubject
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></PageCreateSubject>
                            </Route>
                            <Route exact path="/institution_management">
                              <PageInstitution
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></PageInstitution>
                            </Route>
                            <Route exact path="/bookings_management">
                              <PageBooking
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></PageBooking>
                            </Route>
                            <Route exact path="/institution_detail">
                              <PageInstitutionDetail
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></PageInstitutionDetail>
                            </Route>
                            <Route exact path="/create_institution">
                              <PageCreateInstitution
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></PageCreateInstitution>
                            </Route>
                            <Route exact path="/user_management">
                              <PageUserManagement
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></PageUserManagement>
                            </Route>
                            <Route exact path="/booking_detail">
                              <PageBookingDetail
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                operator={props.operator ? true : false}
                                subfolder={props.subfolder}
                              ></PageBookingDetail>
                            </Route>
                            <Route exact path="/privacy_policy">
                              <PrivacyPolicy></PrivacyPolicy>
                            </Route>
                            <Route path="/">
                              <PageOperator
                                deleteCoockieExpired={deleteCoockieExpired}
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></PageOperator>
                            </Route>
                          </Switch>
                        )}
                        {!props.isLogged && !props.operator && (
                          <Switch>
                            <Route exact path="/session_courses">
                              <PageSessionAllCourses
                                apiToken={props.apiToken}
                                operator={props.operator}
                                subfolder={props.subfolder}
                              ></PageSessionAllCourses>
                            </Route>

                            <Route path="/resetPassword">
                              <PageResetPassword
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></PageResetPassword>
                            </Route>

                            <Route path="/registerConfirm">
                              <PageConfirmedSignedUp
                                apiToken={props.apiToken}
                                subfolder={props.subfolder}
                              ></PageConfirmedSignedUp>
                            </Route>
                            <Route exact path="/privacy_policy">
                              <PrivacyPolicy
                                subfolder={props.subfolder}
                              ></PrivacyPolicy>
                            </Route>

                            <Route path="/">
                              <SignedUp subfolder={props.subfolder}></SignedUp>
                            </Route>
                          </Switch>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        </div>

        {/* 
      {(!apiToken || isOperator) && <ChatBot intent={"WELCOME"}></ChatBot>} */}
        {isOpen && (
          <Modal isOpen={isOpen} labelledBy="esempio1">
            <ModalHeader id="esempio1">SESSIONE SCADUTA</ModalHeader>
            <ModalBody>Esegui nuovamente il login</ModalBody>
            <ModalFooter>
              <Button
                color="primary"
                onClick={() => (window.location.href = props.subfolder + "/")}
              >
                Login
              </Button>
            </ModalFooter>
          </Modal>
        )}
      </main>
    </>
  );
}

export default Body;
