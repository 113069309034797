import { React, useState, useEffect } from "react";
import { Markup } from "interweave";

function PrivacyPolicy(props) {
  const [dataHtml, setData] = useState(null);

  useEffect(() => {
    fetch("./privacy.html")
      .then((res) => res.text())
      .then((data) => {
        setData(data);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <Markup content={dataHtml} />
    </>
  );
}

export default PrivacyPolicy;
